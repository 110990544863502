import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { searchProperty } from "../../utils/elasticSearch"; // Ensure this function returns a promise that resolves to an array

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Removes the border
    },
    "&:hover fieldset": {
      border: "none", // Removes the border on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Removes the border on focus
    },
    padding: "0", // Removes padding inside the TextField
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none", // Hides the dropdown icon
  },
  "& .MuiInputBase-input": {
    padding: "0", // Remove padding inside the input element
  },
});

const PropertySearch = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let active = true;

    const search = async () => {
      if (searchTerm === "") {
        setOptions([]);
        return;
      }

      setIsLoading(true);
      const result = await searchProperty(searchTerm);
      if (active) {
        setOptions(Array.isArray(result) ? result : []);
        setIsLoading(false);
      }
    };

    search();

    return () => {
      active = false;
    };
  }, [searchTerm]);

  const loading = isLoading;

  const onOptionSelected = useCallback(
    (event, value) => {
      if (value) navigate(`/product-view?slug=${value.slug}`);
    },
    [navigate]
  );

  return (
    <Autocomplete
      id="asynchronous-property-search"
      sx={{
        width: {
          xs: "100%", // 100% width on extra-small screens (e.g., mobile devices)
          sm: "90%", // 90% width on small screens (e.g., tablets)
          md: "100%", // 80% width on medium screens (e.g., small desktops)
          lg: 500, // 600px width on large screens (e.g., large desktops)
        },
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.full_address || ""}
      options={options}
      loading={loading}
      onChange={onOptionSelected}
      renderInput={(params) => (
        <CssTextField
          {...params}
          placeholder="Search for AnyProperty"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default PropertySearch;
