import React, { useCallback, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "../auth/SignUp.css";
import { useSelector, useDispatch } from "react-redux";
/*
import {
  setFrontImageID,
  setBackImageID,
} from "../../redux/actions/verificationActions";
*/
import Header from "../../components/common/Header";
//import ImageUploader from "../../components/common/ImageUploader";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import { NavLink, useNavigate } from "react-router-dom";
import HelpLink from "../../components/common/HelpLink";
import IdentityUpload from '../../components/account/IdentityUpload';

export default function VerifiIdentify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  /*
  const [identityFront, setIdentityFront] = useState(null);
  const [identityBack, setIdentityBack] = useState(null);
  const { frontImageID, backImageID } = useSelector(
    (state) => state.verification
  );
  */
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);



  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleUploadSuccess = () => {
    setUploadSuccess(true);
    setIsUploading(false);
    setMessage({
      type: "success",
      message: "Identity documents uploaded successfully",
    });
    setAlertOpen(true);
  };

  const onNextClick = ({ skip = false } = {}) => {
    if (!skip && !uploadSuccess) {
      setMessage({
        type: "error",
        message: "Please upload all images",
      });
      setAlertOpen(true);
      return;
    }

    navigate("/verify-selfie");
  };

  /*
  const onNextClick = async ({ skip = false }) => {
    if (!skip && (!identityFront || !identityBack)) {
      setMessage({
        type: "error",
        message: "Please upload all images",
      });
      setAlertOpen(true);
      return;
    }
  
    if (skip) {
      navigate("/verify-selfie");
      return;
    }
  
    try {
      setIsUploading(true);
      await dispatch(setFrontImageID(user, identityFront));
      await dispatch(setBackImageID(user, identityBack));
      console.log("Upload successful");
      navigate("/verify-selfie");
    } catch (error) {
      console.error("Error uploading files:", error);
      setMessage({
        type: "error",
        message: "Failed to upload images for identity verification",
      });
      setAlertOpen(true);
    } finally {
      setIsUploading(false);
    }
  };
  */

  const handleUploadStart = () => {
    console.log('Upload started');
    // Do something when upload starts
  };

  const handleUpdateLoader = (loading) => {
    console.log("Loading state:", loading); // Here you can manage loader state, e.g., showing a loading spinner
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };




  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Verification</div>
        <div className="step-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step active">
            <div className="step-number">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5 verification">
        {" "}
        <div className="confirmation">
          <button
            className="button"
            onClick={() => onNextClick({ skip: true })}
          >
            Skip for now
          </button>
        </div>
        <div className="row justify-content-center confirmation">
          <div className="col-md-4 signup confirmation bg-gray text-center shadow p-5">
            <h3 className="text-center">Identity verification</h3>

            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={55} />
            </Box>

            <div className="cards-container">
              <div className="cards-container-inner">

                <div>
                  <IdentityUpload
                    userId={currentUser.slug}
                    onUploadSuccess={handleUploadSuccess}
                    onUploadStart={handleUploadStart} />
                  {/*
                  <ImageUploader
                    applyClass={true}
                    onChange={(file) => setIdentityFront(file)}
                    onUpdateLoader={handleUpdateLoader}
                    onThumbnail={handleThumbnail}
                  />{" "}
                  <p className="image-icon-text">Upload front page</p>
                    */}
                </div>
                {/*
                <div>
                  <ImageUploader
                    applyClass={true}
                    onChange={(file) => setIdentityBack(file)}
                    onUpdateLoader={handleUpdateLoader}
                    onThumbnail={handleThumbnail}
                  />

                  <p className="image-icon-text">Upload back page</p>
                </div>
                */}
              </div>
            </div>
            <div className="m-b-50">
              <span>
                File size must be between 10KB and 5120KB in jpg/jpeg/png
                format.
              </span>
            </div>
            <div className="m-b-50">
              <button
                onClick={onNextClick}
                className="button"
                disabled={isUploading}
              >
                {isUploading ? 'Uploading...' : 'Continue'}
              </button>
            </div>
            <div className="m-b-50">
              <NavLink className="back" to="/verification-upload-image">
                <b className="mt-3">Back</b>
              </NavLink>
            </div>
            <div className="m-b-50">
              <HelpLink />
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </div>
  );
}
