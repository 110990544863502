import { Link } from 'react-router-dom';
import './AccountInfoCard.css'
const AccountInfoCard = ({ heading, para, icon, link, linkText }) => {
    return (
        <div className='account_info_card'>
            <img src={icon} alt='' />
            <div className='account_info_card_heading'>{heading}</div>
            <hr className='account_info_line' />
            <div className='account_info_card_para'>
                {para}
            </div>
            <div>
                <Link to={link === "" ? "/pages/privacy-policy" : link}>
                    {linkText}
                </Link>
            </div>
        </div>
    );
};
export default AccountInfoCard;

