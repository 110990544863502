import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWatchlists } from "../../redux/actions/watchlistActions";
import "./VerifiedProperties.css";
import VerifiedPropertyCard from "../../components/property/VerifiedPropertyCard";
import Header from "../../components/common/Header";
import Profile from "./Profile";
import PropertySections from "../../components/property/PropertySections";
import Footer from "../../components/common/Footer";

const WatchList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { items, isLoading, error } = useSelector((state) => state.watchlist);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user && user.slug) {
      dispatch(fetchWatchlists(user.slug));
    }
  }, []);
  return (
    <>
      <Header />
      <div className="container">
        <Profile />
        <div className="verified-container">
          <div className="tab">
            <Link
              to="/verifiedproperties"
              className={`tab-button ${
                location.pathname === "/verifiedproperties" ? "active" : ""
              }`}
            >
              Verified Properties
            </Link>
            <Link
              to="/watchlist"
              className={`tab-button ${
                location.pathname === "/watchlist" ? "active" : ""
              }`}
            >
              Watchlist
            </Link>
            <Link
              to="/recentlyview"
              className={`tab-button ${
                location.pathname === "/recentlyview" ? "active" : ""
              }`}
            >
              Recently Viewed
            </Link>
          </div>
          <div className="line-separator"></div>

          <PropertySections isLoading={isLoading} propertiesData={items} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WatchList;
