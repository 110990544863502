import apiService from "../../services/apiService";
import { searchProperty } from "../../utils/elasticSearch";

export const setProperties = (properties) => ({
  type: "SET_PROPERTIES",
  payload: properties,
});

export const setPropertiesHistory = (properties) => ({
  type: "SET_PROPERTIES_HISTORY",
  payload: properties,
});

export const setLoading = (isLoading) => ({
  type: "SET_LOADING",
  payload: isLoading,
});

export const fetchProperties = (data) => async (dispatch) => {
  dispatch(setProperties(data));
  dispatch(setLoading(false));
};

export const getVerifiedProperties = () => async (dispatch) => {
  dispatch(setLoading(true));
  const properties = await apiService.getWithToken("/properties/verified");
  dispatch(setProperties(properties));
  dispatch(setLoading(false));
};

export const getUserPropertiesHistory = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const history = await apiService.getWithToken("/history/properties");
    const uniqueData = Array.from(
      new Map(history.map((item) => [item["id"], item])).values()
    );

    dispatch(setPropertiesHistory(uniqueData));
  } catch (error) {
    console.error("Failed to fetch user properties history", error);
    // Optionally, dispatch an error action here
  } finally {
    dispatch(setLoading(false));
  }

  
};


