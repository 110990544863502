import apiService from "../../services/apiService";

export const setUser = (user) => ({
  type: "SET_USER",
  payload: user,
});

//update user with put request
export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch(setLoadingUser(true));
    const response = await apiService.putWithToken(`/users/${user.slug}`, {
      user,
    });
    if (response.user) {
      localStorage.setItem("userData", JSON.stringify(response.user));
      dispatch(setUser(response.user));
      dispatch(setLoadingUser(false));
    }
  } catch (error) {
    dispatch(setLoadingUser(false));
    console.error("Error updating user:", error);
    // Optionally, handle the error state in your Redux store
  }
};

export const setLoadingUser = (isLoading) => ({
  type: "SET_LOADING_USER",
  payload: isLoading,
});

export const setMyProperties = (myProperties) => ({
  type: "SET_MY_PROPERTIES",
  payload: myProperties,
});

export const setViewingsAndOffers = (properties) => ({
  type: "SET_VIEW_AND_OFFERS",
  payload: properties,
});

export const deleteUser = () => {
  return {
    type: "DELETE_USER",
  };
};

export const fetchCurrentUser = () => async (dispatch) => {
  dispatch(setLoadingUser(true));
  try {
    const response = await apiService.getWithToken("/users/current");
    if (response.user) {
      localStorage.setItem("userData", JSON.stringify(response.user));
      dispatch(setUser(response.user));
    }
  } catch (error) {
    console.error("Error fetching current user:", error);
    // Optionally, handle the error state in your Redux store
  } finally {
    dispatch(setLoadingUser(false));
  }
};

//call  get "/users/:slug/my_properties" => "users#my_properties"
export const fetchUserProperties = () => async (dispatch) => {
  dispatch(setLoadingUser(true));
  try {
    const properties = await apiService.getWithToken(`/my_properties`);
    if (properties) {
      dispatch(setMyProperties(properties));
    }
  } catch (error) {
    console.error("Error fetching user properties:", error);
    // Optionally, handle the error state in your Redux store
  } finally {
    dispatch(setLoadingUser(false));
  }
};

function organizeViewings(viewings) {
  const properties = [];

  viewings.forEach((viewing) => {
    const propertyId = viewing.property_id;
    const propertyInfo = {
      id: propertyId,
      lat: viewing.property.lat,
      lng: viewing.property.lng,
      full_address: viewing.property.full_address,
      suburb: viewing.property.suburb,
      viewings: [],
    };

    // Find if the property already exists in the properties array
    let propertyEntry = properties.find((p) => p.id === propertyId);

    const viewingDetails = {
      id: viewing.id,
      buyer: viewing.buyer_first_name,
      createdAt: viewing.created_at,
      viewing_dates: viewing.viewing_dates,
    };

    if (propertyEntry) {
      // Add the new viewing to the existing property's viewings array
      propertyEntry.viewings.push(viewingDetails);
    } else {
      // If it's a new property, add the first viewing and push to the properties array
      propertyInfo.viewings.push(viewingDetails);
      properties.push(propertyInfo);
    }
  });
  return properties;
}

export const fetchUserViewingsAndOffers = (user) => async (dispatch) => {
  dispatch(setLoadingUser(true));
  try {
    const response = await apiService.getWithToken(
      `/users/${user.slug}/viewings`
    );
    if (response) {
      const { viewings, offers, sellerViewings, sellerOffers, virtual_tours } =
        response;

      // Dispatching properties as separate arrays
      dispatch(
        setViewingsAndOffers({
          offers: viewings.filter((v) => v.aasm_state === "making_offer"),
          viewings: viewings.filter((v) => v.aasm_state !== "making_offer"),
          sellerViewings: organizeViewings(
            sellerViewings.filter((v) => v.aasm_state === "request_viewing")
          ),
        })
      );
    }
  } catch (error) {
    console.error("Error fetching user properties:", error);
  } finally {
    dispatch(setLoadingUser(false));
  }
};
