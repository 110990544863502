import React from "react";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import "./SetPropertyFeatures.css";
import { Grid } from "@mui/material";
import { useProperty } from "../../context/PropertyContext";

const propertyFeatures = [
  { _name: "Air Conditioned", img: "/images/AC.svg" },
  { _name: "Elevator Access", img: "/images/elevator.svg" },
  { _name: "Smart Home", img: "/images/smart_home.svg" },
  { _name: "Keyless Entry", img: "/images/keyless_entry.svg" },
  { _name: "Indoor Fireplace", img: "/images/indoor_fireplace.svg" },
  { _name: "Exercise Equipment", img: "/images/exercise_equipment.svg" },
  { _name: "Pool", img: "/images/pool.svg" },
  { _name: "Hot Tub", img: "/images/hot_tub.svg" },
  { _name: "Patio", img: "/images/patio.svg" },
];

function SetPropertyFeatures() {
  const { property, updateProperty } = useProperty();

  // Initialize features map if not already initialized in context
  if (!property.features) {
    updateProperty(
      "features",
      propertyFeatures.reduce((acc, feature) => {
        acc[feature._name] = false;
        return acc;
      }, {})
    );
  }

  const handleClick = (_name) => {
    // Toggle the feature state
    const newFeatures = {
      ...property.features,
      [_name]: !property.features[_name],
    };
    updateProperty("features", newFeatures);
  };

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Show off some of the features your place have
        </p>
        <p className="set-properties-rooms-para">
          What are some of the cool features your place have?
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={4} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-features-container">
            {propertyFeatures.map((item) => (
              <div
                key={item._name}
                className={`property-card-features ${
                  property.features && property.features[item._name]
                    ? "active-feature"
                    : ""
                }`}
                onClick={() => handleClick(item._name)}
              >
                <img
                  src={item.img}
                  alt={item._name}
                  className="property-card-features-image"
                />
                <p>{item._name}</p>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyFeatures;
