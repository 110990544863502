import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./RecentlyView.css";
import { getUserPropertiesHistory } from "../../redux/actions/propertyActions";
import VerifiedPropertyCard from "../../components/property/VerifiedPropertyCard";
import apiService from "../../services/apiService";
import Header from "../../components/common/Header";
import PropertySections from "../../components/property/PropertySections";
import Profile from "./Profile";
import Footer from "../../components/common/Footer";

const RecentlyView = () => {
  const { properties_history, isLoading, error } = useSelector(
    (state) => state.properties
  );
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPropertiesHistory());
  }, []);

  return (
    <div className="container">
      <Header />
      <Profile />
      <div className="verified-container">
        <div className="tab">
          <Link
            to="/verifiedproperties"
            className={`tab-button ${
              location.pathname === "/verifiedproperties" ? "active" : ""
            }`}
          >
            Verified Properties
          </Link>
          <Link
            to="/watchlist"
            className={`tab-button ${
              location.pathname === "/watchlist" ? "active" : ""
            }`}
          >
            Watchlist
          </Link>
          <Link
            to="/recentlyview"
            className={`tab-button ${
              location.pathname === "/recentlyview" ? "active" : ""
            }`}
          >
            Recently Viewed
          </Link>
        </div>
        <div className="line-separator"></div>

        <PropertySections
          isLoading={isLoading}
          propertiesData={properties_history} // Corrected variable name
        />
      </div>

      <Footer />
    </div>
  );
};

export default RecentlyView;
