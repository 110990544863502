import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ViewRequest.css";
import { fetchUserViewingsAndOffers } from "../../redux/actions/userActions";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import MyPropertyCard from "../../components/account/MyPropertyCard";
import { Link, useLocation } from "react-router-dom";

const ViewRequest = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let viewType = queryParams.get("type");
  if (viewType === undefined || viewType === "") {
    viewType = false;
  }
  const dispatch = useDispatch();
  const { viewingsAndOffers, currentUser } = useSelector((state) => state.user);
  const { viewings, sellerViewings } = viewingsAndOffers;

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchUserViewingsAndOffers(currentUser));
    }
  }, [currentUser]);
  return (
    <div className="offer-request">
      <Header />
      <div className="my-properties-subheader-nav">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="/account-settings/my-properties" className="custom-link">
            &nbsp; &nbsp;My Properties &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="" className="custom-link">
            &nbsp; &nbsp;View Requests
          </Link>
        </div>
        <div className="payments-transactions-heading">View Requests</div>
      </div>
      {!viewType &&
        sellerViewings &&
        sellerViewings.map((marker) => (
          <MyPropertyCard
            location="locationA"
            viewings={marker.viewings}
            marker={marker}
            card="view_requests"
            isManaged={false}
            refetch={() => dispatch(fetchUserViewingsAndOffers(currentUser))}
            page={"isViewRequest"}
          />
        ))}

      {viewType &&
        viewings &&
        viewings.map((marker) => (
          <MyPropertyCard
            location="locationA"
            marker={marker.property}
            card="view_requests"
            isManaged={false}
            refetch={() => dispatch(fetchUserViewingsAndOffers(currentUser))}
            page={
              viewType === "viewing_others"
                ? "isMyProperties"
                : "isMyProperties" // will change in future versions
            }
          />
        ))}
      <Footer />
    </div>
  );
};

export default ViewRequest;
