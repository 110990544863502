import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProperties } from "../../redux/actions/userActions";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import MyPropertyCard from "../../components/account/MyPropertyCard";
import "./MyProperties.css";

const MyProperties = () => {
  const dispatch = useDispatch();
  const { myProperties } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserProperties());
  }, []);

  return (
    <div className="my-properties">
      <Header />
      <div className="my-properties-subheader-nav">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>
          {">"}
          <Link to="" className="custom-link">
            &nbsp; &nbsp;My Properties
          </Link>
        </div>
        <div className="payments-transactions-heading">My Properties</div>
      </div>
      {/* This is commented out because the PropertySections component is not imported
          Assuming PropertySections is a valid component, you can uncomment this line once you have it imported. 
          <PropertySections isLoading={isLoading} propertiesData={propertiesData} />
      */}
      {myProperties &&
        myProperties.map((p, index) => (
          <MyPropertyCard
            key={index}
            location="locationA"
            marker={p}
            page="isMyProperties"
          />
        ))}
      <Footer />
    </div>
  );
};

export default MyProperties;
