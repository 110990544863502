import { Table } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./HowItworksBuy.css";

const data = [
  {
    image: "/images/money.svg",
    heading: "Fees",
    para: "How much does it cost to sell your property",
    AnyProperty: "$7/month",
    competitors: "$29,457",
  },
  {
    image: "/images/influencer.svg",
    heading: "Resources",
    para: "Sale and purchase agreements, property insights, and more",
    AnyProperty: "Free",
    competitors:
      "Additional cost",
  },
  {
    image: "/images/clock.svg",
    heading: "Sell time",
    para: "You know longer have to rely on ‘experts’ to connect with buyers. And remember, it only takes one...",
    AnyProperty: "37 days",
    competitors: "42   days",
  },
];

function ItemDisplay({ item }) {
  return (
    <div className="bg-white shadow-md rounded-2xl p-6 m-4 mx-auto max-w-sm text-center border border-2 w-[80%] text-dark-blue">
      <div className="flex justify-center mb-2">
        <img src={item.image} alt={item.heading} className="h-24 w-24" />
      </div>
      <h1 className="text-xl font-product-sans-bold text-gray-800 mb-2">{item.heading}</h1>
      <p className="text-gray-600 mb-6">{item.para}</p>
      <div className="grid grid-cols-1 gap-2">
        <div className="grid grid-cols-2 gap-2 items-center">
          <div className="">
            <h2 className="text-lg text-gray-700">AnyProperty</h2>

          </div>
          <div>
            <h2 className="text-lg text-gray-700">Competitors</h2>

          </div>
        </div>

        <hr className="my-2 h-2 border-black w-full" />
        <div className="grid grid-cols-2 gap-2">
          <div>

            <p className="text-2xl  font-product-sans-medium">{item.AnyProperty}</p>
          </div>
          <div>

            <p className="text-2xl  font-product-sans-medium">{item.competitors}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function HowItWorksSell() {

  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    windowWidth > 768 ? (
      <div>

        <div className="how-it-works-sell-container">
          <Table className="how-it-works-sell-table">
            <thead>
              <tr>
                <th></th>
                <th>AnyProperty</th>
                <th>Competitors</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr>
                  <td>
                    <div className="how-it-works-table-img-box">
                      <img src={item.image} alt="" height="90px" width="90px" />
                      <div className="how-it-works-table-head-box">
                        <h1 className="how-it-works-table-img-head">
                          {" "}
                          {item.heading}
                        </h1>
                        <p className="how-it-works-table-img-para">
                          {" "}
                          {item.para}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{item.AnyProperty}</td>
                  <td>{item.competitors}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    ) : (

      <div className="">
        {data.map((item, index) => (
          <ItemDisplay key={index} item={item} />
        ))}
      </div>

    )

  );
}

export default HowItWorksSell;
