
import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "./Resources.css";
import { useNavigate } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import SanityResourcePostCard from "../../components/sanity/SanityResourcePostCard";

function Resources({ }) {
    const navigate = useNavigate();

    //const [resourcesData, setResourcesData] = useState([]);
    const [post, setPostData] = useState([]);

    useEffect(() => {
        // const query = '*[_type == "resourcePost"]';
        const query = `*[_type == "resourcePost" && slug.current == 'why-buy-and-sell-on-anyproperty'][0]`;
        console.log(query);
        sanityClient.fetch(query).then((data) => {
           
            setPostData(data);

        });

    }, []);

    const onSetup = () => {
        navigate("/set-property");
    };

    const onGotoCategory = (category) => {
        navigate(`/resources/${category}`);
    };

    if (!post) {
        return <div>Loading...</div>; // Render a loading message while the data is being fetched
    }

    console.log(post);
    return (
        <>
            <Header />


            <div className="bg-white ">
                {/* Resources Header */}
                <div className="min-h-32 gl:min-h-64">
                    {/* Circle Background */}
                    <div
                        className=" w-full lg:h-108 bg-center z-0 flex flex-col "
                        style={{
                            backgroundImage: `url('/images/resources-bg2.png')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'left',
                            backgroundRepeat: 'no-repeat',


                        }}
                    >
                        {/* Adjusted Content */}
                        <div className="flex flex-col z-10 pt-4 gl:pt-24 p-8 lg:min-w-[60rem] lg:ml-40 "> {/* Increase top padding here */}
                            <h1 className="text-4xl font-bold text-white">Resources</h1>
                            <p className="text-sm sm:text-xl text-white">Everything you need to know about buying, selling, and moving with AnyProperty, all in one place.</p>  </div>
                    </div>


                </div>


                {/* Learning More Section */}
                <div className="flex flex-col justify-between p-8 mx-auto max-w-6xl">

                    <div className="flex flex-row">
                        <h2 className="text-2xl font-semibold mt-4">I want to learn more about...</h2>
                    </div>

                    <div className='resources-post-content md:px-4 mt-12 mb-12 '>
                        <h1 className="text-1xl">{post.title}</h1>
                        {post.body ? <BlockContent blocks={post.body} /> : <p>Loading...</p>}
                    </div>


                    {/* Buying and Selling Section 
                    <div className="flex flex-col sm:flex-row">
                        <div className="flex-1 m-4" onClick={() => onGotoCategory('buying')}>
                            <div
                                className="relative bg-no-repeat rounded-lg"
                                style={{
                                    backgroundImage: `url('/images/resources-img1.jpg')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '286px',
                                    sm: {
                                        width: '518px',
                                    }
                                }}
                            >
                                <p className="mt-2 absolute top-0 left-0 bg-white bg-opacity-50 p-2">Home Buying</p>
                            </div>
                        </div>
                        <div className="flex-1 m-4" onClick={() => onGotoCategory('selling')}>
                            <div
                                className="relative bg-no-repeat rounded-lg"
                                style={{
                                    backgroundImage: `url('/images/resources-img2.jpg')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '286px',
                                    sm: {
                                        width: '518px',
                                    }
                                }}
                            >
                                <p className="mt-2 absolute top-0 left-0 bg-white bg-opacity-50 p-2">Home Selling</p>
                            </div>
                        </div>
                    </div>
                    */}

                    {/* Featured Section 
                    <div className="p-8">
                        <div className="flex-1 mb-8">
                            <h3 className="text-4xl font-semibold">Featured</h3>
                        </div>
                        <div className="flex flex-row flex-wrap  gap-4">

                            {resourcesData.map((resource, index) => (
                                <div className="w-full md:w-[45%] ">
                                    <SanityResourcePostCard resource={resource} index={index} />
                                </div>
                            ))}

                        </div>
                    </div>
                    */}

                </div>



                {/* Find Your Dream Home Section */}
                <div className="bg-gray-200 p-4 mt-12 sm:p-8 min-h-[30rem] flex flex-col "
                    style={{
                        backgroundImage: `url('/images/resources-bg1.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                    }}>
                    <div className=" mx-auto my-8  w-[80%] max-w-6xl">
                        <div className="flex flex-col  w-[70%] resources-act gap-4" >
                            <h2 className="text-2xl sm:text-3xl font-bold">Find your dream home</h2>
                            <p className="text-base sm:text-lg">Make an offer on any property in New Zealand</p>
                            <div className="resources-input-wrapper" >
                                <input
                                    type="text"
                                    placeholder="Where will your dream house be?"
                                    className="p-1 sm:p-2 rounded-l-lg flex-grow"
                                />
                                <button onClick={onSetup} className="p-1 sm:p-2 border-none font-productsans_bold bg-blue-900 text-white rounded-full cursor-pointer z-10 -ml-7 mt-0 w-50 text-sm sm:text-base text-white rounded-r-lg px-8">Offer</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default Resources;