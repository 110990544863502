import React, { useState } from 'react';
import apiServiceUploadFiles from '../../services/apiServiceUploadFiles';

const VirtualImageUploaderForProperty = ({ property, updateProperty }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    if (!property || !property.id) {
      setMessage('Property information is not available. Please try again later.');
      return;
    }

    setIsUploading(true);
    setMessage('');

    for (let file of files) {
      try {
        const response = await apiServiceUploadFiles.uploadPropertyVirtualImage(property.id, file);
        
        if (response.success) {
          updateProperty("virtual_images", prevImages => ({
            ...prevImages,
            virtual_images: [...(prevImages?.virtual_images || []), response.image_url]
          }));
          setMessage(prevMessage => prevMessage + `Virtual image ${file.name} uploaded successfully. `);
        } else {
          throw new Error(response.message || "Upload failed");
        }
      } catch (error) {
        console.error('Upload error:', error);
        setMessage(prevMessage => prevMessage + `Error uploading ${file.name}: ${error.message}. `);
      }
    }

    setIsUploading(false);
  };

  return (
    <div className="virtual-image-uploader-for-property">
      <h3>Upload Virtual Images</h3>
      <input
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        multiple
        disabled={isUploading}
      />
      {isUploading && <p>Uploading...</p>}
      {message && <p>{message}</p>}
      <div className="uploaded-virtual-images">
        <h4>Uploaded Virtual Images</h4>
        {property.virtual_images && property.virtual_images.map((image, index) => (
          <img key={index} src={image} alt={`Property Virtual Image ${index + 1}`} style={{maxWidth: '100px', margin: '5px'}} />
        ))}
      </div>
    </div>
  );
};

export default VirtualImageUploaderForProperty;