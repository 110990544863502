import React, { useState, useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "./redux/actions/userActions";
import Cookies from "js-cookie";
import HomeBuy from "./pages/property/HomeBuy";
import Browse from "./pages/property/Browse";
import AnyPropertyIt from "./pages/property/AnypropertyIt";
import SignUp from "./pages/auth/SignUp";
import Profile from "./pages/account/Profile";
import Login from "./pages/auth/Login";
import FAQ from "./pages/common/Faq";
import Resources from "./pages/common/Resources";
import ResourcesCategory from "./pages/common/ResourcesCategory";
import ResourcesPost from "./pages/common/ResourcesPost";
import Sell from "./pages/property/Sell";
import Confirmation from "./pages/verification/Confirmation";
import Subcription from "./pages/verification/Subcription";
import ResetPassword from "./pages/account/ResetPassword";
import Verification from "./pages/verification/Verification";
import VerificationUploadImage from "./pages/verification/VerificationUploadImage";
import VerifyIdentity from "./pages/verification/VerifyIdentity";
import VerifySelfie from "./pages/verification/VerifySelfie";
import Verified from "./pages/verification/Verified";
import Post from "./pages/property/Post";
import "./App.css";
import Plan from "./pages/property/Plan";
import VerifiedProperties from "./pages/account/VerifiedProperties";
import WatchList from "./pages/account/WatchList";
import RecentlyView from "./pages/account/RecentlyView";
import { getCookie } from "./utils";
import MyProperties from "./pages/account/MyProperties";
import OfferRequest from "./pages/account/OfferRequest";
import ViewRequest from "./pages/account/ViewRequest";
import ViewOffer from "./pages/property/ViewOffers";
import AccountSettings from "./pages/account/AccountSettings";
import PersonalInfo from "./pages/account/PersonalInfo";
import ManageProperty from "./pages/account/ManageProperty";
// import ProductPage from "./pages/ProductPage";
import LoginSecurity from "./pages/account/LoginSecurity";
import PaymentTransactionsWithStripe from "./pages/account/PaymentTransactions";
import { PropertyProvider } from "./context/PropertyContext";
import SetProperty from "./pages/property/SetProperty";
import apiService from "./services/apiService";
import ProductPage from "./pages/property/ProductPage";
import AnypropertyIt from "./pages/property/AnypropertyIt";
import HowItWorks from "./pages/property/HowItWorks";
import ForgotPassword from "./pages/auth/ForgotPassword";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);
  useEffect(() => {
    const user = localStorage.getItem("userData");
    if (user && user?.id) {
      return;
    }
    if (currentUser?.id) {
      localStorage.setItem("userData", JSON.stringify(currentUser));
    }
  }, []);
  return (
    <div>
      <PropertyProvider>
        <Routes>
          <Route path="/" element={<HomeBuy />} />{" "}
          <Route path="/subscription" element={<Subcription />} />
          <Route path="/buy" element={<HomeBuy />} />
          <Route path="/sign-up" element={<SignUp />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/sell" element={<Sell />}></Route>
          <Route path="/plan" element={<Plan />}></Route>
          <Route path="/watchList" element={<WatchList />}></Route>
          <Route path="/recentlyview" element={<RecentlyView />}></Route>
          <Route
            path="/account-settings/recentlyview"
            element={<RecentlyView />}
          ></Route>
          <Route
            path="/verifiedproperties"
            element={<VerifiedProperties />}
          ></Route>
          <Route
            path="/confirmation"
            element={<Confirmation></Confirmation>}
          ></Route>
          <Route
            path="/verification"
            element={<Verification></Verification>}
          ></Route>
          <Route
            path="/verification-upload-image"
            element={<VerificationUploadImage></VerificationUploadImage>}
          ></Route>
          <Route path="/verify-identify" element={<VerifyIdentity />}></Route>
          <Route
            path="/verify-selfie"
            element={<VerifySelfie></VerifySelfie>}
          ></Route>
          <Route path="/verified" element={<Verified></Verified>}></Route>
          <Route path="/anypropertyit" element={<AnyPropertyIt />} />
          <Route path="/browse" element={<Browse />} />
          <Route
            path="/account-settings/my-properties"
            element={<MyProperties />}
          />
          <Route
            path="/account-settings/my-properties/offer-request"
            element={<OfferRequest />}
          />
          <Route
            path="/account-settings/my-properties/view-request"
            element={<ViewRequest />}
          />
          <Route
            path="/account-settings/my-properties/view-offer"
            element={<ViewOffer />}
          />
          <Route
            path="/account-settings/my-properties/manage-property"
            element={<ManageProperty />}
          />
          <Route path="/product-view" element={<ProductPage />} />
          {/* <Route path="/product-view" element={<ProductPage />} /> */}
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route
            path="/account-settings/personal-info"
            element={<PersonalInfo />}
          />
          <Route
            path="/account-settings/login-security"
            element={<LoginSecurity />}
          />
          <Route
            path="/account-settings/payment-transactions"
            element={<PaymentTransactionsWithStripe />}
          />
          <Route path="/set-property" element={<SetProperty />} />
          <Route
            path="/account-settings/personal-info"
            element={<PersonalInfo />}
          />
          <Route
            path="/account-settings/login-security"
            element={<LoginSecurity />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/resources" element={<Resources />} />
          <Route
            path="/resources/buying"
            element={<ResourcesCategory category="Buying" />}
          />
          <Route
            path="/resources/selling"
            element={<ResourcesCategory category="Selling" />}
          />
          <Route path="/resources/:slug" element={<ResourcesPost />} />
          <Route
            path="/account-settings/payment-transactions"
            element={<PaymentTransactionsWithStripe />}
          />
          <Route path="/set-property" element={<SetProperty />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/pages/:slug" element={<Post />} />
        </Routes>
      </PropertyProvider>
    </div>
  );
}
export default App;
