import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCards,
  fetchTransactions,
  deleteCard,
} from "../../redux/actions/paymentActions";

import "./PaymentTransactions.css";
import Modal from "../../components/common/Modal";
import PaymentFormWithStripe from "../../components/account/PaymentFormWithStripe";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { Link } from "react-router-dom";
import { Box, Grid, Stack, Table, Tabs } from "@mui/material";
import AccountInfoCard from "../../components/account/AccountInfoCard";
import Tab from "@mui/material/Tab";
import { loadStripe } from "@stripe/stripe-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import apiService from "../../services/apiService";
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "pk_live_srOYpGflwsIyPtqXCeKQwa7r"
);
const PaymentTransactions = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("one");
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { cards, transactions, isLoading, error } = useSelector(
    (state) => state.payment
  );
  const [windowWidth, setWindowWidth] = useState(null);
  const [deletingCardId, setDeletingCardId] = useState(null);

  useEffect(() => {

    dispatch(fetchCards());
    dispatch(fetchTransactions());

    setWindowWidth(window.innerWidth);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // handle delete credit card

  const handleDeleteCard = async (cardId) => {
    if (!stripe || !elements) {
      setErrorMessage("Stripe has not yet loaded.");
      return;
    }
  
    setLoading(true);
    setErrorMessage(null);
  
    try {
      const response = await apiService.deleteWithToken("/payments/delete_card", {
        card: cardId,
      });
      if (response.message) {
        toast.success("Card deleted successfully");
        // Refresh the card list after successful deletion
        dispatch(fetchCards());
      }
  
      console.log("Card deleted successfully:", response); // Adjust logging based on your API response
      setLoading(false);
      // Maybe clear form here or give further instructions
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      setErrorMessage(e.message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div>
        <Header />
        <div className="payments-transactions-container">
          <div>
            <Link to="/account-settings" className="custom-link">
              Account Settings &nbsp; &nbsp;
            </Link>
  
            {`>`}
  
            <Link to="" className="custom-link">
              &nbsp; &nbsp;Payments & Transactions
            </Link>
          </div>
          <div className="payments-transactions-heading">
            Payments and Transactions
          </div>
          <div>
            <div className="payments-transactions-tabs-box">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="one" label="Payments" />
                  <Tab value="two" label="Transactions" />
                </Tabs>
              </Box>
            </div>
            {value === "one" ? (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <div className="payment-details-container">
                      {cards.length > 0 ? (
                        cards.map((card, index) => (
                          
                          <div key={index}>
                            <div className="payment-details-card">
                              <Stack spacing={2} direction="row">
                                <div>
                                  <img src="/images/payment-card.svg" alt="" />
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  {card.brand} {card.last4}
                                </div>
                              </Stack>
                              <Stack spacing={2} direction="row">
                                <div>...... {card.last4}</div>
                                <div> 
                                  Expiry: {card.exp_month}/{card.exp_year}
                                </div>
                              </Stack>
                              <div 
                                className="text-red-500 cursor-pointer" 
                                onClick={() => {
                                  setDeletingCardId(card.id);
                                  handleDeleteCard(card.id);
                                }}
                              >
                                {deletingCardId === card.id ? 'Deleting...' : 'Delete'}
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div>No payment methods available</div>
                      )}
                      <div
                        className="payment-details-add-card"
                        onClick={handleOpenModal}
                      >
                        + Add payment methods
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AccountInfoCard
                      icon={"/images/transaction_protections.svg"}
                      heading={"Make all payments through AnyProperty"}
                      para={
                        "Always pay and communicate through AnyProperty to ensure you’re protected under our Terms of Service, Payment Term of Service, cancellation and other safeguards."
                      }
                      link={"/pages/privacy-policy"}
                      linkText={"Learn more"}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <div className="transaction-payment-details">
                  <div className="transaction-payment-details-box">
                    <div className="transaction-payment-heading">
                      Account Balance
                    </div>
                    <div className="transaction-payment-dots">...</div>
                  </div>
                </div>
                <div className="flex justify-end items-baseline font-product-sans-medium">
                  <span className="transaction-payment-price"> $0</span>
                  NZD
                </div>
                <hr />
                <div style={{ marginTop: "40px" }}>
                  {
                    windowWidth > 768 ? (transactions ? TransactionsTable(transactions) : <p>Loading...</p>) : MobileTransactions(transactions)}
                </div>
              </>
            )}
          </div>
        </div>{" "}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <PaymentFormWithStripe />
        </Modal>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );

  function TransactionsTable({ transactions }) {
    return (
      <Table className="transactions_table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Address</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {
            Array.isArray(transactions) && transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.date}</td>
                  <td style={{ color: "#2B72FF" }}>
                    {transaction.address}
                  </td>
                  <td>{transaction.type}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.status}</td>
                  <td>
                    <button className="preview-button">
                      {transaction.invoice}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No transactions found</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  }

  function MobileTransactions({ transactions }) {
    return (
      <div className="flex flex-col space-y-4 p-4">
        {Array.isArray(transactions) && transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4">
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-600">Date</div>
                <div className="font-medium">{transaction.date}</div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="text-sm text-gray-600">Address</div>
                <div className="text-blue-500">{transaction.address}</div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="text-sm text-gray-600">Amount</div>
                <div className="font-medium">{transaction.amount}</div>
              </div>
              <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                Preview
              </button>
            </div>
          ))
        ) : (
          <p>No transactions found</p>
        )}
      </div>
    );
  }
};

const PaymentTransactionsWithStripe = () => (
  <Elements stripe={stripePromise}>
    <PaymentTransactions  stripe={stripePromise} />
  </Elements>
);

export default PaymentTransactionsWithStripe;


