import React, { useEffect, useState } from "react";
import "./ViewCard.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import mapIcon from "../../assets/pin.png";
const center = {
  lat: -43.528763,
  lng: 172.657002,
};

const ViewCard = ({ page, marker, edit }) => {
  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("320px");

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setContainerWidth("500px");
      } else if (window.innerWidth >= 960) {
        setContainerWidth("400px");
      } else if (window.innerWidth >= 850) {
        setContainerWidth("300px");
      } else if (window.innerWidth <= 450) {
        setContainerWidth("350px");
        setContainerHeight("200px");
      } else if (window.innerWidth <= 560) {
        setContainerWidth("450px");
        setContainerHeight("250px");
      } else if (window.innerWidth <= 674) {
        setContainerWidth("500px");
        setContainerHeight("280px");
      } else {
        setContainerWidth("250px");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-section">
      <div className="isMyProperties">
        <div className="mypropertycard-left">
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
            <GoogleMap
              mapContainerStyle={{
                width: containerWidth,
                height: containerHeight,
              }}
              options={mapOptions}
              center={{
                lat: marker?.latitude || null,
                lng: marker?.longitude || null,
              }}
              zoom={14}
            >
              {" "}
              <Marker
                position={{
                  lat: marker?.latitude || null,
                  lng: marker?.longitude || null,
                }}
                icon={{
                  url: mapIcon,
                  size: window.google?.maps?.Size
                    ? new window.google.maps.Size(40, 40)
                    : undefined,
                  anchor: window.google?.maps?.Point
                    ? new window.google.maps.Point(20, 40)
                    : undefined,
                  scaledSize: window.google?.maps?.Size
                    ? new window.google.maps.Size(40, 40)
                    : undefined,
                }}
              />
            </GoogleMap>
          </LoadScript>
        </div>
        <div className="mypropertycard-right">
          <div className="mypropertycard-top">
            <div className="card-details">
              <h2>{marker?.full_address}</h2>
              <p>Longitude: {marker?.longitude}</p>
              <p>Latitude: {marker?.latitude}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCard;
