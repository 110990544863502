import Header from "../../components/common/Header";
import React, { useCallback } from "react";
import "./AccountSettings.css";
import { Grid } from "@mui/material";
import SettingsCard from "../../components/account/SettingsCard";
import Footer from "../../components/common/Footer";
import { useNavigate } from "react-router-dom";
function AccountSettings() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="account-settings-body">
        <div className="account-settings_main-heading">Account Settings </div>
        <div className="accounts-settings-cards-row">
          <div className="mx-auto md:space-y-0 md:grid lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 md:gap-12">
            <div
              className="mx-auto w-full mb-0"
              onClick={() => navigate("/account-settings/personal-info")}
            >
              <SettingsCard
                heading="Personal Info"
                para="Provide personal details and how we can reach you"
                icon="/images/personal-info-icon.svg"
              />
            </div>
            <div
              className="mx-auto w-full my-8"
              onClick={() => navigate("/account-settings/login-security")}
            >
              <SettingsCard
                heading={"Login & Security"}
                para={"Update your password and secure your account"}
                icon={"/images/login-security-icon.svg"}
              />
            </div>
            <div
              className="mx-auto w-full my-8"
              onClick={() => navigate("/account-settings/payment-transactions")}
            >
              <SettingsCard
                heading="Payments & Transactions"
                para="Manage your payment methods and review your transactions"
                icon="/images/payements-icon.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="account-settings-body">
        <div className="account-settings_main-heading">Properties</div>
        <div className="accounts-settings-cards-row">
          <div className="mx-auto md:space-y-0 md:grid lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 md:gap-12">
            <div
              className="mx-auto w-full mb-0"
              onClick={() => navigate("/account-settings/my-properties")}
            >
              <SettingsCard
                heading={"My Properties"}
                para={
                  "Manage your property and update your property information"
                }
                icon={"/images/my_properties.svg"}
              />
            </div>
            <div
              className="mx-auto w-full my-8"
              onClick={() => navigate("/watchlist")}
            >
              <SettingsCard
                heading="Watchlist"
                para="Review your saved properties"
                icon="/images/watchlist.svg"
              />
            </div>
            <div
              className="mx-auto w-full my-8"
              onClick={() => navigate("recentlyview")}
            >
              <SettingsCard
                heading={"Recently Viewed"}
                para={"Review your recently viewed properties"}
                icon={"/images/recently_viewed.svg"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="account-settings-body">
        <div className="account-settings_main-heading">Viewings & Offers</div>
        <div className="accounts-settings-cards-row">
          <div className="mx-auto md:space-y-0 md:grid sm:grid-cols-1 md:grid-cols-2 w-full md:grid-cols-2 xl:grid-cols-3 md:gap-12">
            <div
              className="mx-auto w-full mb-0"
              onClick={() =>
                navigate("/account-settings/my-properties/view-request")
              }
            >
              <SettingsCard
                heading={"Viewing Requests"}
                para={"Manage your viewing request for your property"}
                icon={"/images/view_requested.svg"}
              />
            </div>
            <div
              className="mx-auto w-full mb-0"
              onClick={() =>
                navigate(
                  "/account-settings/my-properties/view-request?type=viewing_others"
                )
              }
            >
              <SettingsCard
                heading={"Viewing Requests for other properties"}
                para={"Manage your viewing request for other property"}
                icon={"/images/view_requested.svg"}
              />
            </div>
            <div
              className="mx-auto w-full my-8"
              onClick={() =>
                navigate("/account-settings/my-properties/offer-request")
              }
            >
              <SettingsCard
                heading={"Offers Made"}
                para={"Manage and review your offer made to properties"}
                icon={"images/offers_made.svg"}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AccountSettings;
