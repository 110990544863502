import React from "react";
import { Divider, Grid } from "@mui/material";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import "./SetPropertyRooms.css";

function SetPropertyRooms() {
  const { property, updateProperty } = useProperty();

  const handleIncrement = (propertyName) => {
    const newCount = (property.rooms[propertyName] || 0) + 1;
    updateProperty("rooms", { [propertyName]: newCount });
  };

  const handleDecrement = (propertyName) => {
    const currentCount = property.rooms[propertyName] || 0;
    if (currentCount > 0) {
      updateProperty("rooms", { [propertyName]: currentCount - 1 });
    }
  };

  const roomTypes = [
    { name: "Bedrooms", Img: "/images/bedroom.svg" },
    { name: "Bathrooms", Img: "/images/bathroom.svg" },
  ];

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Tell us more about your place
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={3} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-container">
            {roomTypes.map((room, index) => (
              <React.Fragment key={index}>
                <div className="property-room-card">
                  <div className="property-room-card-img-box">
                    <img
                      src={room.Img}
                      alt={room.name}
                      className="property-room-card-img"
                    />
                    <h3>{room.name}</h3>
                  </div>
                  <div className="property-counter">
                    <button
                      onClick={() => handleDecrement(room.name)}
                      disabled={
                        !(
                          property.rooms[room.name] &&
                          property.rooms[room.name] > 0
                        )
                      }
                    >
                      -
                    </button>
                    <span>{property.rooms[room.name] || 0}</span>
                    <button onClick={() => handleIncrement(room.name)}>
                      +
                    </button>
                  </div>
                </div>
                <Divider variant="middle" />
              </React.Fragment>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyRooms;
