import React, { useState, useEffect } from "react";
import defaultImage from "../../assets/dummyImage.jpg";

const Image = ({ src, fallback, alt, className }) => {
  const [imgSrc, setImgSrc] = useState(
    validateUrl(src) ? src : fallback || defaultImage
  );

  const handleError = () => {
    setImgSrc(fallback || defaultImage); // Use fallback if provided, otherwise defaultImage
  };

  useEffect(() => {
    setImgSrc(validateUrl(src) ? src : fallback || defaultImage); // React to changes in the src prop
  }, [src]);

  function validateUrl(url) {
    const pattern =
      /^(https?:\/\/)?[\w-]+(\.[\w-]+)+\.[\w-]+(\/[^\/#?]+)+\.(jpeg|jpg|png)$/i;
    return pattern.test(url);
  }

  return (
    <img
      className={className}
      src={imgSrc}
      onError={handleError}
      alt={alt}
      style={{ display: imgSrc ? "block" : "none" }} // Optionally hide img tag when no src is available
    />
  );
};

export default Image;
