import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "./Faq.css";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const onContactUs = () => {
    // mailto:
    window.location.href = "mailto:admin@anyproperty.co.nz";
  }

  useEffect(() => {
    setLoading(true);
    const query = `*[_type == "question"] {
      questionText,
     answer
    }`;

    sanityClient.fetch(query)
      .then((data) => {
        console.log("Fetched FAQs:", data);  // Check the structure of fetched data
        setFaqs(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header />
      <div className="container faq-container mt-12">
        {faqs && faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={index} className="mt-4 border-t border-gray-300 w-full px-12">
              <button
                className={`accordion-question w-full ${activeIndex === index ? 'active' : ''}`}
                aria-expanded={activeIndex === index}
                aria-controls={`faq${index}`}
                onClick={() => handleClick(index)}
              >
                <div className="w-full grid grid-cols-12">
                  <div className="col-span-11">
                    {faq.questionText}
                  </div>
                  <span className="col-span-1 ml-auto faq-icon">
                    {activeIndex === index ? '-' : '+'}
                  </span>
                </div>

              </button>
              <div id={`faq${index}`} className={`accordion-answer ml-2 ${activeIndex === index ? 'active' : ''}`}>
                <p>{faq.answer}</p>
              </div>
            </div>
          ))
        ) : <p>No FAQs available.</p>}
      </div>

      <div className="flex flex-col items-center justify-center h-128  p-8">
        <h2 className="text-3xl font-semibold text-gray-800">Still got questions? We got answers.</h2>
        <p className="text-gray-600 text-2xl mt-4">It's natural to have questions about Any Property, a unique way to buy and sell homes in Aotearoa.</p>
        <button className="faq-blue-button" onClick={onContactUs}>Contact Us</button>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
