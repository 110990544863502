import * as actionTypes from "../actionTypes/paymentActionTypes";
import apiService from "../../services/apiService";

const setLoading = (isLoading) => ({
  type: actionTypes.SET_LOADING,
  payload: isLoading,
});

const setError = (error) => ({
  type: actionTypes.SET_ERROR,
  payload: error,
});
export const setTransactions = (transactions) => ({
  type: actionTypes.SET_TRANSACTIONS,
  payload: transactions,
});

export const setSubscriptionStatus = (status) => ({
  type: actionTypes.SET_SUBSCRIPTION_STATUS,
  payload: status,
});

export const fetchCards = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await apiService.getWithToken("/payments/cards");
    console.log(" response.data", response.data);
    dispatch({ type: actionTypes.SET_CARDS, payload: response.data });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
export const fetchTransactions = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const transactions = await apiService.getWithToken(
      "/payments/transactions"
    );
    dispatch(setTransactions(transactions));
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// Define the async action to add a subscription
export const addSubscription =
  ({ propertyId, stripeToken, description, coupon }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      // Construct the payload for the POST request
      const payload = {
        property_id: propertyId,
        stripeToken: stripeToken,
        description: description,
        currency: "nzd", // Assuming currency is always 'nzd' based on your backend code
        coupon: coupon, // Optional, only include if present
      };

      // Make the POST request to the /payments/add_subscription endpoint
      const response = await apiService.postWithToken(
        "/payments/add_subscription",
        payload
      );

      // Check the response for success or error status
      if (response.status === "error") {
        // Handle the error, e.g., by dispatching an error action or setting an error state
        dispatch(setError(response.message));
      } else {
        // Subscription was successful, handle accordingly
        dispatch(setSubscriptionStatus("success"));
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch operation
      dispatch(setError(error.toString()));
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const deleteCard = (cardId) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiService.postWithToken("/payments/delete_card", {
        card: cardId,
      });
      if (response.message) {
        toast.success("Card deleted successfully");
      }
      console.log("Card deleted successfully:", response); // Adjust logging based on your API response
      setLoading(false);
      // Maybe clear form here or give further instructions
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      setErrorMessage(e.message);
    }
  };

// Define similar actions for add_card, update_card, delete_card, set_default_card, and transactions...
