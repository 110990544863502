import React, { useCallback, useState } from "react";
import Footer from "../../components/common/Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import "./Plan.css";

const Plan = () => {
  const navigate = useNavigate();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const onGroupContainerClick = useCallback(() => {
    if (currentUser && currentUser.id) {
      navigate("/subscription");
    } else {
      navigate("/sign-up");
    }
  }, [navigate]);

  return (
    <div className="Plan-container">
      <Header />
      <div className="container" id="start">
        <div className="plan">
          <div className="heading AnyProperty">
            <img src="/images/logo_2x.png" alt="" className="crossimage" />
            <div className="text-logo">AnyProperty Premium</div>
          </div>
          <hr className="seprateline" />
          <div className="">
            <span className="plan-normal-heading">30 days free</span>
            <span className="plan-normal-text">then $7/month after that</span>
          </div>
          <div className="quiz-window">
            <div className="quiz-window-body">
              <div className="gui-window-awards">
                <ul className="guiz-awards-row guiz-awards-header">
                  {/* <li className="guiz-awards-header-star">&nbsp;</li> */}
                  <li className="guiz-awards-header-title">Features</li>

                  <li className="guiz-awards-header-time">Premium</li>
                </ul>

                <ul className="guiz-awards-row">
                  <div className="become-text">Become a Price Maker</div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Claim & manage your listing
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Display an asking price range to potential purchasers
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Accept and negotiate offers from interested buyers
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                </ul>
                <ul className="guiz-awards-row">
                  <div className="become-text">
                    Have Control Over Your Property
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Update basic details about your property
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Display an automatically generated description about your
                      property
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Write and set your own description of your property
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Add and update details about your property’s room
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Add photos, videos, and 3D tours of your property so
                      prospective purchasers are more likely to make an offer
                    </div>

                    <div className="guiz-awards-time">Multiple</div>
                  </div>
                </ul>
                <ul className="guiz-awards-row">
                  <div className="become-text">
                    Be Found By Prospective Purchasers
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Be listed on property searches
                    </div>

                    <div className="guiz-awards-time">Featured</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Be featured as “Ready to Sell” within potential
                      purchasers’ search results
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                </ul>

                <ul className="guiz-awards-row">
                  <span className="become-text">
                    Claim & Manage Your Property
                  </span>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Display an asking price to potential purchasers
                    </div>

                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Accept & Decline offers from interested buyers
                    </div>
                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Update basic details about your property
                    </div>
                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Free Sales and Purchase Agreement, and more
                    </div>
                    <div className="guiz-awards-time">Available</div>
                  </div>
                  <div className="guiz-awards-title">
                    <div className="guiz-awards-subtitle">
                      Get listed on property searches
                    </div>
                    <div className="guiz-awards-time">Available</div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-overlay margin">
            <div className="overlay-text">
              <button
                onClick={onGroupContainerClick}
                className="plan-button button-custom"
              >
                Start free-trial
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Plan;
