import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../sanityClient";
import SanityContent from "../../components/sanity/SanityContent";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

const BlogPost = () => {
  const [postData, setPostData] = useState(null);
  // Call useParams at the top level of your component
  const { slug } = useParams();

  useEffect(() => {
    const query = `*[_type == "post" && slug.current == $slug][0]`;
    const params = { slug };
    console.log("query", query);
    console.log("slug", slug);
    sanityClient
      .fetch(query, params)
      .then((data) => setPostData(data))
      .catch(console.error);
  }, [slug]); // slug is a dependency here, so it's okay

  return (
    <>
      <Header />
      <SanityContent postData={postData} />
      <Footer />
    </>
  );
};

export default BlogPost;
