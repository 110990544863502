import React from "react";
import { useDispatch } from 'react-redux';
import { Grid } from "@mui/material";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import ImageUploaderForProperty from "../../components/common/ImageUploaderForProperty";
import { useProperty } from "../../context/PropertyContext";
import { fetchProperty, updatePropertyField } from "../../redux/actions/propertyDetailActions";
import "./SetPropertyPhotos.css";

function SetPropertyPhotos() {
  const dispatch = useDispatch();
  const { property, updateProperty } = useProperty();
  // Ensure images are initialized correctly within the photos object
 const images = property.images || Array(6).fill(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const refreshImages = async () => {
    await dispatch(fetchProperty(property.slug));
   
  };

  const handleFiles = (files) => {
    const newImages = [...images];
    for (let i = 0; i < Math.min(files.length, 6); i++) {
      const file = files[i];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const index = newImages.findIndex((image) => image === null);
          if (index !== -1) {
            newImages[index] = reader.result;
            // Update the structured object instead of just an array
            updateProperty("images", { ...property.images, images: newImages });
          }
        };
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event) => {
    handleFiles(event.target.files);
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages[index] = null;
    updateProperty("photos", { ...property.images, images: newImages });
  };

  const handleUpdateLoader = (loading) => {
    console.log("Loading state:", loading); // Here you can manage loader state, e.g., showing a loading spinner
  };

  const handleUpload = (uploadData) => {
    console.log("Upload Data:", uploadData); // Here you can manage the response from the upload
  };

  const handleThumbnail = (thumbnailData) => {
    console.log("Thumbnail Data:", thumbnailData); // Handle thumbnail data, maybe set it in the state
  };

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Upload photos for your property
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={8} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-photos-container">
          <ImageUploaderForProperty
                property={property}
                updateProperty={(field, value) => dispatch(updatePropertyField(field, value))}
                refreshImages={refreshImages}
              />
            {/*
            <div className="drag-drop-container">
              <input
                type="file"
                id="fileInput"
                multiple
                onChange={handleInputChange}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput" className="drag-drop-placeholder">
                <img
                  src="/images/upload_image.svg"
                  className="upload_button_img"
                  alt=""
                />
                Drag an image or click here to select a file
              </label>
            </div>
            <div className="empty-boxes">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="proprty-card-image-container empty-box"
                >
                  {image && (
                    <div className="proprty-card-image-container">
                      <img src={image} alt={`Uploaded Image ${index + 1}`} />
                      <button onClick={() => removeImage(index)}>x</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyPhotos;
