import React from 'react';

const HelpLink = () => {
    return (
      <a className="help" href="mailto:admin@anyproperty.co.nz">
        Need help?
      </a>
    );
  };

export default HelpLink;