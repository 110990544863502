import apiService from "../../services/apiService";
import apiServiceUploadFiles from "../../services/apiServiceUploadFiles";

export const setCountry = (slug, country) => async (dispatch) => {
  try {
    // Use the new endpoint we created
   const response = await apiService.patchWithToken(`/users/${slug}/set_country`, { country });
  
    console.log("Country update successful:", response);
    
    if (response.status === "success") {
      dispatch({
        type: "SET_COUNTRY",
        payload: country,
      });
    } else {
      throw new Error(response.message || "Failed to set country");
    }

    return response;
  } catch (error) {
    console.error("Error updating country:", error);
    throw error;
  }
};

export const clearVerificationData = () => {
  return {
    type: "CLEAR_VERIFICATION_DATA",
  };
};

export const setFrontImageID = (user, identityFront) => async (dispatch) => {
  try {
    const response = await apiServiceUploadFiles.uploadIdentityFront(user.slug, identityFront);
   
    console.log("Front image upload successful:", response);
    dispatch({
      type: "SET_FRONT_IMAGE_ID",
      payload: response.frontImageID,
    });
  } catch (error) {
    console.error("Error uploading front image:", error);
    // handle error appropriately
  }
};

export const setBackImageID = (user, identityBack) => async (dispatch) => {
  try {
    const response = await apiServiceUploadFiles.uploadIdentityBack(user.slug, identityBack);
    console.log("Back image upload successful:", response);
    dispatch({
      type: "SET_BACK_IMAGE_ID",
      payload: response.backImageID,
    });
  } catch (error) {
    console.error("Error uploading back image:", error);
    // handle error appropriately
  }
};

export const setSelfieImage = (slug, imageId) => ({
  type: "SET_SELFIE_IMAGE",
  payload: imageId
});
