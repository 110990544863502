import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import HelpLink from "../../components/common/HelpLink";
import apiService from "../../services/apiService";
import "../auth/SignUp.css";
import { clearVerificationData } from "../../redux/actions/verificationActions";

export default function Verified() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const { currentUser, isLoading } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(currentUser || {});
  const [isEditing, setIsEditing] = useState(false);
  const [info, setInfo] = useState("personal_info_text");
  const { country, frontImageID, backImageID, selfieImage } = useSelector(
    (state) => state.verification
  );
  const [verificationImages, setVerificationImages] = useState({});

  useEffect(() => {
    const fetchUserAndImages = async () => {
      try {
        const userResponse = await apiService.getWithToken("/users/current");
        if (userResponse.user) {
          console.log("User data fetched successfully:", userResponse.user);
          localStorage.setItem("userData", JSON.stringify(userResponse.user));
          setUserData(userResponse.user);
    
          // Fetch verification images
          try {
            const imagesResponse = await apiService.getWithToken(`/users/${userResponse.user.slug}/verification_images`);
            console.log("Images response:", imagesResponse);
            if (imagesResponse.status === "success") {
              setVerificationImages(imagesResponse.images);
            } else {
              console.error("Error fetching images:", imagesResponse);
            }
          } catch (imgError) {
            console.error("Error fetching verification images:", imgError);
          }
        } else {
          console.error("User data not found in response:", userResponse);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError(`Failed to load user data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndImages();
  }, []); 

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSave = async () => {
    setIsEditing(true);
    try {
      const response = await apiService.postWithToken(
        `/users/${currentUser.slug}/request_verification`,
        {}
      );
      if (response?.status === "success") {
        setMessage({
          type: "success",
          message: "Your profile has been verified now.",
        });
        setAlertOpen(true);
        setTimeout(() => {
          navigate("/account-settings/personal-info");
        }, 2000);
      }
    } catch (e) {
      console.log(e);
      setMessage({
        type: "error",
        message: "Verification request failed. Please try again.",
      });
      setAlertOpen(true);
    } finally {
      setIsEditing(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        {/* ... (rest of the existing JSX) ... */}
      </div>

      <div className="container mt-5 mb-5 verification">
        <div className="row justify-content-center confirmation">
          <div className="col-md-4 signup confirmation bg-gray text-center shadow p-5">
            <h3 className="text-center">Identity verification</h3>
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={100} />
            </Box>
            <h5 className="text-center mt-4">Almost There!</h5>
            
           

            <p className="mt-5">
              AnyProperty will review your data in a timely manner.
            </p>
            <p>
              Once your application has been verified, we will <br />
              send you an email notification.{" "}
            </p>{" "}
            <p className="m-b-50">
              Do not refresh your browser during the process.
            </p>
            <div className="m-b-50">
              <button
                onClick={handleSave}
                disabled={isEditing}
                className="button"
              >
                {isEditing ? "Completing..." : "Complete"}
              </button>
            </div>{" "}
            <div className="m-b-50">
              <NavLink className="back" to="/verify-selfie">
                <b className="mt-3">Back</b>
              </NavLink>
            </div>{" "}
            <div className="m-b-50">
              <HelpLink />
            </div>

             {/* Display verification images */}
             <div className="verification-images mt-4 flex flex-col gap-4 items-center">
              {verificationImages.selfie && (
                <div>
                  <h6>Selfie</h6>
                  <img src={verificationImages.selfie.image_url} alt="Selfie" style={{maxWidth: '200px'}} />
                </div>
              )}
              {verificationImages.identity_front && (
                <div>
                  <h6>ID Front</h6>
                  <img src={verificationImages.identity_front.image_url} alt="ID Front" style={{maxWidth: '200px'}} />
                </div>
              )}
              {verificationImages.identity_back && (
                <div>
                  <h6>ID Back</h6>
                  <img src={verificationImages.identity_back.image_url} alt="ID Back" style={{maxWidth: '200px'}} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </div>
  );
}