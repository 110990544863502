import React from "react";
import { useNavigate } from "react-router-dom";
import PropertyCard from "./PropertyCard";
import { Skeleton } from "../common/Skeleton";

const PropertySections = ({ isLoading, propertiesData }) => {
  if (!propertiesData) {
    // Handle the case where propertiesData is undefined
    return null;
  }

  const navigate = useNavigate();

  // Function to break properties data into chunks of given size
  const chunkArray = (array, chunkSize) => {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  // Function to handle property click, navigates to detailed property view
  const onPropertyClick = (slug) => {
    navigate(`/product-view?slug=${slug}`);
  };

  // Break the propertiesData into chunks of 2 for row-based display
  const chunkedProperties = chunkArray(propertiesData, 2);

  return (
    <div className="verified-box-container">
      {isLoading ? (
        // Display loading placeholders if data is still loading
        <div className="skeleton-container">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        // Map each chunked pair to a section for display
        chunkedProperties.map((propertyPair, index) => (
          <section key={index} className="row">
            {propertyPair.map((property, idx) => {
              const property_details = Array.isArray(property.property_details)
                ? property.property_details[0]
                : property; // Accessing the first index of property_details

              const pic =
                property_details.legal_description !== "" &&
                property_details.legal_description !== undefined
                  ? property_details?.legal_description?.split("||")[0]
                  : property_details?.images;
              return (
                <div
                  onClick={() => onPropertyClick(property_details.slug)}
                  key={property_details.id}
                  className={`verified-box ${idx % 2 === 0 ? "left" : "right"}`}
                >
                  <PropertyCard
                    title={property_details?.full_address}
                    bedroom={`${property_details?.bedrooms}`}
                    bathrooms={`${property_details?.bathrooms ?? 0}`}
                    price={`${
                      property_details?.estimate_upper?.toLocaleString() ||
                      property_details?.market_value?.toLocaleString()
                    }`}
                    pic={pic}
                    page="verified"
                  />
                </div>
              );
            })}
          </section>
        ))
      )}
    </div>
  );
};

export default PropertySections;
