import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";

const SanityResourcesRelatedPosts = ({ category, currentSlug }) => {

    const [resourcesData, setResourcesData] = useState([]);
    useEffect(() => {

        const query = `*[_type == "resourcePost" && category == "${category}"]`;

        sanityClient.fetch(query).then((data) => {
            setResourcesData(data);
        });
    }, []);



    if (!resourcesData || resourcesData.length === 0) {
        return <p>No records found.</p>;
    }

    return (
        // Your JSX code goes here
        <div>
            { resourcesData.length < 2 ? (
                <p>No records found.</p>
            ) : (
                resourcesData.map((resource, index) => (
                    resource.slug.current !== currentSlug ? (
                        <div className="w-full mb-2" key={index}>
                            <a href={`/resources/${resource.slug.current}`} className="text-blue-500 hover:underline">
                                {resource.title}
                            </a>
                        </div>
                    ) : null
                ))
            )}
        </div>
    );
};

export default SanityResourcesRelatedPosts;