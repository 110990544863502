import React, { useState } from 'react';
import './PropertyFilterDropDown.css';

const PropertyFilterDropDown = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Newest');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option); // Call the callback function with the selected option
  };

  return (
    <div className="relative inline-block text-left">
      <div className="border border-black border-2 ">
        <button
          type="button"
          className='p-1 filter-button'
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {selectedOption}
         
        </button>
      </div>

      {isOpen && (
        <div
          className="z-50 origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1 " role="none">
            <a
              href="#"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 border-b"
              role="menuitem"
              onClick={() => handleOptionClick('Newest')}
            >
              Newest
            </a>
            <a
              href="#"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 border-b"
              role="menuitem"
              onClick={() => handleOptionClick('Lowest Price')}
            >
              Lowest Price
            </a>
            <a
              href="#"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleOptionClick('Highest Price')}
            >
              Highest Price
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyFilterDropDown;
