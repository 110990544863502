import React, { useState, useRef, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./PaymentFormWithStripe.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "../../services/apiService";
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "pk_live_srOYpGflwsIyPtqXCeKQwa7r"
);

const PaymentForm = () => {
  const [complete, setComplete] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);

  const updateComplete = (field, isComplete) => {
    setComplete((prev) => ({ ...prev, [field]: isComplete }));
  };

  useEffect(() => {
    if (complete.number && complete.expiry && complete.cvc) {
      // Automatically focus to the next field isn't necessary because all fields are complete
    }
  }, [complete]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setErrorMessage("Stripe has not yet loaded.");
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      toast.error(error.message);
      setLoading(false);
    } else {
      try {
        const response = await apiService.postWithToken("/payments/add_card", {
          stripeToken: token.id,
        });
        if (response.message) {
          toast.success("Card added successfully");
        }

        console.log("Card added successfully:", response); // Adjust logging based on your API response
        setLoading(false);
        // Maybe clear form here or give further instructions
      } catch (e) {
        setLoading(false);
        toast.error(e.message);
        setErrorMessage(e.message);
      }
    }
  };

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form className="viewing-payment__body" onSubmit={handleSubmit}>
      <label htmlFor="cardNumber" className="creditCard__label">
        Card Number
      </label>
      <div className="card-number">
        <CardNumberElement
          id="cardNumber"
          options={options}
          onChange={(event) => updateComplete("number", event.complete)}
          ref={cardNumberRef}
        />
      </div>
      <div className="flex">
        <div className="w-full mr-4">
          <label htmlFor="cardExpiry" className="creditCard__label">
            Expiry Date
          </label>
          <CardExpiryElement
            id="cardExpiry"
            options={options}
            onChange={(event) => updateComplete("expiry", event.complete)}
            ref={cardExpiryRef}
          />
        </div>
        <div className="w-full">
          <label htmlFor="cardCvc" className="creditCard__label">
            CVC
          </label>
          <CardCvcElement
            id="cardCvc"
            options={options}
            onChange={(event) => updateComplete("cvc", event.complete)}
            ref={cardCvcRef}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="text-red text-left pl-2">{errorMessage}</div>
      )}
      <button
        type="submit"
        disabled={
          loading || !complete.number || !complete.expiry || !complete.cvc
        }
      >
        {loading ? "Saving..." : "Save Card Information"}
      </button>{" "}
      <ToastContainer position="top-center" autoClose={5000} />
    </form>
  );
};

const PaymentFormWithStripe = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default PaymentFormWithStripe;
