import React, { useState } from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, Box, Grid, Table, Tabs, TextField, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import apiService from "../../services/apiService";
import AccountInfoCard from "../../components/account/AccountInfoCard";
import "./ResetPassword.css";

function ResetPassword() {
  const navigate = useNavigate();
  const [value, setValue] = useState("one");
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const reset_password_token = queryParams.get("reset_password_token");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const isPasswordStrong = (password) => {
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return regex.test(password);
  };

  const handlePasswordChange = async () => {
    if (!password || !confirmPassword) {
      setMessage({
        type: "error",
        message: "Both password fields are required.",
      });
      setAlertOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setMessage({
        type: "error",
        message: "Passwords do not match.",
      });
      setAlertOpen(true);
      return;
    }

    if (!isPasswordStrong(password)) {
      setMessage({
        type: "error",
        message:
          "Password must be at least 8 characters long, including uppercase, lowercase, numeric, and special characters.",
      });
      setAlertOpen(true);
      return;
    }

    const data = {
      reset_token: reset_password_token,
      password,
    };

    try {
      const response = await apiService.post("/reset-password", data);
      setMessage({
        type: "success",
        message: "Password has been successfully updated!",
      });
      setAlertOpen(true);
      setTimeout(() => {
        navigate(`/login`);
      }, 2000);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Invalid token or server error.",
      });
      setAlertOpen(true);
      console.error("Error during password reset", error);
    }
  };

  return (
    <>
      <Header />
      <div className="login-security-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab value="one" label="Login" />
              </Tabs>
            </Box>
            <Table>
              {value === "one" && (
                <>
                  <br />
                  <tr className="m-10">
                    <td>
                      <TextField
                        label="New Password"
                        type="password"
                        fullWidth
                        required
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                  <br />
                  <tr className="m-10">
                    <td>
                      <TextField
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        required
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      <Button
                        onClick={handlePasswordChange}
                        variant="contained"
                        color="primary"
                      >
                        Update Password
                      </Button>
                    </td>
                  </tr>
                </>
              )}
            </Table>
          </Grid>
          <Grid item xs={12} md={6}>
            <AccountInfoCard
              icon={"/images/loging_security.svg"}
              heading={"Keeping your account secure"}
              para={
                "We regularly review accounts to make sure they’re as secure as possible. We’ll also let you know if there’s more we can do to increase the security of your account."
              }
              link={""}
              linkText={"Learn more"}
            />
          </Grid>
        </Grid>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </>
  );
}

export default ResetPassword;
