import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchProperty } from "../../redux/actions/propertyDetailActions";
import Tab from "@mui/material/Tab";
import { Box, Grid, Table, Tabs } from "@mui/material";
import "./ProductPage.css";
import Header from "../../components/common/Header";
import ImageSlider from "../../components/common/ImageSlider";
import Footer from "../../components/common/Footer";
import Image from "../../components/common/Image";
import AlertBox from "../../components/common/AlertBox";
import GoogleMapForPages from "../../components/map/GoogleMapForPages";
import PopupModal from "../../components/common/PopupModal";
import PopupModalMobile from "../../components/common/PopupModalMobile";
import apiService from "../../services/apiService";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [openAlert, setAlertOpen] = useState(false);
  const [openRequestView, setOpenRequestView] = useState(false);
  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const [openMobileMakeanOffer, setOpenMobileMakeanOffer] = useState(false);

  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    property: propertyData,
    isLoading: propertyLoading,
    error,
  } = useSelector((state) => state.property);

  const checkUser = () => {
    if (!currentUser || currentUser === null) {
      setMessage({
        type: "error",
        message: "Please login to make this action",
      });
      setAlertOpen(true);
      navigate("/login");
      return false;
    }
    return true;
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const footerRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProperty(slug));

    if (value === "0") {
      window.location.hash = "photos";
      const element = document.getElementById("photos");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    if (value === "1") {
      window.location.hash = "overview";
      const element = document.getElementById("overview");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    if (value === "2") {
      window.location.hash = "land-information";
      const element = document.getElementById("land-information");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    if (value === "3") {
      window.location.hash = "construction";
      const element = document.getElementById("construction");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    if (value === "4") {
      window.location.hash = "interiors";
      const element = document.getElementById("interiors");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    if (value === "5") {
      window.location.hash = "zones";
      const element = document.getElementById("zones");
      if (element) {
        window.scrollBy(0, -210); // Adjust scroll position
      }
    }
    const checkScroll = () => {
      if (window.scrollY >= 450) {
        setIsScrolled(true);
        if (window.scrollY >= 1500) {

          setIsFooterVisible(true);
        } else {
          setIsFooterVisible(false);
        }

      } else {
        setIsScrolled(false);
      }
    };



    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, [dispatch, slug, value]);

  const handleRequestView = () => {
    setOpenRequestView(true);
    setOpenMakeOffer(false);
    setOpen(true);
  };

  const handleMakeOffer = () => {
    setOpenMakeOffer(true);
    setOpenRequestView(false);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const images = propertyData?.legal_description?.split("|");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setMessage({
        type: "success",
        message: "Property url copied to clipboard, share with your friends",
      });
      setAlertOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const requestView = async ({ dates, amount }) => {
    if (!checkUser()) {
      return;
    }
    const url = `/users/${currentUser.slug}/viewings`;
    let newViewing = {
      user_id: currentUser.id,
      property_id: propertyData.id,
      viewing_dates_attributes: dates,
    };
    try {
      setLoading(true);
      const viewing = await apiService.postWithToken(url, newViewing);
      const response = await apiService.postWithToken(
        `${url}/${viewing.id}/request_viewing`,
        newViewing
      );
      console.log("Viewing request successful", response);
      setMessage({
        type: "success",
        message: "Viewing request successful",
      });
      setAlertOpen(true);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      setLoading(false);
      setMessage({
        type: "error",
        message: error,
      });
      setAlertOpen(true);
    }
  };

  const makeAnOffer = async ({ dates, amount }) => {
    if (!checkUser()) {
      return;
    }
    const url = `/users/${currentUser.slug}/viewings`;
    let newViewing = {
      user_id: currentUser.id,
      property_id: propertyData.id,
      amount,
    };
    try {
      setLoading(true);
      const responseView = await apiService.postWithToken(url, newViewing);

      const responseOffer = await apiService.postWithToken(
        `/users/${currentUser.slug}/viewings/${responseView.id}/buyer_making_offer`,
        { offer_price: 50000 }
      );
      console.log("Offer request successful", responseOffer);
      setMessage({
        type: "success",
        message: "Offer request successful",
      });
      setAlertOpen(true);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      setLoading(false);
      setMessage({
        type: "error",
        message: error,
      });
      setAlertOpen(true);
    }
  };

  const makeAnOfferForMobile = () => {
    setOpenMobileMakeanOffer(true);
    //setOpenRequestView(false);
  };

  const addToWatchList = async (slug, remove = false) => {
    if (!checkUser()) {
      return;
    }
    const url = `/users/${currentUser.slug}/watchlists`;

    try {
      setLoading(true);
      const response = await apiService.postWithToken(url, {
        watchlist: {
          name: "my-watchlist",
          properties: [slug],
        },
      });
      dispatch(fetchProperty(slug));
      setLoading(false);
      setMessage({
        type: "success",
        message: remove ? "Removed from watchlist" : "Added to watchlist",
      });
      setAlertOpen(true);
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      setLoading(false);
      setMessage({
        type: "error",
        message: error,
      });
      setAlertOpen(true);
    }
  };

  const viewVirtualTour = async () => {
    if (!checkUser()) {
      return;
    }
    const url = `/users/${currentUser.slug}/viewings`;
    let newViewing = {
      user_id: currentUser.id,
      property_id: propertyData.id,
    };
    try {
      setLoading(true);
      const responseView = await apiService.postWithToken(url, newViewing);

      const responseOffer = await apiService.getWithToken(
        `/viewings/${responseView.id}/virtual_tour`
      );
      console.log("Offer request successful", responseOffer);
      setMessage({
        type: "success",
        message: "Offer request successful",
      });
      setAlertOpen(true);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      setLoading(false);
      setMessage({
        type: "error",
        message:
          "Virtual tour request failed, there is no virtual tour created for this property",
      });
      setAlertOpen(true);
    }
  };

  const claimProperty = async () => {
    if (!checkUser()) {
      return;
    }
    navigate(`/set-property?slug=${slug}`);
  };

  const saveInWatchList = propertyData?.is_watchlist;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="product-page">
      <Header />
      <div className="product-container">
        <ImageSlider images={images} />
        <div className="product-page-content">
          <div
            className={`product-page-header  top-0 ${isScrolled ? "mt-0 fixed" : "mt-1"
              } bg-white  w-full z-10`}
          >
            <div className="product-view-content-nav flex flex-row w-full">
              {" "}
              <div className="product-view-content-nav-elements text-sm flex hidden md:block mt-1">
                <Tabs
                  className="flex overflow-auto"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab className="min-w-0" value="0" label="Photos" />
                  <Tab className="min-w-0" value="1" label="Overview" />
                  <Tab className="min-w-0" value="2" label="Land Information" />
                  <Tab className="min-w-0" value="3" label="Construction" />
                  <Tab className="min-w-0" value="4" label="Interiors" />
                  <Tab className="min-w-0" value="5" label="Zones" />
                </Tabs>
              </div>
              <div className="product-view-content-nav-elements">
                <ul>
                  <li onClick={copyToClipboard}>
                    <img
                      src="./images/share-icon.jpg"
                      style={{
                        width: "14px",
                        height: "14px",
                        marginRight: "8px",
                      }}
                    />
                    <span>Share</span>
                  </li>
                  {!saveInWatchList && (
                    <li onClick={() => addToWatchList(slug)}>
                      <img
                        src="./images/save-icon.jpg"
                        style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "8px",
                        }}
                      />
                      <span>{isLoading ? "Saving... " : "Save"}</span>
                    </li>
                  )}
                  {saveInWatchList && (
                    <li onClick={() => addToWatchList(slug, true)}>
                      <img
                        src="./images/save-icon.jpg"
                        style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "8px",
                        }}
                      />
                      <span>{isLoading ? "Removing... " : "Saved"}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="product-view-details grid grid-cols-12 pt-34 lg:pt-22">
            <div className="col-span-full md:col-span-6 lg:col-span-7 p-5">
              <div className="product-view-details-content">
                <p style={{ marginBottom: "20px" }}>
                  {propertyData?.full_address}
                </p>
                <div className="product-view-details-content-group">
                  <div className="product-view-details-content-flex-item">
                    <img
                      src="./images/bedrooms.jpg"
                      style={{ width: "40px", height: "30px" }}
                    />
                    <span>{propertyData?.bedrooms} Bedrooms</span>
                  </div>
                  <div className="product-view-details-content-flex-item">
                    <img
                      src="./images/bathroom.jpg"
                      style={{ width: "40px", height: "30px" }}
                    />
                  <span>{propertyData?.bathrooms ?? 0} Bathrooms</span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div
                className={`product-view-details-card-content py-5 sm:hidden `}
              >
                <h4>Market Rate</h4>
                <h2>
                  {Number.isFinite(propertyData?.estate_type)
                    ? `${propertyData?.estate_type.toLocaleString()} NZD`
                    : propertyData?.estate_type}
                </h2>
              </div>
              <div id="overview" className="product-view-details-content">
                <h2>Property Details</h2>
                <p>{propertyData?.description}</p>
              </div>
              <div id="map" className="flex flex-col mt-4 ">
                <h2>Map</h2>
                <div className=" m-5">
                  <GoogleMapForPages
                    center={{
                      lat: propertyData?.latitude,
                      lng: propertyData?.longitude,
                    }}
                  />
                </div>
              </div>
              <div
                id="land-information"
                className="product-view-details-content"
              >
                <h2>Land Information</h2>
                <div className="product-view-details-content-group">
                  <div className="product-view-details-content-block-item">
                    <h4>Land Area</h4>
                    <span>{propertyData?.land_area}</span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4>Land Description</h4>
                    <span>{propertyData?.land_area}</span>
                  </div>
                </div>
              </div>
              <div id="construction" className="product-view-details-content">
                <h2>Construction</h2>
                <div className="product-view-details-content-group">
                  <div className="product-view-details-content-block-item">
                    <h4>Decade</h4>
                    <span>{propertyData?.decade}</span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4>Floor Area</h4>
                    <span>{propertyData?.floor_area || "No data"} </span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4>Wall Material</h4>
                    <span>{propertyData?.wall_construction || "No data"} </span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4>Roof Material</h4>
                    <span>{propertyData?.roof_construction || "No data"} </span>
                  </div>
                </div>
              </div>{" "}
              <div id="interiors" className="product-view-details-content">
                <h2>Interiors</h2>
                <div className="product-view-details-content-group">
                  <div className="product-view-details-content-block-item">
                    <h4></h4>
                    <span></span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4></h4>
                    <span></span>
                  </div>
                </div>
              </div>{" "}
              <div id="zones" className="product-view-details-content">
                <h2>Zone</h2>
                <div className="product-view-details-content-group">
                  <div className="product-view-details-content-block-item">
                    <h4>Local Zone</h4>
                    <span>{propertyData?.land_zone || "No data"} </span>
                  </div>
                  <div className="product-view-details-content-block-item">
                    <h4>Local Council</h4>
                    <span>{propertyData?.local_council}</span>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="flex justify-end col-span-full md:col-span-6 lg:col-span-5 p-5 lg:justify-end justify-center bg-white md:relative hidden md:block">
              <div className={`product-view-details-price-card bg-white py-5 px-5 lg:px-10  ${isScrolled ? (isFooterVisible ? "relative mt-1200 z-0 " : "z-5 fixed md:top-32 lg:top-24") : " z-10 mt-1"}`}>

                <div className="product-view-details-card-content py-5">
                  <h4>Market Rate</h4>
                  <h2>
                    {Number.isFinite(propertyData?.estate_type)
                      ? `${propertyData?.estate_type.toLocaleString()} NZD`
                      : propertyData?.estate_type}
                  </h2>
                </div>
                <div className="product-view-details-card-content">
                  <div className="product-view-details-card-content-flex">
                    <h4>Make an offer on any property in Christchurch</h4>
                    <img
                      src="./images/info.jpg"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </div>
                  <div className="product-view-details-card-content-flex">
                    <button onClick={viewVirtualTour}>View Virtual Tour</button>
                    <button onClick={handleRequestView}>Request to View</button>
                  </div>
                  <button className="block-button" onClick={handleMakeOffer}>
                    Make an offer
                  </button>
                </div>
                <PopupModal
                  open={open}
                  isLoading={isLoading}
                  setOpen={setOpen}
                  handleClick={openRequestView ? requestView : makeAnOffer}
                  title={propertyData?.full_address}
                  description={
                    openRequestView
                      ? "Request a viewing for"
                      : "What would you like to offer for"
                  }
                  subDescription={
                    openRequestView
                      ? "Select a few time slots that best suit you"
                      : "Make an offer"
                  }
                />
                <PopupModalMobile
                  open={openMobileMakeanOffer}
                  isLoading={isLoading}
                  setOpen={setOpenMobileMakeanOffer}
                  handleClick={makeAnOffer}
                  title={propertyData?.full_address}
                  description={
                    openRequestView
                      ? "Request a viewing for"
                      : "What would you like to offer for"
                  }
                  subDescription={"Make an offer"}
                  propertyData={propertyData}
                  slug={slug}
                />
                <div className="product-view-details-card-content">
                  <div className="product-view-details-card-content-flex">
                    <h4>Are you the owner of the property?   </h4>
                    <h5 className="bg-black text-white px-2 font-size-8">Claimed?  {propertyData?.is_claimed ? "Yes" : "No"} </h5>
                    <img
                      src="./images/info.jpg"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </div>

                  <button className="block-button" onClick={claimProperty}>
                    Verify Property
                  </button>
                </div>
              </div>
            </div>
            {/* below for mobile action panel */}
            <div className="w-full p-5 flex justify-center bg-white fixed bottom-0 z-10 md:hidden">
              <div className="product-view-details-price-card px-2 bg-grey-500">
                <div className="product-view-details-card-content">
                  <div className="product-view-details-card-content-flex">
                    <h4 className="text-base productsans_bold">
                      Are you the owner of the property ?{" "}
                    </h4>
                  </div>
                  <div className="product-view-details-card-content-flex">
                    <button
                      className="block-button mr-1"
                      onClick={makeAnOfferForMobile}
                    >
                      Make an offer
                    </button>
                    <button
                      className="block-button2 ml-1"
                      onClick={handleRequestView}
                    >
                      Request to view
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
      <Footer ref={footerRef} />
    </div>
  );
};

export default ProductPage;
