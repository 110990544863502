import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { BiSearch } from "react-icons/bi";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import mapIcon from "../../assets/pin.png";
import { useProperty } from "../../context/PropertyContext";

const SetPropertyPin = () => {
  const { property, updateProperty, loading } = useProperty();
 
  const [markerPosition, setMarkerPosition] = useState(
    property?.place?.coordinates || {
      lat: -43.528763,
      lng: 172.657002,
    }
  );
  const [addressInput, setAddressInput] = useState("");

  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("450px");
  const [containerRadius, setContainerRadius] = useState("20px");

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    draggable: true,
  };

  useEffect(() => {
    if (!loading && property?.place) {
      if (property.place.coordinates) {
        setMarkerPosition(property.place.coordinates);
      }
      if (property.place.address) {
        setAddressInput(property.place.address);
      }
    }
  }, [property, loading]);

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    updateProperty("place", {
      ...property.place,
      coordinates: { lat: newLat, lng: newLng },
    });
  };

  const handleAddressChange = (event) => {
    const newAddress = event.target.value;
    setAddressInput(newAddress);
    updateProperty("place", {
      ...property.place,
      address: newAddress,
    });
  };

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box flex flex-row">
        <div className="mr-8">
          <p className="set-properties-rooms-heading">
            Is the pin on the right spot?
          </p>
          <p className="set-properties-rooms-para">
            Drag the map to adjust the pin.
          </p>
        </div>
        <div>
          <p className="set-properties-rooms-para">
            Address: {property?.place?.address || ""}
          </p>
          <p className="set-properties-rooms-para">
            Pin: {property?.place?.coordinates?.lat || ""}, {property?.place?.coordinates?.lng || ""}
          </p>
        </div>
      </div>
    
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={2} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="set-properties-map-image">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
              <GoogleMap
                mapContainerStyle={{
                  width: containerWidth,
                  height: containerHeight,
                  borderRadius: containerRadius,
                }}
                options={mapOptions}
                center={markerPosition}
                zoom={14}
              >
                <Marker
                  position={markerPosition}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                  icon={{
                    url: mapIcon,
                    size: window.google?.maps?.Size
                      ? new window.google.maps.Size(60, 60)
                      : undefined,
                    anchor: window.google?.maps?.Point
                      ? new window.google.maps.Point(20, 60)
                      : undefined,
                    scaledSize: window.google?.maps?.Size
                      ? new window.google.maps.Size(60, 60)
                      : undefined,
                  }}
                />
              </GoogleMap>
            </LoadScript>
            <div className="search-box-onmap">
              <input
                type="text"
                placeholder="Enter address here"
                value={addressInput}
                onChange={handleAddressChange}
              />
              <div className="search-icon">
                <BiSearch />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SetPropertyPin;