import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserViewingsAndOffers } from "../../redux/actions/userActions";
import "./OfferRequest.css";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import MyPropertyCard from "../../components/account/MyPropertyCard";

const OfferRequest = () => {
  const dispatch = useDispatch();
  const { viewingsAndOffers, currentUser } = useSelector((state) => state.user);
  const { offers } = viewingsAndOffers;

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchUserViewingsAndOffers(currentUser));
    }
  }, [currentUser]);
  return (
    <div className="offer-request">
      <Header />
      <div className="my-properties-subheader-nav">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="/account-settings/my-properties" className="custom-link">
            &nbsp; &nbsp;My Properties &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="" className="custom-link">
            &nbsp; &nbsp;Offer Made
          </Link>
        </div>
        <div className="payments-transactions-heading">Offer Requests</div>
      </div>
      {offers &&
        offers.map((o) => (
          <MyPropertyCard
            location="locationA"
            marker={o.property}
            isManaged={false}
            page="isMyProperties"
          />
        ))}
      <Footer />
    </div>
  );
};

export default OfferRequest;
