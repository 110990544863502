
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import mapIcon from "../../assets/pin.png";


const center = {
  lat: -43.528763,
  lng: 172.657002,
};
const CustomOverlay = ({ text }) => (
  <div
    style={{
      background: "white",
      padding: "5px 5px",
      width: "200px",
      borderRadius: "30px",
      border: "1px solid #000",
      fontSize: "12px",
      color: "#333",
      fontFamily: "var(--font-productsans_bold)",
      textAlign: "center",
    }}
  >
    <h1>{text}</h1>
  </div>
);
const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});
const ClusterGoogleMap = ({ data }) => {
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [mapsLoaded, setMapsLoaded] = useState(false); // State to track if Google Maps script is loaded

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  const containerStyle = {
    width: "100%",
    height: isMobile ? "50vh" : "90vh",
  };

  const navigate = useNavigate();
  const mapOptions = {
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: true,
    fullscreenControl: true,
    dragging: true,
  };
  const onPropertyClick = (slug) => {
    //navigate(`/product-view?slug=${slug}`);
    const url = `/product-view?slug=${slug}`;
    window.open(url, '_blank');
  };

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
<circle cx="120" cy="120" r="70" fill="rgba(35,55,105,0.8)"/>
</svg>`;
  const svgUrl = `data:image/svg+xml,${encodeURIComponent(svg)}`;

  const onMapLoad = () => {
    setMapsLoaded(true); // Set state to true when Google Maps is loaded
  };
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
      onLoad={onMapLoad}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        center={center}
        zoom={15}
       
      >
        <MarkerClusterer
          options={{
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            gridSize: 60,
            maxZoom: 15,
            styles: [
              {
                url: svgUrl, // replace with the path to your image
                height: 50,
                width: 50,
                textColor: '#fff', // this will change the color of the number on the cluster
              },
            ],
          }}
        >
          {(clusterer) =>
            data.map((marker, index) => (
              <React.Fragment key={index}>
                {marker.latitude && marker.longitude && (
                  <Marker
                    onMouseOver={() => setHoveredMarker(index)}
                    onMouseOut={() => setHoveredMarker(null)}
                    onClick={() => onPropertyClick(marker.slug)}
                    position={{
                      lat: marker.latitude || null,
                      lng: marker.longitude || null,
                    }}
                    icon={{
                      url: mapIcon,
                      size: window.google?.maps?.Size
                        ? new window.google.maps.Size(40, 40)
                        : undefined,
                      anchor: window.google?.maps?.Point
                        ? new window.google.maps.Point(20, 40)
                        : undefined,
                      scaledSize: window.google?.maps?.Size
                        ? new window.google.maps.Size(40, 40)
                        : undefined,
                    }}
                   
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    clusterer={clusterer}
                  >
                    <CustomOverlay text={marker.estimate_upper} />
                  </Marker>
                )}
                {hoveredMarker === index && marker?.estate_type && (
                  <OverlayView
                    position={{
                      lat: marker.latitude,
                      lng: marker.longitude,
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    <CustomOverlay
                      text={
                        Number.isFinite(marker?.estate_type)
                          ? `${marker?.estate_type.toLocaleString()} NZD`
                          : marker?.estate_type
                      }
                    />
                  </OverlayView>
                )}
              </React.Fragment>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(ClusterGoogleMap);
