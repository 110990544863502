import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/common/Header";
import { setUser } from "../../redux/actions/userActions";
import axios from "axios";
import AlertBox from "../../components/common/AlertBox";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/common/Footer";
import Cookies from "js-cookie";
import apiService from "../../services/apiService";
import "./Login.css";
import { Link } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const confirmToken = queryParams.get("confirm");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    const confirm = async () => {
      if (confirmToken) {
        try {
          await apiService.get(
            `/users/confirmation?confirmation_token=${confirmToken}`
          );
          setMessage({
            type: "success",
            message: "Your email has been confirmed, please login",
          });
          setOpen(true);
        } catch (e) {
          setMessage({
            type: "error",
            message: "This token is not correct.",
          });
          setOpen(true);
        }
      }
    };
    confirm();
  }, [location]); // Depend on `location` to re-run effect if URL changes

  const validatePassword = (password) => {
    return password.length >= 5; // Example criteria
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 5 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!isValid) return;

    // If inputs are valid, proceed with API call
    const data = {
      user: {
        email: email,
        password: password,
      },
    };

    const apiEndpoint = "/users/sign_in";

    try {
     
      setLoading(true);
      const response = await apiService.post(apiEndpoint, data);
      if (response.token) {
        Cookies.set("token", response.token, { expires: 30, path: "/" });
        const current = await apiService.getWithToken("/users/current");
        if (current.user) {
          localStorage.setItem("userData", JSON.stringify(current.user));
          dispatch(setUser(current.user));

          navigate("/browse");
        }
      } else {
        setMessage({
          type: "error",
          message: "Login failed. Please check your email and password.",
        });

        setLoading(false);
        setOpen(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Login failed", error);
      setMessage({
        type: "error",
        message: `Login failed: ${error}`,
        error,
      });
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="login-container">
      <Header />
      <form onSubmit={handleLogin} className="container">
        <div className="login">
          <div className="head">
            <p className="login_heading">Log in or sign up</p>
          
          </div>
          <hr className="seprateline" />
          <div className="login-body">
            <div className="heading">
              <div>Kia Ora</div>
              <div>Welcome to AnyProperty</div>
            </div>
            <div className="email">
              <p className="email_heading">Email</p>
              <input
                required
                type="email"
                name="email"
                className="email_subheading"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className="email">
              <p className="email_heading">Password</p>
              <input
                required
                name="password"
                type="password"
                className="email_subheading"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />{" "}
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
            </div>
            <br />{" "}
            <button disabled={isLoading} className="continue">
              <span className="continuebutton">
                {isLoading ? "Logging in..." : "Login"}
              </span>
            </button>
            <br />
            <br />
          </div>

          <hr className="seprateline" />
          <div>
            <p className="text">Forgot your password? <Link to="/forgot-password">Reset it</Link></p>
          </div>
          <br />
          <br />
          
          <p className="text">New to AnyProperty? <Link to="/sign-up">Sign Up</Link></p>
        
        </div>{" "}
      </form>
      <AlertBox
        open={open}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
      <Footer />
    </div>
  );
}

export default Login;
