import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "./Resources.css";
import { useNavigate, useParams } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import { InsertDriveFile } from "@mui/icons-material";

import SanityResourcesRelatedPosts from "../../components/sanity/SanityResourcesRelatedPosts";


function ResourcesPost({ }) {

    const { slug } = useParams();

    const [post, setPostData] = useState(null);

    useEffect(() => {
        //console.log(  { slug } );
        const query = `*[_type == "resourcePost" && slug.current == "${slug}"]{
                        ...,
                        author-> 
                        }`;

        sanityClient.fetch(query).then((data) => {
           
            setPostData(data[0]);

        });
    }, []);


    const navigate = useNavigate();

    const onGotoCategory = (category) => {
        category = category.toLowerCase();
        navigate(`/resources/${category}`);
    };


    if (!post) {
        return <div>Loading...</div>; // Render a loading message while the data is being fetched
    }
    const wordsPerMinute = 200; // Average reading speed
    const numberOfWords = post.body
        .map(block =>
            block.children
                .map(child => child.text)
                .join(' ')
        )
        .join(' ')
        .split(' ')
        .length;
    const readingTime = Math.ceil(numberOfWords / wordsPerMinute);

   
    return (

        <>

            <Header />
            <div className='resources-post-content md:px-4 mt-12 mb-12 items-center'>
                <button className="bg-black text-white rounded-lg p-2 mb-12" onClick={() => onGotoCategory(post.category)}>
                    Back to {post.category}
                </button>
                {/* Display the content of the Sanity document */}
                <h1 className="text-3xl">{post.title}</h1>
                <div className="flex flex-row">
                    <p className="flex-1">By {post.author.name}</p>
                    <p className="flex-1 text-gray-500"> {new Date(post._createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                    <p className="flex-1 text-gray-500">Reading Time: {readingTime} minutes</p>
                </div>
                <hr className="my-4" />

                <div> <BlockContent blocks={post.body} /></div>
                <hr className="mt-8"/>

                <div className="flex flex-col mt-12">
                    <div>
                        <h1>Related guides and blog articles</h1>
                    </div>
                    <div >
                        
                    <SanityResourcesRelatedPosts category={post.category} currentSlug={slug} />
                    </div>
                   
                </div>

            </div>


            <Footer />
        </>
    );


}

export default ResourcesPost;