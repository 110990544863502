import React from "react";
import { useNavigate } from "react-router-dom";
import "./AnyProperty.css";

import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

const AnypropertyIt = () => {
  const navigate = useNavigate();
  const plan = () => {
    navigate("/plan");
  };

  const onSetup = () => {
    navigate("/set-property");
  };

  return (
    <>
      <Header />
      <div className="container">

        <div className="anyproperty-first-container">
          <div className="first-sentence">Verify your property &</div>
          <div className="second-sentence"> start receiving offers</div>
          <div className="third-sentence">
            30 days free trial. No strings attached.
          </div>
          <button className="start-btn" onClick={plan}>
            Start today
          </button>
          <div className="fourth-sentence" >
            <button className="start-btn" onClick={onSetup}>
              Setup Property
            </button>

          </div>
        </div>
        <div className="second-container">
          <section className="anyproperty-steps-container">
            <div className="anyproperty-steps-container-box">
              <div className="steps-container-inner">
                <div className="step">Step 1</div>
                <div className="search">Verify it</div>
                <div className="steps-container-bottom">
                  Verifying your home allows us to connect you with interested
                  buyers who’ve made an offer on your property.
                </div>
              </div>
            </div>
            <div className="steps-container-image"></div>
          </section>
          <section className="anyproperty-steps-container">
            <div className="anyproperty-steps-container-box">
              <div className="steps-container-inner">
                <div className="step">Step 2</div>
                <div className="search">Market it</div>
                <div className="steps-container-bottom">
                  With our advanced technology, you can use simple software to get more attention and better offers for your home. 
                  <br/><br/>
                  Opt-in to access proven methods like 3D virtual tours, virtual staging, and lighting features that make your home stand out.
                </div>
              </div>
            </div>
            <div className="steps-second-container-image"></div>
          </section>
          <section className="anyproperty-steps-container">
            <div className="anyproperty-steps-container-box">
              <div className="steps-container-inner">
                <div className="step">Step 3</div>
                <div className="search">Sell it</div>
                <div className="steps-container-bottom">
                  Our all in one selling process allows you to securly accept or
                  decline offers, negotiate prices, set-up agreements, and more!
                  <br />
                  <br />
                  We provide you with all the necessary resources you'll need to
                  successfully sell a home.
                </div>
              </div>
            </div>
            <div className="steps-third-container-image"></div>
          </section>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default AnypropertyIt;
