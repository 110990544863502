// reducers/verificationReducer.js

const initialState = {
  country: null,
  frontImageID: null,
  backImageID: null,
  selfieImageID: null,
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COUNTRY":
      return { ...state, country: action.payload };
    case "SET_FRONT_IMAGE_ID":
      return { ...state, frontImageID: action.payload };
    case "SET_BACK_IMAGE_ID":
      return { ...state, backImageID: action.payload };
      case "SET_SELFIE_IMAGE":
        return { ...state, selfieImageID: action.payload, error: null };
      case "SET_UPLOADING":
        return { ...state, isUploading: action.payload };
      case "SET_SELFIE_ERROR":
        return { ...state, error: action.payload };
    case "CLEAR_VERIFICATION_DATA":
      return { ...initialState }; // Resetting the state
    default:
      return state;
  }
};

export default verificationReducer;
