import React from 'react';
import './HowItworksBuy.css'; // Import your CSS file for styling
import { Grid } from '@mui/material';

function HowItWorksBuy()  {

  const cardData = [
    {
      heading: 'Transparent Prices',
      logoSrc: '/images/transparent_prices.svg',
      para: 'Leveraging real-time data, you can see up to date market rates and prices of any home you’re interested in buying.',
    },
    {
      heading: 'Convenience',
      logoSrc: '/images/Convenience.svg',
      para: 'No travel, no hassle, no cost. View any property you’re interested in buying from anywhere in the country.',
      para2:"You can now request 3D virtual tours, 365 degree drone footage, virtual communication, and more, all from the comfort of your own device.",
    },
    {
      heading: 'Speed',
      logoSrc: '/images/Speed.svg',
      para: 'The traditional process of buying a home is slow and stodgy. ',
      para2:"With our all in one process, we have documentation and all other resources ready to go for you, ensuring the transaction is efficient."
    },
  ];

  return (
    <div className="how-it-works-buy-card-container">
    <Grid container  justify="center">
                       
      {cardData.map((card, index) => (
       <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="how-it-works-buy-card" key={index}>
         
          <h2 className="how-it-works-buy-card-heading">{card.heading}</h2>
          <img src={card.logoSrc} alt="Logo" className="how-it-works-buy-card-logo" />
          <p className="how-it-works-buy-card-para">{card.para}</p>
          <p className="how-it-works-buy-card-para">{card.para2}</p>
        </div>
        </Grid>
      ))}
      </Grid>
    </div>

  );
};

export default HowItWorksBuy;
