import React, { useState, useEffect } from "react";
import "./Skeleton.css";
const Skeleton = () => {
  return (
    <div className="skeleton-card">
      <div className="skeleton-property-card-image"></div>
      <div className="skeleton-property-card-data"></div>
    </div>
  );
};

const ImageSkeleton = () => {
  return <div className="skeleton-image lazy-image"></div>;
};
export { Skeleton, ImageSkeleton };
