import BlockContent from "@sanity/block-content-to-react";
import ContentLoader from "react-content-loader";
const SanityContent = ({ postData }) => {
  if (!postData)
    return (
      <ContentLoader className="center" width={700}>
        <rect x="30" y="20" rx="0" ry="0" width="230" height="23" />
        <rect x="30" y="60" rx="0" ry="0" width="700" height="120" />
        <rect x="30" y="189" rx="0" ry="0" width="700" height="15" />
        <rect x="30" y="211" rx="0" ry="0" width="700" height="15" />
        <rect x="30" y="261" rx="0" ry="0" width="700" height="15" />
      </ContentLoader>
    );
  return (
    <article className="sanity-content">
      <h1>{postData.title}</h1>
      <BlockContent
        blocks={postData.body}
        projectId="urrl0ozg"
        dataset="production"
      />
    </article>
  );
};

export default SanityContent;
