import axios from "axios";

const ES_URL =
  "https://37bf4f683c514e8794c45746c4936616.ap-southeast-2.aws.found.io:443";
const API_KEY = "NUtlanJvOEJWSFI5dVBjdWprM186QTBUTW9DLXRSeXFpTTBES2hUSlBIQQ==";

export const searchProperty = async (q, filters = {}) => {
  let data;

  if (q || Object.keys(filters).length > 0) {
    const must = [];

    if (q) {
      must.push({
        query_string: {
          query: q,
        },
      });
    }

    if (filters.propertyType) {
      must.push({
        match: {
          land_zone: filters.propertyType,
        },
      });
    }

    if ((filters?.priceMin || filters?.priceMax) && filters.priceMax != null) {
      const range = {};
      if (filters?.priceMin) {
        range.gte = parseInt(filters?.priceMin.replace(/[^0-9]/g, ""), 0);
      }
      if (filters?.priceMax) {
        range.lte = parseInt(
          filters?.priceMax.replace(/[^0-9]/g, ""),
          100000000
        );
      }
      must.push({
        range: {
          estimate_upper: range,
        },
      });
    }

    if (filters.bedrooms && filters.bedrooms != null) {
      must.push({
        range: {
          bedrooms: {
            gte: parseInt(filters.bedrooms, 1),
          },
        },
      });
    }

    if (filters.bathrooms && filters.bathrooms != null) {
      must.push({
        range: {
          bathrooms: {
            gte: parseInt(filters.bathrooms, 1),
          },
        },
      });
    }

    data = {
      query: {
        bool: {
          must,
        },
      },
    };
  } else {
    // Use a large value for 'size' to fetch all documents
    data = {
      query: {
        match_all: {},
      },
      size: 1000, // Set this to the maximum number of documents you expect
    };
  }

  try {
    const searchResult = await axios.post(
      `${ES_URL}/property/_search?pretty`,
      data,
      {
        headers: {
          Authorization: `ApiKey ${API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const hits = searchResult.data.hits.hits;
    const result = hits.map((hit) => hit._source);
    return result;
  } catch (error) {
    console.error("Error fetching data from Elasticsearch:", error);
    throw error;
  }
};

export const getMainProperties = async () => {
  const data = {
    // your existing query parameters
    size: 4, // this limits the number of results to 4
  };

  try {
    const searchResult = await axios.post(
      `${ES_URL}/property/_search?pretty`,
      data,
      {
        headers: {
          Authorization: `apikey ${API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const hits = searchResult.data.hits.hits;
    const result = hits.map((hit) => hit._source);
    return result;
  } catch (error) {
    console.error("Error fetching data from Elasticsearch:", error);
    throw error;
  }
};
