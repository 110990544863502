import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./PopupModal.css";
import {
  FormControl,
  Input,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useState } from "react";

export default function PopupModal({
  open,
  setOpen,
  title,
  description,
  subDescription,
  handleClick,
  isLoading,
}) {
  const [amount, setAmount] = useState(0);
  const [btnType, setButton] = useState(1);
  const [selectedDate1, setSelectedDate1] = useState("");
  const [selectedTime1, setSelectedTime1] = useState("");
  const [selectedDate2, setSelectedDate2] = useState("");
  const [selectedTime2, setSelectedTime2] = useState("");
  const [selectedDate3, setSelectedDate3] = useState("");
  const [selectedTime3, setSelectedTime3] = useState("");

  const handleDateChange = (event, slot) => {
    switch (slot) {
      case 1:
        setSelectedDate1(event.target.value);
        break;
      case 2:
        setSelectedDate2(event.target.value);
        break;
      case 3:
        setSelectedDate3(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleTimeChange = (event, slot) => {
    switch (slot) {
      case 1:
        setSelectedTime1(event.target.value);
        break;
      case 2:
        setSelectedTime2(event.target.value);
        break;
      case 3:
        setSelectedTime3(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleClickAmount = (event) => {
    setAmount(event.target.value);
  };
  const handleClose = () => setOpen(false);
  const handleOffer = () => {
    setOpen(false);
  };
  const handleRequest = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box id="popup-modal">
            {subDescription == "Make an offer" ||
            description === "Request a viewing for" ? (
              <Box>
                {" "}
                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h2"
                >
                  {description}
                  <span className="close-icon" onClick={handleClose}>
                    X
                  </span>
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {title}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {title}
                </Typography>

                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h2"
                >
                  {description}
                  <span className="close-icon" onClick={handleClose}>
                    X
                  </span>
                </Typography>
              </Box>
            )}

            {subDescription && (
              <Typography id="transition-modal-subdescription" sx={{ mt: 4 }}>
                {subDescription === "Make an offer" ? (
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <Input
                      type="number"
                      id="standard-adornment-amount"
                      onChange={handleClickAmount}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ fontSize: "30px", color: "#000000" }}
                        >
                          <strong> $</strong>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                ) : (
                  subDescription
                )}
              </Typography>
            )}
            {description === "Request a viewing for" && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {[1, 2, 3].map((slot) => (
                  <Box key={slot} id="date-time-input-div">
                    <TextField
                      id={`date-input-${slot}`}
                      variant="outlined"
                      type="date"
                      value={
                        slot === 1
                          ? selectedDate1
                          : slot === 2
                          ? selectedDate2
                          : selectedDate3
                      }
                      onChange={(event) => handleDateChange(event, slot)}
                      InputProps={{
                        className: "date-time-input",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ mt: 2 }}
                    />
                    <TextField
                      id={`time-input-${slot}`}
                      variant="outlined"
                      type="time"
                      value={
                        slot === 1
                          ? selectedTime1
                          : slot === 2
                          ? selectedTime2
                          : selectedTime3
                      }
                      onChange={(event) => handleTimeChange(event, slot)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        className: "date-time-input",
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {subDescription && (
              <Button
                id="transition-modal-btn"
                sx={{ mt: 5 }}
                variant="contained"
                onClick={() =>
                  handleClick({
                    dates: [
                      { date: selectedDate1 },
                      { date: selectedDate2 },
                      { date: selectedDate3 },
                    ],
                    amount,
                  })
                }
              >
                {isLoading
                  ? "Please wait..."
                  : subDescription === "Make an offer"
                  ? "Offer"
                  : description === "Request a viewing for"
                  ? "Request"
                  : "Close"}
              </Button>
            )}
            {!subDescription && (
              <div className="tranisition-modal-btn-group">
                <Button
                  id="transition-modal-btn"
                  sx={{ mt: 5 }}
                  variant="contained"
                  onClick={() => {
                    setButton(1);
                    handleClick(true);
                  }}
                >
                  {isLoading && btnType === 1 ? "Accepting..." : "Accept"}
                </Button>
                <Button
                  id="transition-modal-btn"
                  sx={{ mt: 5 }}
                  variant="contained"
                  onClick={() => {
                    setButton(2);
                    handleClick(false);
                  }}
                >
                  {isLoading && btnType === 2 ? "Declining..." : "Decline"}
                </Button>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
