import React, { useState, useEffect } from 'react';
import apiServiceUploadFiles from '../../services/apiServiceUploadFiles';

const ImageUploaderForProperty = ({ property, updateProperty, refreshImages }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [localImages, setLocalImages] = useState([]);

  useEffect(() => {
    if (property && property.images) {
      setLocalImages(property.images);
    }
  }, [property]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    if (!property || !property.id) {
      setMessage('Property information is not available. Please try again later.');
      return;
    }

    setIsUploading(true);
    setMessage('');

    for (let file of files) {
      try {
        console.log("Uploading file:", file.name);
        const response = await apiServiceUploadFiles.uploadPropertyImage(property.id, file);

        console.log("Response received:", response);

        if (response && response.success && response.image_url) {
          updateProperty("images", prevImages => ({
            ...prevImages,
            images: [...(Array.isArray(prevImages?.images) ? prevImages.images : []), response.image_url]
          }));
          setLocalImages(prevImages => [...prevImages, response.image_url]);
          setMessage(prevMessage => prevMessage + `Image ${file.name} uploaded successfully. `);
        } else {
          throw new Error("Invalid response: " + JSON.stringify(response));
        }
      } catch (error) {
        console.error('Upload error:', error);
        setMessage(prevMessage => prevMessage + `Error uploading ${file.name}: ${error.message}. `);
      }
    }

    setIsUploading(false);
    if (refreshImages) {
      refreshImages();
    }
  };

  return (
    <div className="image-uploader-for-property w-full">
      <div className="mb-4">
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          multiple
          disabled={isUploading}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      {isUploading && <p className="text-blue-500 mb-2">Uploading...</p>}
      {message && <p className="text-green-500 mb-2">{message}</p>}
      <div className="uploaded-images grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {localImages.map((image, index) => (
          <div key={index} className="relative group">
            <img
              src={image.url}
              alt={`Property Image ${index + 1}`}
              className="w-full h-24 object-cover rounded"
            />
            <button
              onClick={() => handleRemoveImage(image.id)}
              className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploaderForProperty;