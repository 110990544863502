// Cards
export const SET_CARDS = "SET_CARDS";
export const ADD_CARD = "ADD_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const SET_DEFAULT_CARD = "SET_DEFAULT_CARD";

// Transactions
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";

export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";

// Loading and errors
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
