import './SettingsCard.css'
const SettingsCard = ({heading,para,icon }) => {
    return (
     <div className="settings-card">
            <div className='settings-card-heading'>{heading}</div>
            <div className='settings-card-para'>{para}</div>
            {icon === "/images/recently_viewed.svg" ? <>
            <div className='settings-card-icon_two'>
            <img src={icon} alt=''/>
            </div>
            </> : <>
            <div className='settings-card-icon'>
            <img src={icon} alt=''/>
            </div>
            </>}
           
              
     </div>
    );
  };
  export default SettingsCard;
  