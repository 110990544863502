import * as actionTypes from "../actionTypes/paymentActionTypes";

const initialState = {
  cards: [],
  transactions: [],
  isLoading: false,
  error: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };

    case actionTypes.SET_ERROR:
      return { ...state, error: action.payload };

    case actionTypes.SET_CARDS:
      return { ...state, cards: action.payload };

    case actionTypes.ADD_CARD:
      return { ...state, cards: [...state.cards, action.payload] };

    case actionTypes.UPDATE_CARD:
      return {
        ...state,
        cards: state.cards.map((card) =>
          card.id === action.payload.id ? action.payload : card
        ),
      };

    case actionTypes.DELETE_CARD:
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== action.payload),
      };

    case actionTypes.SET_DEFAULT_CARD:
    // Logic to set the default card

    case actionTypes.SET_TRANSACTIONS:
      return { ...state, transactions: action.payload };

    // Handle other actions

    default:
      return state;
  }
};

export default paymentReducer;
