
import React from "react";
import { useNavigate } from 'react-router-dom';

const SanityResourcePostCard = ({ resource, index }) => {

    const navigate = useNavigate();
    
    const onGotoResourcesPost = (slug) => {
        navigate(`/resources/${slug}`);
    };
    return (
        <div key={index} onClick={() => onGotoResourcesPost(resource.slug.current)}>
            <div className="flex-auto rounded bg-gray-300 min-h-[200px] max-w-full">
                <h3 className="text-xl sm:text-2xl font-semibold p-4">{resource.title}</h3>
            </div>
        </div>
    );
};

export default SanityResourcePostCard;