import React, { useState } from "react";
import "./LoginSecurity.css";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import { Link } from "react-router-dom";
import { Box, Grid, Table, Tabs } from "@mui/material";
import apiService from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import AccountInfoCard from "../../components/account/AccountInfoCard";
import Tab from "@mui/material/Tab";

const breakText = () => {
  return (
    <>
      {"We regularly review accounts to make sure they’re secure as possible."}
      <br />
      <br />
      {
        "We’ll also let you know if there’s more we can do to increase the security of your account."
      }
    </>
  );
};

function LoginSecurity() {
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [openAlert, setAlertOpen] = useState(false);
  const [value, setValue] = React.useState("one");
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const resetPassword = async () => {
    const data = {
      user: {
        email: currentUser.email,
        redirect_url: "",
      },
    };
    const response = await apiService.post("users/password", data);
    setMessage({
      type: "success",
      message: "We have sent you a email for password reset.",
    });
    setAlertOpen(true);
  };

  return (
    <>
      <div>
        <Header />
        <div className="login-security-container">
          <div>
            <Link to="/account-settings" className="custom-link">
              Account Settings &nbsp;&nbsp;
            </Link>

            {`>`}

            <Link to="" className="custom-link">
              &nbsp;&nbsp;Login & Security
            </Link>
          </div>
          <div className="login-security-heading">Login & Security</div>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <div className="login-table-box">
                  <div className="login-security-tabs-box">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      >
                        <Tab value="one" label="Login" />
                      </Tabs>
                    </Box>
                  </div>
                  {value === "one" ? (
                    <>
                      <Table className="login-security_table">
                        <tr className="login-security-table-row">
                          <td>
                            <p className="login-security_filed">
                              Password
                              <br />
                              <span className="login-security_text">
                                {" "}
                                *****************{" "}
                              </span>
                            </p>
                          </td>
                          <td>
                            <p
                              onClick={resetPassword}
                              className="login-security_edit"
                            >
                              Reset
                            </p>
                          </td>
                        </tr>
                        {/*
                           <tr className="login-security-table-row">
                            <td>
                              <p className="login-security_filed">
                                Account
                                <br />
                                <span className="login-security_text">
                                  {" "}
                                  Deactivate account{" "}
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="login-security_deactivate">
                                Deactivate
                              </p>
                            </td>
                          </tr>
                          */}
                      </Table>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountInfoCard
                  icon={"/images/loging_security.svg"}
                  heading={"Keeping your account secure"}
                  para={breakText()}
                  link={""}
                  linkText={"Learn more"}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer />
        <AlertBox
          open={openAlert}
          type={message.type}
          onClose={handleClose}
          message={message.message}
        ></AlertBox>
      </div>
    </>
  );
}

export default LoginSecurity;
