import React, { useState, useEffect, useCallback } from "react";
import { BiSearch } from "react-icons/bi";
import { deleteUser } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropertySearch from "../property/PropertySearch";
import "../../pages/auth/Login.css";
import imagen from "../../assets/newAPlogoDark.png";
import MetaPixel from "utils/meta/metaPixel";


import apiService from "../../services/apiService";

const textTittle = {};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  p: 4,
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);

  const userName =
    currentUser && currentUser?.first_name
      ? `${currentUser?.first_name} ${currentUser?.last_name}`
      : currentUser?.email;

  const logoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleLogout = () => {
    Cookies.remove("token", { path: "/" });
    localStorage.removeItem("userData");
    dispatch(deleteUser());
    setTimeout(() => {
      navigate("/login");
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="px-6 py-4 lg:px-10 ">
      <MetaPixel />
      <div className="flex flex-row left-header" onClick={logoClick}>
        {/* <img src="/images/logo_2x.png" alt="Logo" className="logo" /> */}
        <img src={imagen} alt="Logo" className="logo" />
        <div className="text-logo">AnyProperty</div>
      </div>
      <div className="search-box">
        <PropertySearch />
        <div className="search-icon">
          <BiSearch />
        </div>
      </div>
      <div className="right-header">
        <div className="top-menu">
          <ul>
            <li>
              <Link to="/anypropertyit">AnyProperty It</Link>
            </li>
            <li>
              <Link to="/browse">Browse Property</Link>
            </li>
          </ul>
        </div>
        <div className="dropdown-menu">
          <img
            className="menu dropbtn"
            src="/images/dropdown-1@2x.png"
            alt="Menu"
          />
          <div className="dropdown-content w-52 right-0">
            {currentUser?.id ? (
              <>
                <Link to="/watchList">Hi {userName}!</Link>
                <Link to="/account-settings">Account Settings</Link>
                <Link to="/how-it-works">How It Works</Link>
                {isMobile && (
                  <>
                    <Link className="any-property" to="/anypropertyit">
                      AnyProperty It
                    </Link>
                    <Link to="/browse">Browse Property</Link>
                  </>
                )}
                <Link to="#" onClick={handleLogout}>
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link to="/how-it-works">How It Works</Link>
                <Link to="/login">Login / SignUp</Link>
                {isMobile && (
                  <>
                    <Link className="any-property" to="/anypropertyit">
                      AnyProperty It
                    </Link>
                    <Link to="/browse">Browse Property</Link>
                  </>
                )}
                {/* <Link to="/sign-up">SignUp</Link> */}
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
