import React from "react";
import { Grid } from "@mui/material";
import * as changeCase from "change-case";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import "./SetPropertySpecifics.css";

function SetPropertySpecifics() {
  const { property, updateProperty } = useProperty();

  const handleChange = (label, event) => {
    const newValue = event.target.value;
    updateProperty("specifics", {
      ...(property.specifics || {}),
      [label]: newValue,
    });
  };

  const specificsDetails = [
    {
      label: "decade_constructed",
      value: property?.specifics?.decade_constructed || "",
    },
    { label: "floor_area", value: property?.specifics?.floor_area || "" },
    {
      label: "wall_construction",
      value: property?.specifics?.wall_construction || "",
    },
    {
      label: "roof_construction",
      value: property?.specifics?.roof_construction || "",
    },
  ];

  if (!property || !property.specifics) {
    return <div>Loading property specifics...</div>;
  }

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Tell us about the specifics
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={5} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-specifics-container">
            {specificsDetails.map((detail) => (
              <div key={detail.label} className="property-card-specifics">
                <p>{changeCase.capitalCase(detail.label)}</p>
                <input
                  type="text"
                  value={detail.value}
                  className="property-card-specifics-input"
                  onChange={(e) => handleChange(detail.label, e)}
                />
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertySpecifics;