
import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "./Resources.css";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SanityResourcePostCard from "../../components/sanity/SanityResourcePostCard";


function ResourcesCategory({ category }) {
    const navigate = useNavigate();

    const [resourcesData, setResourcesData] = useState([]);
    useEffect(() => {

        const query = `*[_type == "resourcePost" && category == "${category}"]`;
        sanityClient.fetch(query).then((data) => {
            setResourcesData(data);
        });
    }, []);

    const onGotoResourcesPost = (slug) => {
        navigate(`/resources/${slug}`);
    };



    return (
        <>
            <Header />


            <div className="bg-white ">
                {/* Resources Header */}

                <div className="w-full mt-10">
                    <div
                        className="relative bg-no-repeat rounded-3xl mx-auto "
                        style={{
                            backgroundImage: `url('/images/resources-${category === 'Buying' ? 'img1' : 'img2'}.jpg')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '95vw',
                            height: '600px',
                            maxWidth: '1000px',

                        }}
                    >
                        <p className="mt-5 absolute top-5 left-5 text-3xl white p-2">{category === 'Buying' ? 'Home Buying' : 'Home Selling'} </p>
                    </div>
                </div>

                {/* Learning More Section */}
                <div className="flex flex-col justify-between p-8 mx-auto max-w-6xl">



                    {/* Featured Section */}
                    <div className="p-8">
                        <div className="flex-1">
                            <h3 className="text-3xl font-semibold mb-12">Latest</h3>
                        </div>
                        <div className="flex flex-wrap gap-4 ">


                            {resourcesData.map((resource, index) => (
                                <div className="w-full md:w-[45%] ">

                                    <SanityResourcePostCard resource={resource} index={index} />
                                </div>
                            ))}

                        </div>
                    </div>
                </div>




            </div>
            <Footer />
        </>
    );
}

export default ResourcesCategory;