const initialState = {
  currentUser: null,
  myProperties: null,
  viewingsAndOffers: {
    viewings: [],
    offers: [],
    sellerViewings: [],
    sellerOffers: [],
    virtualTours: [],
  },
  isLoadingUser: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, currentUser: action.payload };
    case "SET_LOADING_USER":
      return { ...state, isLoadingUser: action.payload };
    case "SET_MY_PROPERTIES":
      return { ...state, myProperties: action.payload };
    case "SET_VIEW_AND_OFFERS":
      return { ...state, viewingsAndOffers: action.payload };
    case "DELETE_USER": // Handling user deletion (logout)
      return { ...state, currentUser: null };
    default:
      return state;
  }
}

export default userReducer;
