import React, { useCallback, useEffect, useState } from "react";
import "./SetUpPropertyHeader.css";
import { useNavigate } from "react-router-dom";

const SetupPropertHeader = () => {
  const navigate = useNavigate();
  const logoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const handleExit = () => {
    navigate("/browse");
  };

  return (
    <div className="setup-header">
      <div className="btn-container">
        <button onClick={handleExit}>Exit</button>
      </div>
      <div className="left-header logo-section" onClick={logoClick}>
        <img src="/images/logo_2x.png" alt="Logo" className="logo" />
        <div className="text-logo">AnyProperty</div>
      </div>
      <div className="btn-container">
        <button className="need-help">
          <a href="mailto:admin@anyproperty.co.nz">Need help?</a>
        </button>
      </div>
    </div>
  );
};

export default SetupPropertHeader;
