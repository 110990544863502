import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "../auth/SignUp.css";
import { useSelector, useDispatch } from "react-redux";
import { setSelfieImage } from "../../redux/actions/verificationActions";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import AlertBox from "../../components/common/AlertBox";
import ImageUploaderForUser from "../../components/common/ImageUploaderForUser";
import Footer from "../../components/common/Footer";
import HelpLink from "../../components/common/HelpLink";

export default function VerifySelfie() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const { selfieImageID, isUploading, error } = useSelector((state) => state.verification);
  const user = useSelector((state) => state.user.currentUser);

  const handleUploadSuccess = useCallback((imageData) => {
    if (user && user.slug) {
      dispatch(setSelfieImage(user.slug, imageData.imageId));
      setMessage({
        type: "success",
        message: "Selfie uploaded successfully!",
      });
      setAlertOpen(true);
    } else {
      setMessage({
        type: "error",
        message: "User information is not available. Please try again later.",
      });
      setAlertOpen(true);
    }
  }, [dispatch, user, setMessage, setAlertOpen]);

  const onNextClick = ({ skip = false }) => {
    if (!skip && !selfieImageID) {
      setMessage({
        type: "error",
        message: "Please upload selfie image",
      });
      setAlertOpen(true);
      return;
    }
    navigate("/verified");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Verification</div>
        <div className="step-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step active">
            <div className="step-number ">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5 verification">
        {" "}
        <div className="confirmation">
          <button
            className="button"
            onClick={() => onNextClick({ skip: true })}
          >
            Skip for now
          </button>
        </div>
        <div className="row justify-content-center confirmation">
          <div className="col-md-4 signup confirmation bg-gray text-center shadow p-5">
            <h3 className="text-center">Identity verification</h3>

            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={75} />
            </Box>

            <div className="cards-container">
              <div className="cards-container-inner">
                <div>
                  {user && user.slug ? (
                    <ImageUploaderForUser
                      applyClass={true}
                      setUploadedImageIds={handleUploadSuccess}
                    />
                  ) : (
                    <p>User information is not available. Please try again later.</p>
                  )}
                  <p>Upload a selfie photo</p>
                </div>
              </div>
            </div>
            <div className="m-b-50">
              <span>
                File size must be between 10KB and 5120KB in jpg/jpeg/png
                format.
              </span>
            </div>
            <div className="m-b-50">
              <button onClick={onNextClick} className="button" disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Continue'}
              </button>
            </div>
            <div className="m-b-50">
              <NavLink className="back" to="/verify-identify">
                <b className="mt-3">Back</b>
              </NavLink>
            </div>
            <div>
              <HelpLink />
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </div>
  );
}
