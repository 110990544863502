import axios from "axios";
import { getCookie } from "../utils";
const baseURL = process.env.REACT_APP_API_URL;
//const baseURL = "/api";
const api = axios.create({
  baseURL,
});


const apiRequest = async (method, url, data = {}, headers = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCookie("token") }`,
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    throw (
      error?.response?.data?.errors ||
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message
    );
  }
};

const apiServiceUploadFiles = {
  uploadIdentityFront: (slug, identityFront) => {
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("identity_front", identityFront);
    return apiRequest("post", `/users/${slug}/upload_identity_front`, formData);
  },
  uploadIdentityBack: (slug, identityBack) => {
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("identity_back", identityBack);
    return apiRequest("post", `/users/${slug}/upload_identity_back`, formData);
  },

  uploadSelfie: (slug, selfie) => {
    const formData = new FormData();
    formData.append("selfie", selfie);
    return apiRequest("post", `/users/${slug}/upload_selfie`, formData);
  },

  uploadBothIdentityDocuments: (slug, identityFront, identityBack) => {
    const formData = new FormData();
    formData.append("identity_front", identityFront);
    formData.append("identity_back", identityBack);
    return apiRequest("post", `/users/${slug}/upload_identity`, formData);
  },

  uploadPropertyImage: async (id, image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      const response = await apiRequest("post", `/properties/${id}/upload_image`, formData);
      
      console.log("Server response:", response);
      
      if (response && response.success && response.image_url) {
        return {
          success: response.success,
          message: response.message,
          image_url: response.image_url // Changed from image_urls[0] to image_url
        };
      } else {
        console.log("Invalid response:", response);
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error in uploadPropertyImage:", error);
      throw error;
    }
  },

  uploadPropertyVirtualImage: async (id, image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("image_type", "virtual_images");
      const response = await apiRequest("post", `/properties/${id}/upload_virtual_image`, formData);
      
      if (response && response.image_url) {
        console.log("Virtual image uploaded successfully:", response);
        return response;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error in uploadPropertyVirtualImage:", error);
      throw error;
    }
  },
};

export default apiServiceUploadFiles;