import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { BiSearch } from "react-icons/bi";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import mapIcon from "../../assets/pin.png";
import "./SetPropertyPlace.css";

const SetPropertyPlace = () => {
  const { property, updateProperty } = useProperty();

  const defaultCenter = { lat: -43.528763, lng: 172.657002 };
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [containerWidth] = useState("100%");
  const [containerHeight] = useState("450px");
  const [containerRadius] = useState("20px");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  useEffect(() => {
    if (property?.place?.coordinates) {
      setMarkerPosition(property.place.coordinates);
    }
    if (property?.place?.address) {
      setAddress(property.place.address);
    }
  }, [property?.place]);

  const handleSearch = useCallback(async () => {
    if (address.length < 5) return; // Only search if address is long enough
  
    // Check local storage first
    const cachedResult = localStorage.getItem(address);
    if (cachedResult) {
      const { lat, lng } = JSON.parse(cachedResult);
      setMarkerPosition({ lat, lng });
      updateProperty("place", { address, coordinates: { lat, lng } });
      return;
    }
  
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_MAP_KEY}`);
      const data = await response.json();
      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        setMarkerPosition({ lat, lng });
        updateProperty("place", { address, coordinates: { lat, lng } });
        
        // Cache the result
        localStorage.setItem(address, JSON.stringify({ lat, lng }));
      } else {
        console.error("Geocoding failed:", data.status);
      }
    } catch (error) {
      console.error("Error during geocoding:", error);
    }
  }, [address, updateProperty]);

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const timeout = setTimeout(() => {
      handleSearch();
    }, 1000); // Increased to 1 second to reduce API calls

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [address, handleSearch]);

  const handleAddressChange = (event) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    updateProperty("place", { ...property.place, address: newAddress });
  };

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box flex flex-row">
        <div className="mr-8">
          <p className="set-properties-rooms-heading">
            Where's your place located?
          </p>
          <p className="set-properties-rooms-para">
            Input your address through search, manually, or precise location.
          </p>
        </div>
        <div>
          <p className="set-properties-rooms-para">
            Address: {property?.place?.address || ""}
          </p>
          <p className="set-properties-rooms-para">
            Pin: {property?.place?.coordinates?.lat || ""}, {property?.place?.coordinates?.lng || ""}
          </p>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={1} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="set-properties-map-image">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
              <GoogleMap
                mapContainerStyle={{
                  width: containerWidth,
                  height: containerHeight,
                  borderRadius: containerRadius,
                }}
                options={mapOptions}
                center={markerPosition}
                zoom={14}
              >
                <Marker
                  position={markerPosition}
                  icon={{
                    url: mapIcon,
                    size: window.google?.maps?.Size
                      ? new window.google.maps.Size(60, 60)
                      : undefined,
                    anchor: window.google?.maps?.Point
                      ? new window.google.maps.Point(20, 60)
                      : undefined,
                    scaledSize: window.google?.maps?.Size
                      ? new window.google.maps.Size(60, 60)
                      : undefined,
                  }}
                />
              </GoogleMap>
            </LoadScript>
            <div className="search-box-onmap">
              <input
                type="text"
                placeholder="Search..."
                value={address}
                onChange={handleAddressChange}
              />
              <div className="search-icon" onClick={handleSearch}>
                <BiSearch />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SetPropertyPlace;