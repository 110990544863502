import React, { useRef, useState, useEffect } from "react";

import "./ViewOffer.css";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import MyPropertyCard from "../../components/account/MyPropertyCard";
import { Link, useSearchParams } from "react-router-dom";

const ViewOffer = () => {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("property_id");

  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    // Assuming you have the JSON data of properties stored in public/properties.json
    const fetchData = async () => {
      const property = propertiesData.find(
        (item) => item.id.toString() === propertyId
      );
      setPropertyData(property);
    };

    if (propertyId) {
      fetchData();
    }
  }, [propertyId]);
  if (!propertyData) {
    return <div>Loading..</div>;
  }

  return (
    <div className="offer-view">
      <Header />
      <div className="my-properties-subheader-nav">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="/account-settings/my-properties" className="custom-link">
            &nbsp; &nbsp;My Properties &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="" className="custom-link">
            &nbsp; &nbsp;View Offers
          </Link>
        </div>
        <div className="payments-transactions-heading">View Offers</div>
      </div>
      <MyPropertyCard
        location="locationA"
        marker={propertyData}
        page="isViewOffer"
      />
      <Footer />
    </div>
  );
};

export default ViewOffer;
