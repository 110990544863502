import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import apiServiceUploadFiles from '../../services/apiServiceUploadFiles';

const ImageUploaderForUser = ({ setUploadedImageIds, applyClass }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const user = useSelector((state) => state.user.currentUser);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!user || !user.slug) {
      setMessage('User information is not available. Please try again later.');
      return;
    }

    setIsUploading(true);
    setMessage('');

    try {
      const response = await apiServiceUploadFiles.uploadSelfie(user.slug, file);
      
      if (response.status === "success") {
        const imageData = {
          imageId: response.image_id || user.slug,
          url: response.image_url,
          message: response.message,
        };
        setUploadedImageIds(imageData);
        setUploadedImage(response.image_url);
        setMessage('Selfie uploaded successfully!');
      } else {
        throw new Error(response.message || "Upload failed");
      }
    } catch (error) {
      console.error('Upload error:', error);
      setMessage('Error uploading selfie. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className={applyClass ? "custom-dashboard" : "dashboard"}>
      {!uploadedImage ? (
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          disabled={isUploading || !user || !user.slug}
        />
      ) : (
        <div>
          <img src={uploadedImage} alt="Uploaded selfie" style={{maxWidth: '200px'}} />
          <button onClick={() => setUploadedImage(null)}>Upload new image</button>
        </div>
      )}
      {isUploading && <p>Uploading...</p>}
      {message && <p>{message}</p>}
    </div>
  );
};

export default ImageUploaderForUser;