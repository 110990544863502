import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import "./PopupModalMobile.css";
import {
  FormControl,
  Input,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from 'react-redux';

export default function PopupModalMobile({
  open,
  setOpen,
  title,
  description,
  subDescription,
  handleClick,
  isLoading,
  propertyData,
  slug,
}) {

  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState("");
  const [selectedTime1, setSelectedTime1] = useState("");
  const [selectedDate2, setSelectedDate2] = useState("");
  const [selectedTime2, setSelectedTime2] = useState("");
  const [selectedDate3, setSelectedDate3] = useState("");
  const [selectedTime3, setSelectedTime3] = useState("");

  const handleDateChange = (event, slot) => {
    switch (slot) {
      case 1:
        setSelectedDate1(event.target.value);
        break;
      case 2:
        setSelectedDate2(event.target.value);
        break;
      case 3:
        setSelectedDate3(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleTimeChange = (event, slot) => {
    switch (slot) {
      case 1:
        setSelectedTime1(event.target.value);
        break;
      case 2:
        setSelectedTime2(event.target.value);
        break;
      case 3:
        setSelectedTime3(event.target.value);
        break;
      default:
        break;
    }
  };
  const navigate = useNavigate();

  const claimProperty = async () => {
    navigate(`/set-property?slug=${slug}`);
  };

  const handleClose = () => setOpen(false);
  const handleOffer = () => {
    setOpen(false);
  };
  const handleRequest = () => {
    setOpen(false);
  };

  const viewVirtualTour = async () => {
    const url = `/users/${currentUser.slug}/viewings`;
    let newViewing = {
      user_id: currentUser.id,
      property_id: propertyData.id,
    };
    try {
      setLoading(true);
      const responseView = await apiService.postWithToken(url, newViewing);

      const responseOffer = await apiService.getWithToken(
        `/viewings/${responseView.id}/virtual_tour`
      );
      console.log("Offer request successful", responseOffer);
      setMessage({
        type: "success",
        message: "Offer request successful",
      });
      setAlertOpen(true);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      setLoading(false);
      setMessage({
        type: "error",
        message:
          "Virtual tour request failed, there is not virtual tour created for this property",
      });
      setAlertOpen(true);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box id="popup-modal-mobile">
            {subDescription == "Make an offer" ||
              description === "Request a viewing for" ? (
              <Box>
                {" "}
                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h2"
                >
                  {description}
                  <span className="close-icon" onClick={handleClose}>
                    X
                  </span>
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {title}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {title}
                </Typography>

                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h2"
                >
                  {description}
                  <span className="close-icon" onClick={handleClose}>
                    X
                  </span>
                </Typography>
              </Box>
            )}

            {subDescription && (
              <Typography id="transition-modal-subdescription" sx={{ mt: 4 }}>
                {subDescription === "Make an offer" ? (
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <Input
                      type="number"
                      id="standard-adornment-amount"
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ fontSize: "30px", color: "#000000" }}
                        >
                          <strong> $</strong>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                ) : (
                  subDescription
                )}
              </Typography>
            )}
            

            
              <Button
                id="transition-modal-btn"
                sx={{ mt: 5 }}
                variant="contained"
                onClick={handleClick}
              >
                {isLoading
                  ? "Please wait..."
                  : subDescription === "Make an offer"
                    ? "Offer"
                    : description === "Request a viewing for"
                      ? "Request"
                      : "Close"}
              </Button>

              <div className="product-view-details-card-content">
                  <div className="mobile-card-content-flex">
                   
                    <img
                      src="./images/info.jpg"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </div>
                  <div className="mobile-card-content-flex">
                    <button onClick={viewVirtualTour}>View Virtual Tour</button>
                   
                  </div>
                  <button className="block-button" onClick={claimProperty}>
                   Verify Property
                  </button>
                </div>
          
            {!subDescription && (
              <div className="tranisition-modal-btn-group">
                <Button
                  id="transition-modal-btn"
                  sx={{ mt: 5 }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Accept
                </Button>
                <Button
                  id="transition-modal-btn"
                  sx={{ mt: 5 }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Decline
                </Button>
                
              </div>


            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
