import apiService from "../../services/apiService";
const FETCH_WATCHLISTS_REQUEST = "FETCH_WATCHLISTS_REQUEST";
const FETCH_WATCHLISTS_SUCCESS = "FETCH_WATCHLISTS_SUCCESS";
const FETCH_WATCHLISTS_FAILURE = "FETCH_WATCHLISTS_FAILURE";

const fetchWatchlistsRequest = () => ({
  type: FETCH_WATCHLISTS_REQUEST,
});

const fetchWatchlistsSuccess = (watchlists) => ({
  type: FETCH_WATCHLISTS_SUCCESS,
  payload: watchlists,
});

const fetchWatchlistsFailure = (error) => ({
  type: FETCH_WATCHLISTS_FAILURE,
  payload: error,
});

export const fetchWatchlists = (slug) => {
  return async (dispatch) => {
    dispatch(fetchWatchlistsRequest());
    try {
      const properties = await apiService.getWithToken(
        `/users/${slug}/watchlists`
      );
      const uniqueData = Array.from(
        new Map(
          properties.map((item) => [item["property_details.id"], item])
        ).values()
      );
      dispatch(fetchWatchlistsSuccess(uniqueData));
    } catch (error) {
      dispatch(fetchWatchlistsFailure(error.message));
    }
  };
};
