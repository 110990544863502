import React, { useState, useContext } from "react";
import { Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useProperty } from "../../context/PropertyContext"; // Import context
import { useSearchParams, useNavigate } from "react-router-dom";
import AlertBox from "../../components/common/AlertBox";
import apiService from "../../services/apiService";
import "./SetupPropertyFooter.css";

function SetupPropertyFooter({ children }) {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const slug = searchParams.get("slug");
  const [currentPage, setCurrentPage] = useState(0);
  const { property } = useProperty(); // Use property and user data from context
  const navigate = useNavigate(); // Setup navigate for routing
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);

  const nextPage = async () =>  {
    let message = "";
    if (currentPage === 0 && (!property?.place?.address || !property.place.address.trim())) {
      message = "Please enter address and press search icon to search";
    }
    if (
      currentPage === 2 &&
      (!property?.rooms?.Bedrooms || !property?.rooms?.Bathrooms)
    ) {
      message = "Please provide bathrooms and bedrooms information";
    }
    if (currentPage === 4 && !property?.descriptions?.generalDescription) {
      message = "Please provide a description for your property";
    }

    if (currentPage === 5 && !slug) {
      let url = `/properties/create`;

      try {
        const response = await apiService.postWithToken(url, {
          
          first_name: currentUser.first_name,
          middle_name: currentUser.middle_name,
          last_name: currentUser.last_name,
          address: property?.place?.address,
          full_address: property?.place?.address,
          bedrooms: property?.rooms?.Bedrooms,
          bathrooms: property?.rooms?.Bathrooms,
          latitude: property?.place?.coordinates?.lat,
          longtitude: property?.place?.coordinates?.lng,
          drive_licence: currentUser.drive_licence,
          licence_version: currentUser.licence_version,
          dob: currentUser.dob,
          decade_constructed: property?.decade_constructed,
          floor_area: property?.specifics?.floor_area,
          wall_construction: property?.specifics?.wall_construction,
          roof_construction: property?.specifics?.roof_construction,
          description: property?.descriptions?.generalDescription,
          decade_constructed: property?.specifics?.decade_constructed,
          region_id: 2,
        });

        console.log("response", response);
        property.slug = response.slug;
        slug = response.slug;
        setMessage({
          type: "success",
          message: "Your property have been saved successfully",
        });
        setAlertOpen(true);
       
      } catch (error) {
        setMessage({
          type: "error",
          message: "Property cannot be saved",
        });
        setAlertOpen(true);
        console.error("Error during property claim", error);
      } finally {
        setSaving(false);
      }
    }

    if (message != "") {
      setMessage({
        type: "error",
        message,
      });
      setAlertOpen(true);
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const handleSubmit = async () => {
    setSaving(true);
    let url = '';
    if (!slug) {
      url = `/properties/create`;
    }
    else {
      url = `/users/${currentUser.slug}/properties/${slug}/claim_request`;
    }
    try {
      const response = await apiService.postWithToken(url, {
        slug: property.slug,
        first_name: currentUser.first_name,
        middle_name: currentUser.middle_name,
        last_name: currentUser.last_name,
        address: property?.place?.address,
        full_address: property?.place?.address,
        bedrooms: property?.rooms?.Bedrooms,
        bathrooms: property?.rooms?.Bathrooms,
        latitude: property?.place?.coordinates?.lat,
        longtitude: property?.place?.coordinates?.lng,
        drive_licence: currentUser.drive_licence,
        licence_version: currentUser.licence_version,
        dob: currentUser.dob,
        decade_constructed: property?.decade_constructed,
        floor_area: property?.specifics?.floor_area,
        wall_construction: property?.specifics?.wall_construction,
        roof_construction: property?.specifics?.roof_construction,
        description: property?.descriptions?.generalDescription,
        decade_constructed: property?.specifics?.decade_constructed,
        region_id: 2,
      });

      setMessage({
        type: "success",
        message: slug
          ? "Claim request successful"
          : "Your property have been saved successfully",
      });
      setAlertOpen(true);
      setTimeout(() => {
        navigate("/account-settings/my-properties");
      }, 2000);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Property has already been claimed",
      });
      setAlertOpen(true);
      console.error("Error during property claim", error);
    } finally {
      setSaving(false);
    }
  };

  const currentPageComponent = React.Children.toArray(children)[currentPage];

  return (
    <div>
      <div>
        {React.cloneElement(currentPageComponent, {
          children,
        })}
      </div>
      <Divider />
      <div className="setup-property-footer">
        {currentPage > 0 && (
          <div className="setup-footer-btn-container">
            <button onClick={prevPage}>Back</button>
          </div>
        )}
        {currentPage < React.Children.count(children) - 1 && (
          <div className="setup-footer-btn-container setup-footer-active-btn">
            <button onClick={nextPage}>Next</button>{" "}
          </div>
        )}
        {currentPage === React.Children.count(children) - 1 && (
          <div className="setup-footer-btn-container setup-footer-active-btn">
            <button disabled={isSaving} onClick={handleSubmit}>
              {isSaving ? "Saving..." : "Complete"}
            </button>{" "}
          </div>
        )}
      </div>{" "}
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
    </div>
  );
}

export default SetupPropertyFooter;
