import React from "react";
import { Grid } from "@mui/material";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import "./SetPropertyPhotos.css";

function SetPropertyVirtualTour() {
  const { property, updateProperty } = useProperty();
  // Assuming images is a part of the virtualTour object
  const images = property.vtours || Array(6).fill(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const newImages = [...images];
    for (let i = 0; i < Math.min(files.length, 6); i++) {
      const file = files[i];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const index = newImages.findIndex((image) => image === null);
          if (index !== -1) {
            newImages[index] = reader.result;
            updateProperty("virtualTour", {
              ...property.virtualTour,
              images: newImages,
            });
          }
        };
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event) => {
    handleFiles(event.target.files);
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages[index] = null;
    updateProperty("virtualTour", {
      ...property.virtualTour,
      images: newImages,
    });
  };

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Upload a virtual tour for your property
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={9} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-photos-container">
            <div className="drag-drop-container">
              <input
                type="file"
                id="fileInput"
                multiple
                onChange={handleInputChange}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput" className="drag-drop-placeholder">
                <img
                  src="/images/virtual_tour.svg"
                  className="upload_button_img"
                  alt=""
                />
                Drag an image or click here to select a file
              </label>
            </div>
            <div className="empty-boxes">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="proprty-card-image-container empty-box"
                >
                  {image && (
                    <div className="proprty-card-image-container">
                      <img src={image} alt={`Uploaded Image ${index + 1}`} />
                      <button onClick={() => removeImage(index)}>x</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyVirtualTour;
