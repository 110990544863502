import React from "react";
import "./OfferCard.css";
import dayjs from "dayjs";

const OfferCard = ({ property, locationArray, handlePopup }) => {
  return (
    <div className="offer-right">
      {locationArray.map((item) => (
        <div className="offer-card" key={item.id}>
          <div className="offer-card-col">
            <p>Viewing Date</p>
            <h4>{dayjs(item?.viewing_dates[0]?.date).format("MM/DD/YY")}</h4>
          </div>
          {item.status && (
            <div className="offer-card-col">
              <p>Status</p>
              <h4>{item.status}</h4>
            </div>
          )}
          {item.price && (
            <div className="offer-card-col">
              <p>Offer Price</p>
              <h4>{item.price}</h4>
            </div>
          )}

          <div className="offer-card-btn">
            <button onClick={() => handlePopup(item.id)}>Manage</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OfferCard;
