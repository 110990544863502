import React, { useState } from "react";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import ToggleButtons from "../../components/ui/ToggleButtons";
import HowItworksBuy from "./HowItworksBuy";
import HowItWorksSell from "./HowItWorksSell";
import "./HowItWorks.css";

function HowItWorks() {
  const [activeComponent, setActiveComponent] = useState("buy");

  const handleToggle = (selectedComponent) => {
    setActiveComponent(selectedComponent);
  };

  return (
    <>
      <Header />
      <div className="how-it-works-container">
        <div className="how-it-works-header">How It Works</div>
        <div className="how-it-works-imgs-box">
          <div>
            <img
              src="/images/Ellipse-25.svg"
              alt=""
              className="how-it-works-img-1"
            />
          </div>
          <div>
            <img
              src="/images/Ellipse-26.svg"
              alt=""
              className="how-it-works-img-2"
            />
          </div>
          <div>
            <img
              src="/images/Ellipse-27.svg"
              alt=""
              className="how-it-works-img-3"
            />
          </div>
        </div>
        <div className="how-it-works-para">
          AnyProperty simply connects people looking <br />
          to buy a home, with people wanting to sell one.
        </div>
        <div className="how-it-works-main-img-box">
          <div>
            <img
              src="/images/how_it_work_main.svg"
              alt=""
              className="how-it-works-main-img"
            />
          </div>
        </div>
        <div className="how-it-works-sub-para">
          We aim to change the way properties are brought and sold throughout
          the country, and are focused on doing everything possible to make the
          process as cheap & straight forward as possible for everyone.
        </div>
      </div>
      <div>
        <div>
          <div className="how-it-works-last-heading">AnyProperty it.</div>
          <div className="how-it-works-last-para">
            The new way of buy & sell
          </div>
        </div>
        <div>
          <ToggleButtons
            text={activeComponent}
            onSellTextClick={handleToggle}
          />
          {activeComponent === "buy" ? <HowItworksBuy /> : <HowItWorksSell />}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HowItWorks;
