import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProperty, updatePropertyField } from "../../redux/actions/propertyDetailActions";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import ImageUploaderForProperty from "../../components/common/ImageUploaderForProperty";
import VirtualImageUploaderForProperty from "../../components/common/VirtualImageUploaderForProperty";
import { useSearchParams } from "react-router-dom";
import ViewCard from "../../components/property/ViewCard";
import AlertBox from "../../components/common/AlertBox";
import apiService from "../../services/apiService";
import "./ManageProperty.css";
import { Link, useNavigate } from "react-router-dom";

const ManageProperty = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("property_id");
  const slug = searchParams.get("slug");
  const dispatch = useDispatch();
  const {
    property: propertyData,
    isLoading: propertyLoading,
    error,
  } = useSelector((state) => state.property);

  useEffect(() => {
    dispatch(fetchProperty(slug));
  }, [slug, dispatch]);

  useEffect(() => {
    if (propertyData?.images) {
      console.log('Property Images:', propertyData.images);
    }
  }, [propertyData]);

  const [message, setMessage] = useState("");
  const [isSaving, setSaving] = useState(false);
  const [images_blob_id, setUploadedImageIdsList] = useState([]);
  const [openAlert, setAlertOpen] = useState(false);
  const [text, setText] = useState(propertyData?.description || "");
  const [bedrooms, setBedrooms] = useState(propertyData?.bedrooms || "");
  const [bathrooms, setBathrooms] = useState(propertyData?.bathrooms || "");
  const [price, setPrice] = useState(propertyData?.estate_type || "");
  const [wall_construction, setWallConstruction] = useState(
    propertyData?.wall_construction || ""
  );
  const maxLength = 800;

  useEffect(() => {
    setText(propertyData?.description || "");
    setBedrooms(propertyData?.bedrooms || "");
    setBathrooms(propertyData?.bathrooms || "");
    setPrice(propertyData?.estate_type || "");
    setWallConstruction(propertyData?.wall_construction || "");
  }, [propertyData]);

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setText(value);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const refreshImages = async () => {
    // Fetch the updated property data
    await dispatch(fetchProperty(slug));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url = `/properties/${propertyData?.slug}`;
    try {
      setSaving(true);
      const response = await apiService.putWithToken(url, {
        bedrooms,
        bathrooms,
        description: text,
        estate_type: price,
        images_blob_id,
        type: "normal",
      });

      setMessage({
        type: "success",
        message: "Your property has been updated successfully",
      });
      setAlertOpen(true);
      setTimeout(() => {
        navigate("/account-settings/my-properties");
      }, 2000);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Property could not be updated. Please try again",
      });
      setAlertOpen(true);
      console.error("Error during property edit", error);
    } finally {
      setSaving(false);
    }
  };

  const setUploadedImageIds = (image) => {
    setUploadedImageIdsList([...images_blob_id, image.blob_id]);
  };

  const removeImage = (blobId) => {
    setUploadedImageIdsList(images_blob_id.filter(id => id !== blobId));
    // Add logic to remove the image from the backend if necessary
  };

  return (
    <div>
      <Header />
      <div className="my-properties-subheader-nav">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>
          {`>`}
          <Link to="/account-settings/my-properties" className="custom-link">
            &nbsp; &nbsp;My Properties &nbsp; &nbsp;
          </Link>
          {`>`}
          <Link to="" className="custom-link">
            &nbsp; &nbsp;Manage Property
          </Link>
        </div>
        <div className="mt-8 text-3xl lg:text-4xl">Manage Property</div>
      </div>
      <ViewCard edit={true} marker={propertyData} />
      <div className="section-container">
        <form className="form" onSubmit={handleSubmit}>
          <div className="single-form">
            <label htmlFor="askingvalue" className="form-label">
              Asking Value
            </label>
            <input
              type="text"
              id="askingValue"
              onChange={(event) => setPrice(event.target.value)}
              name="askingValue"
              value={price}
              className="form-input text-right"
            />
          </div>
          <div className="section-subheading">
            <h3>Property Details</h3>
            <span></span>
          </div>
          <div className="form-group">
            <div className="single-form">
              <label htmlFor="bedrooms" className="form-label">
                Bedrooms
              </label>
              <input
                type="text"
                id="bedrooms"
                onChange={(event) => setBedrooms(event.target.value)}
                name="bedrooms"
                className="form-input"
                value={bedrooms}
              />
            </div>
            <div className="single-form">
              <label htmlFor="bathrooms" className="form-label">
                Bathrooms
              </label>
              <input
                type="text"
                id="bathrooms"
                onChange={(event) => setBathrooms(event.target.value)}
                name="bathrooms"
                className="form-input"
                value={bathrooms}
              />
            </div>
          </div>
          <div className="single-form">
            <label htmlFor="description" className="form-label">
              Property Descriptions
            </label>
            <textarea
              value={text}
              type="text"
              id="description"
              name="description"
              className="form-textarea"
              rows={8}
              maxLength={maxLength}
              onChange={handleChange}
            />
            <div style={{ position: "absolute", right: "12%" }}>
              {maxLength - text.length} characters remaining
            </div>
          </div>
          <div className="single-form">
            <label htmlFor="construction" className="form-label">
              Construction
            </label>
            <textarea
              type="text"
              id="construction"
              name="construction"
              className="form-textarea"
              rows={8}
              maxLength={maxLength}
              value={wall_construction}
              onChange={(e) => setWallConstruction(e.target.value)}
            />
          </div>
          <div className="image-group">
            <label htmlFor="description" className="form-label">
              Photos
            </label>
            <div className="image-container">
              
              <ImageUploaderForProperty
                property={propertyData}
                updateProperty={(field, value) => dispatch(updatePropertyField(field, value))}
                refreshImages={refreshImages}
              />
            </div>
          </div>
          <div className="image-group">
            <label htmlFor="description" className="form-label">
              Virtual Tour
            </label>
            <div className="image-container">
              <VirtualImageUploaderForProperty
                property={propertyData}
                updateProperty={(field, value) => dispatch(updatePropertyField(field, value))}
              />
            </div>
          </div>
          <div className="form-update-btn">
            <button disabled={isSaving} type="submit">
              {isSaving ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
      <Footer />
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
    </div>
  );
};

export default ManageProperty;