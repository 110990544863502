import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Profile.css";

const Profile = () => {
  const navigate = useNavigate();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const editProfile = (slug) => {
    navigate(`/account-settings/personal-info`);
  };

  const userName =
    currentUser && currentUser?.first_name
      ? `${currentUser?.first_name} ${currentUser?.last_name}`
      : currentUser?.email;

  const onGetVerified = useCallback(() => {
    navigate("/verification");
  }, [navigate]);
  return (
    <div className="container">
      <div className="profile-wrapper">
        <div className="cover-pic">{/* Cover pic content */}</div>
        <div className="profile-container">
          <div className="buttons">
            <button onClick={editProfile} className="profile-button">
              Edit Profile
            </button>
          </div>
          <div>
            <div></div>
            <div>
              <h3 className="image-name">{userName}</h3>
            </div>
          </div>
          <div></div>
          {currentUser?.is_verified === "null" ||
            (!currentUser?.is_verified && (
              <div className="card-container">
                <div className="card-text">
                  Before you make an offer or verify your property, you’ll need
                  to complete this step.
                </div>
                <div className="card-button-area">
                  <button onClick={onGetVerified} className="card-button">
                    Get Verified
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
