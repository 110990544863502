import React, { useState, useEffect } from "react";
import "./PersonalInfo.css";
import Header from "../../components/common/Header";

import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/common/Footer";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AccountInfoCard from "../../components/account/AccountInfoCard";
import { updateUser } from "../../redux/actions/userActions";

function PersonalInfo() {
  const navigate = useNavigate();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(currentUser || {});
  const [isEditing, setIsEditing] = useState(false);
  const [info, setInfo] = useState("personal_info_text");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setUserData(currentUser);
    } else {
      navigate("/login");
    }
  }, [currentUser]); // Listen to currentUser changes

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    console.log(JSON.stringify(userData, null, 2));
    dispatch(updateUser(userData));
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  return (
    <>
      <Header />
      <div className="personal_info_container">
        <div>
          <Link to="/account-settings" className="custom-link">
            Account Settings &nbsp; &nbsp;
          </Link>

          {`>`}

          <Link to="" className="custom-link">
            &nbsp; &nbsp;Personal Info
          </Link>
        </div>
        <div className="personal_info_heading">Personal Info</div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div className="mt-12">
              {isEditing ? (
                <div className="flex flex-row  button-conatiner">
                  <button className="profile-button" onClick={handleSave}>
                    Submit
                  </button>
                </div>
              ) : (
                <button className="profile-button" onClick={handleEdit}>
                  Edit
                </button>
              )}
            </div>
            <form>
              <table className="personal_info_table">
                <tr>
                  <td>
                    <p className="personal_info_filed">
                      Legal name
                      <br />
                      {isEditing ? (
                        <>
                          <div className="flex flex-row  justify-start  w-full ">
                            <input
                              className="p-4 border border-gray-300 rounded-md mr-4  "
                              type="text"
                              id="first_name"
                              value={userData["first_name"] || ""} // Handle potentially undefined values
                              onChange={handleChange}
                              readOnly={!isEditing}
                            />

                            <input
                              className="p-4 border border-gray-300 rounded-md  "
                              type="text"
                              id="last_name"
                              value={userData["last_name"] || ""}
                              onChange={handleChange}
                              readOnly={!isEditing}
                            />
                          </div>
                        </>
                      ) : (
                        <span className="personal_info_text">
                          {" "}
                          {userData
                            ? `${userData["first_name"]} ${userData["last_name"]}`
                            : ""}
                        </span>
                      )}
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  <td>
                    <div className="personal_info_filed pr-4 ">
                      Email
                      <br />
                      <input
                        type="text"
                        id="email"
                        value={userData ? userData["email"] : ""}
                        onChange={handleChange}
                        readOnly={!isEditing}
                        className={`personal_info_text w-full ${isEditing
                          ? "p-4 border border-gray-300 rounded-md"
                          : ""
                          }`}
                      />
                    </div>
                  </td>
                </tr>{" "}
                <tr>
                  <td>
                    <div className="personal_info_filed w-full pr-4">
                      Phone No
                      <br />
                      <input
                        type="text"
                        id="phone"
                        maxLength={20}
                        value={userData ? userData["phone"] : ""}
                        onChange={handleChange}
                        readOnly={!isEditing}
                        className={`personal_info_text ${isEditing
                          ? "p-4 border border-gray-300 rounded-md"
                          : ""
                          }`}
                      />
                    </div>
                  </td>
                </tr>{" "}
                {/*
                  <tr>
                    <td>
                      <div className="personal_info_filed w-full pr-4">
                        Address
                        <br />
                        <input
                          type="text"
                          id="address"
                          value={userData ? userData["address"] : ""}
                          onChange={handleChange}
                          readOnly={!isEditing}
                          className={`personal_info_text w-full ${isEditing ? 'p-4 border border-gray-300 rounded-md' : ''}`}
                        />
                      </div>
                    </td>
                  </tr>
                */}
              </table>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginTop: "60px" }}>

              {userData.is_verified ? (
                <AccountInfoCard
                  icon={"/images/UserProtection.svg"}
                  heading={"Account Verified"}
                  para={"Your account has been verified."}
                />
              ) : (<AccountInfoCard
                icon={"/images/UserProtection.svg"}
                heading={"Account Not Verified"}
                para={"Your account is not yet verified."}
              />)}
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </>
  );
}

export default PersonalInfo;
