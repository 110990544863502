import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import mapIcon from "../../assets/pin.png";
// const center = {
//   lat: -43.528763,
//   lng: 172.657002,
// };
const GoogleMapForPages = ({ center }) => {
  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("320px");

  const mapOptions = {
    streetViewControl: true,
    zoomControl: true,
    mapTypeControl: true,
    fullscreenControl: true,
  };
  useEffect(() => {
    const handleResize = () => {
      setContainerWidth("100%");
      setContainerHeight("320px");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          mapContainerStyle={{
            width: containerWidth,
            height: containerHeight,
          }}
          options={mapOptions}
          center={center}
          zoom={14}
        >
          {" "}
          <Marker
            position={center}
            icon={{
              url: mapIcon,
              size: window.google?.maps?.Size
                ? new window.google.maps.Size(60, 60)
                : undefined,
              anchor: window.google?.maps?.Point
                ? new window.google.maps.Point(20, 60)
                : undefined,
              scaledSize: window.google?.maps?.Size
                ? new window.google.maps.Size(60, 60)
                : undefined,
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default GoogleMapForPages;
