import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import ToggleButtons from "../../components/ui/ToggleButtons";
import "./Sell.css";
import image from "../../assets/sell_step1.png";
import image2 from "../../assets/sell_step2.png";
import image3 from "../../assets/sell_step3.png";
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Home = () => {
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(100000); // Initial slider value
  const [savings, setSavings] = useState(5163.5); // Initial savings
  const theme = useTheme();
  const onSellTextClick = useCallback(
    (t) => {
      navigate(`/${t}`);
    },
    [navigate]
  );
  const onGroupContainerClick = useCallback(() => {
    navigate("/plan#start");
  }, [navigate]);

  const onOffer = useCallback(() => {
    navigate("/set-property");
  }, [navigate]);

  const onLearnMore = useCallback(() => {
    navigate("/how-it-works");
  }, [navigate]);

  // Function to calculate savings based on property value
  const calculateSavings = (propertyValue) => {
    const commissionRate = 3.99; // Commission rate in percentage
    const baseFee = 500; // Base fee in dollars
    const taxRate = 1.15; // Tax rate (15%)

    let amountSaved;

    // For property values under $400,000
    if (propertyValue <= 400000) {
      amountSaved =
        ((propertyValue / 100) * commissionRate + baseFee) * taxRate;
    } else {
      // Calculate the amount for the first $400,000
      const amountForFirst400k =
        ((400000 / 100) * commissionRate + baseFee) * taxRate;
      // Calculate the amount for the value above $400,000
      const amountAbove400k = ((propertyValue - 400000) / 100) * 1.99 * taxRate;
      // Sum both amounts
      amountSaved = amountForFirst400k + amountAbove400k;
    }

    return amountSaved;
  };

  // Function to handle slider value change
  const sliderChange = (event, newValue) => {
    setSliderValue(newValue);
    const newSavings = calculateSavings(newValue);
    setSavings(newSavings);
  };

  return (
    <>
      <Header />
      <div style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + 'images/home-bg.svg'})`,
        backgroundPosition: 'center 15%',
        backgroundRepeat: 'no-repeat',
        width: '100%',


      }} className="w-full">
        <div className="container">
          <main>
            <ToggleButtons text={"sell"} onSellTextClick={onSellTextClick} />
            <div className="text-overlay">
              <div className="overlay-text-sell">
                <div className="color1 pink">AnyProperty it.</div>
                <span className="inner-text">You could save</span>{" "}
                <div>
                  <span className="amount">${savings.toFixed(2)} NZD</span>
                </div>
                <div className="text">
                  <span>
                    property valued at an estimated{" "}
                    <span
                      className="bold"
                      style={{ textDecoration: "underline" }}
                    >
                      ${sliderValue.toLocaleString()} NZD
                    </span>{" "}
                  </span>
                  <div className="slider-container my-10">
                    <div className="slider">
                      <Box
                        sx={{
                          width: 300, // Default width
                          "@media (min-width: 600px)": {
                            // Targets screens with a width of 1200px or more
                            width: 500, // Width for large screens
                          },
                          "@media (min-width: 800px)": {
                            // Targets screens with a width of 1200px or more
                            width: 700, // Width for large screens
                          },
                        }}
                      >
                        <Slider
                          style={{ height: 10, marginRight: 80,
                            [theme.breakpoints.down('sm')]: {
                              marginRight: 0,
                            },
                          }} // Set the width to make it wider
                          sx={{
                           
                            "& .MuiSlider-thumb": { width: 50, height: 30 },
                            "& .MuiSlider-thumb": {
                              color: "#001f3f",
                            },
                            "& .MuiSlider-track": {
                              color: "#001f3f",
                              height: 5,
                            },
                            "& .MuiSlider-rail": {
                              color: "#001f3f",
                              height: 5,
                            },
                            "& .MuiSlider-active": {
                              color: "#001f3f",
                            },
                          }}
                          value={sliderValue}
                          min={100000}
                          max={1000000} // Assuming the max value you want to allow
                          step={1000} // Assuming the step you want for the slider
                          onChange={sliderChange}
                          color="secondary"
                        />
                      </Box>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="text-lg md:w-8/12 md:m-auto m-4 font-product-sans-light">
                      Use the toggle to see what you could save by cutting out
                      agent fees.
                    </div>
                  </div>
                </div>
                {/* <div className="text">
              <span>
                property valued at an estimated ${sliderValue.toLocaleString()}{" "}
                NZD
              </span>
            </div> */}
              </div>
            </div>

            <div className="overlay-text-blue additional-overlay">
              <div className="long-text">
                <div className="center">
                  <div className="main-text-white">Verify your property & </div>

                  <div className="pink-color-text">start receiving offers</div>
                </div>{" "}
                <div className="input-sell-container">
                  <div className="input-wrapper">
                    <input type="text" placeholder="Enter your home address" />
                    <button onClick={onOffer}>Get Offer</button>
                  </div>
                </div>
              </div>
            </div>

            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 1</div>
                  <div className="search">Input</div>
                  <div className="steps-container-bottom">
                    Verifying your home allows us to connect you with interested
                    buyers who’ve made an offer on your property.
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="sell-steps-image"
                  loading="lazy"
                  alt=""
                  src={image}
                />
              </div>
            </section>

            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 2</div>
                  <div className="search">Verify</div>
                  <div className="steps-container-bottom">
                    Using the latest technology, we’ve integrated simple softwares
                    to help your home gain more attention and higher quality
                    offers, making sure you get the best offer possible.
                    <p style={{ marginTop: "25px" }}>
                      You can opt in to access proven methods to stand out from
                      the crowd, use 3D virtual tours, virtual staging, lighting
                      features, and more!
                    </p>
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="sell-steps-image"
                  loading="lazy"
                  alt=""
                  src={image2}
                />
              </div>
            </section>

            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 3</div>
                  <div className="search">Upload</div>
                  <div className="steps-container-bottom">
                    Our all in one selling process allows you to securly appect or
                    decline offers, negotiate prices, set-up agreements, and more!
                    <p style={{ marginTop: "25px" }}>
                      We provide you with all the necessary resources you'll need
                      to successfully sell a home. You can opt in to access proven
                      methods to stand out from the crowd, use 3D virtual tours,
                      virtual staging, lighting features, and more!
                    </p>
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="sell-steps-image"
                  loading="lazy"
                  alt=""
                  src={image3}
                />
              </div>
            </section>
            <div className="text-overlay margin">
              <div className="overlay-text">
                <button className="blue-button button-custom" onClick={onLearnMore}>
                  Learn More
                </button>
              </div>
            </div>
            <div className="overlay-container black-area">
              <img src="/images/bf-21@2x.png" alt="Step 1 Image" />
              <div className="overlay-text-bottom">
                <div className="big-text-white">Get more</div>
                <div className="big-text-white">for your property</div>
                {/* <div className="small-text-custom">
              Over 100 buyers throughout NZ makes an offer on AnyProperty every
              minute.
            </div> */}
                <div style={{ marginTop: "25px" }}>
                  <button
                    onClick={onGroupContainerClick}
                    className=" button-custom white-button"
                  >
                    Start Today
                  </button>
                </div>
                <div className="small-text-custom">
                  30 days free trial. No strings attached.
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
