import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./MyPropertyCard.css";
import { useDispatch, useSelector } from "react-redux";
import OfferCard from "../property/OfferCard";
import ViewCard from "../property/ViewCard";
import mapIcon from "../../assets/pin.png";
import PopupModal from "../common/PopupModal";
import AlertBox from "../../components/common/AlertBox";
import apiService from "../../services/apiService";
import ViewOfferCard from "../property/ViewOfferCard";
import { useNavigate } from "react-router-dom";

// import { Grid } from "@mui/material";

const center = {
  lat: -43.528763,
  lng: 172.657002,
};

const MyPropertyCard = ({
  viewings,
  page,
  marker,
  card,
  isManaged = true,
  refetch,
}) => {
  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("320px");
  const [viewingId, setViewingId] = useState(null);
  const [message, setMessage] = useState("");
  const [isSaving, setSaving] = useState(false);
  const [images_blob_id, setUploadedImageIdsList] = useState([]);
  const [openAlert, setAlertOpen] = useState(false);
  const [containerRadius, setContainerRadius] = useState(
    page === "isMyProperties" ? "0px" : "15px"
  );
  const [locationArrayOffer, setLocationArrayOffer] = useState([]);
  const [locationArrayView, setLocationArrayView] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [locationArrayViewOffer, setLocationArrayViewOffer] = useState([]);

  const { viewingsAndOffers, currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const handlePopup = (id) => {
    setViewingId(id);
    setOpenModel(true);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setContainerWidth("500px");
      } else if (window.innerWidth >= 960) {
        setContainerWidth("400px");
      } else if (window.innerWidth >= 850) {
        setContainerWidth("300px");
      } else if (window.innerWidth <= 450) {
        setContainerWidth("350px");
        setContainerHeight("200px");
      } else if (window.innerWidth <= 560) {
        setContainerWidth("400px");
        setContainerHeight("250px");
      } else if (window.innerWidth <= 674) {
        setContainerWidth("500px");
        setContainerHeight("280px");
      } else {
        setContainerWidth("250px");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleViewOffers = (locationName, marker) => {
    let selectedLocationData;
    if (locationName === "locationA") {
      selectedLocationData = viewOffer[0].locationA;
    } else if (locationName === "locationB") {
      selectedLocationData = viewOffer[0].locationA;
    } else {
      selectedLocationData = viewOffer[0].locationA;
    }
    setLocationArrayViewOffer((locationArrayViewOffer) => [
      ...locationArrayViewOffer,
      selectedLocationData,
    ]);
    navigate(`/account-settings/my-properties/view-offer?slug=${marker.slug}`);
  };
  const pointers =
    marker.lng && marker.lat
      ? { lat: marker.lat, lng: marker.lng }
      : { lat: marker.latitude, lng: marker.longtitude || marker.longitude };

  const acceptRejectViewing = async (isApproved) => {
    try {
      setLoading(true);
      let suffix = `/seller_date_confirmed`;
      if (!isApproved) {
        suffix = `/seller_request_date_change`;
      }
      await apiService.postWithToken(
        `users/${currentUser}/viewings/${viewingId}/${suffix}`
      );
      setMessage({
        type: "success",
        message: "Your action have been sent to requester",
      });
      setAlertOpen(true);
    } catch (e) {
      setMessage({
        type: "error",
        message: e.error,
      });
      setAlertOpen(true);
    } finally {
      setLoading(false);
      setOpenModel(false);
      refetch();
    }
  };
  return (
    <div className="container-section">
      <div
        className={
          page === "isMyProperties" ? "isMyProperties" : "container-myproperty"
        }
      >
        {page !== "isViewOffer" && (
          <div className="mypropertycard-left">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
              <GoogleMap
                mapContainerStyle={{
                  width: containerWidth,
                  height: containerHeight,
                  borderRadius: containerRadius,
                }}
                options={mapOptions}
                center={pointers}
                zoom={14}
              >
                {" "}
                <Marker position={pointers} />
                {page !== "isMyProperties" && (
                  <div className="gMap-location-name">
                    <p>{marker.full_address}</p>
                  </div>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        )}

        {page === "isMyProperties" && (
          <div className="mypropertycard-right">
            <div className="mypropertycard-top">
              <div className="card-details">
                <h2>{marker.full_address}</h2>
                <p>Status:</p>

                <button className="pending-btn">
                  {marker.verified ? "Verified" : "Pending Verification"}
                </button>
              </div>

              <div className="button-group">
                {/* {card !== "view_requests" && (
                <button onClick={() => handleViewOffers(location, marker)}>
                  View Offers
                </button>
              )} */}
                {isManaged && (
                  <button
                    onClick={() =>
                      navigate(
                        `/account-settings/my-properties/manage-property?slug=${marker.slug}`
                      )
                    }
                  >
                    Manage
                  </button>
                )}
              </div>

              {/* <div className="offer-number">
                <h2>3</h2>
                <p>Offers</p>
              </div> */}
            </div>
          </div>
        )}
        {page === "isOfferRequest" && (
          <OfferCard locationArray={locationArrayOffer} />
        )}
        {page === "isViewRequest" && (
          <OfferCard
            property={marker}
            handlePopup={handlePopup}
            locationArray={viewings}
            handleClick={acceptRejectViewing}
          />
        )}
        {page === "isViewOffer" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ViewCard marker={marker} />
            <ViewOfferCard
              marker={marker}
              handlePopup={handlePopup}
              locationArray={viewings}
            />
          </div>
        )}
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
      <PopupModal
        open={openModel}
        setOpen={setOpenModel}
        isLoading={isLoading}
        title={marker.full_address}
        handleClick={acceptRejectViewing}
        description={"Accept this viewing"}
      />
    </div>
  );
};

export default React.memo(MyPropertyCard);
