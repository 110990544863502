import React, { useState, useEffect } from 'react';
import apiServiceUploadFiles from 'services/apiServiceUploadFiles';

const IdentityUpload = ({ userId, onUploadSuccess, onUploadStart }) => {
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [frontImageUrl, setFrontImageUrl] = useState(null);
    const [backImageUrl, setBackImageUrl] = useState(null);
    const [message, setMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const uploadImage = async (file, type) => {
        if (!file) return;

        setIsUploading(true);
        if (onUploadStart) {
            onUploadStart();
        }

        try {
            let response;
            if (type === 'upload_identity_front') {
                response = await apiServiceUploadFiles.uploadIdentityFront(userId, file);
                setFrontImageUrl(response.image_url);
                setMessage(`Front of ID uploaded successfully!`);
            }
            if (type === 'upload_identity_back') {
                response = await apiServiceUploadFiles.uploadIdentityBack(userId, file);
                setBackImageUrl(response.image_url);
                setMessage(`Back of ID uploaded successfully!`);
            }

            if (frontImage && backImage) {
                onUploadSuccess();
            }
        } catch (error) {
            setMessage(`Error uploading ${type}. Please try again.`);
            console.error('Upload error:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleFrontImageChange = (e) => {
        const file = e.target.files[0];
        setFrontImage(file);
        uploadImage(file, 'upload_identity_front');
    };

    const handleBackImageChange = (e) => {
        const file = e.target.files[0];
        setBackImage(file);
        uploadImage(file, 'upload_identity_back');
    };

    useEffect(() => {
        if (frontImage && backImage) {
            onUploadSuccess();
        }
    }, [frontImage, backImage, onUploadSuccess]);

    return (
        <div>
            <h2>Upload Identity Documents</h2>
            <div className='flex flex-row items-left justify-left mb-4 mt-4'>
                <label htmlFor="frontImage" className='mr-4 mt-1 minumum-w-[200px] w-[200px] h-[20px] '>Front of ID:</label>
                <input
                    type="file"
                    id="frontImage"
                    onChange={handleFrontImageChange}
                    accept="image/*"
                    disabled={isUploading}
                />
            </div>
            {frontImageUrl && (
                <div>
                    <img src={frontImageUrl} alt="Front of ID" style={{maxWidth: '200px'}} />
                    <button onClick={() => {setFrontImage(null); setFrontImageUrl(null);}}>Upload new front image</button>
                </div>
            )}
            <div className='flex flex-row items-left justify-left mb-4 mt-4'>
                <label htmlFor="backImage" className='mr-4 mt-1 minumum-w-[200px] w-[200px] h-[20px] '>Back of ID:</label>
                <input
                    type="file"
                    id="backImage"
                    onChange={handleBackImageChange}
                    accept="image/*"
                    disabled={isUploading}
                />
            </div>
            {backImageUrl && (
                <div>
                    <img src={backImageUrl} alt="Back of ID" style={{maxWidth: '200px'}} />
                    <button onClick={() => {setBackImage(null); setBackImageUrl(null);}}>Upload new back image</button>
                </div>
            )}
            {message && <p>{message}</p>}
            {isUploading && <p>Uploading...</p>}
        </div>
    );
};

export default IdentityUpload;