// PropertyContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchProperty, fetchPropertySuccess, fetchPropertyFailure } from '../redux/actions/propertyDetailActions';

const PropertyContext = createContext();

export const useProperty = () => useContext(PropertyContext);

export const PropertyProvider = ({ children, initialSlug }) => {
  const dispatch = useDispatch();
  const propertyState = useSelector(state => state.property); // Changed from propertyDetail to property
  const { property, loading, error } = propertyState || {}; // Destructure from propertyState
  const [slug, setSlug] = useState(initialSlug);

  useEffect(() => {
    if (slug) {
      dispatch(fetchProperty(slug));
    }
  }, [slug, dispatch]);

  const updateProperty = (section, data) => {
    dispatch(fetchPropertySuccess({
      ...property,
      [section]: { ...property[section], ...data },
    }));
  };

  const saveProperty = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      let response;
      if (slug) {
        response = await apiService.putWithToken(`/properties/${slug}`, property);
      } else {
        response = await apiService.postWithToken('/properties/create', property);
      }
      
      if (response.data && response.data.id) {
        dispatch(fetchPropertySuccess(response.data));
        if (!slug) {
          setSlug(response.data.slug);
        }
        return response.data;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error("Error saving property:", err);
      dispatch(fetchPropertyFailure(err.message || "Failed to save property data"));
      return null;
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  return (
    <PropertyContext.Provider value={{ 
      property, 
      updateProperty, 
      saveProperty, 
      loading, 
      error,
      slug,
      setSlug
    }}>
      {children}
    </PropertyContext.Provider>
  );
};