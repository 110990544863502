import React from "react";
import Slider from "react-slick";

const ImageSlider = ({ images }) => {
  const settings = {
    swipeToSlide: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function validateUrl(url) {
    const pattern =
      /^(https?:\/\/)?[\w-]+(\.[\w-]+)+\.[\w-]+(\/[^\/#?]+)+\.(jpeg|jpg|png)$/i;
    return pattern.test(url);
  }
  const imagesFiltered =
    Array.isArray(images) && images.length
      ? images.filter((i) => validateUrl(i))
      : [];
  return (
    <Slider {...settings}>
      {imagesFiltered &&
        imagesFiltered.map((img, index) => (
          <div key={index}>
            <img src={img} style={{ width: "100%", display: "block" }} />
          </div>
        ))}
    </Slider>
  );
};

export default ImageSlider;
