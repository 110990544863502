import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { searchProperty } from "../../utils/elasticSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperties } from "../../redux/actions/propertyActions";
import Header from "../../components/common/Header";
import TextField from "@mui/material/TextField";
import PropertyCard from "../../components/property/PropertyCard";
import Footer from "../../components/common/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { Skeleton } from "../../components/common/Skeleton";
import ClusterGoogleMap from "../../components/map/ClusterGoogleMap";
import PropertyFilterDropDown from "../../components/property/PropertyFilterDropDown";
import "../../components/property/PropertyFilterDropDown.css";
import FilterModal from "../../components/property/FilterModal"; // Import the modal component
import { useInView } from "react-intersection-observer";
import "./Browse.css";

const Browse = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const { properties } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Newest");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inViewMarkers, setInViewMarkers] = useState([]);
  const batchSize = 20; // Adjust this to control how many items to load at a time
  const [filters, setFilters] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const result = await searchProperty(searchTerm, filters);
        fetchProperties(result);
        setFilteredMarkers(result);
        setInViewMarkers(result.slice(0, batchSize));
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
  }, [searchTerm, filters]);

  useEffect(() => {
    if (properties && properties.length) {
      setFilteredMarkers(properties);
    }
  }, [properties]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const loadMoreMarkers = () => {
    const newMarkers = filteredMarkers.slice(
      inViewMarkers.length,
      inViewMarkers.length + batchSize
    );
    setInViewMarkers((prevMarkers) => [...prevMarkers, ...newMarkers]);
  };

  const onPropertyClick = (slug) => {
    navigate(`/product-view?slug=${slug}`);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    let sortedMarkers = [];
    if (option === "Newest") {
      sortedMarkers = [...filteredMarkers].sort(
        (a, b) => new Date(b.listing_date) - new Date(a.listing_date)
      );
    } else if (option === "Lowest Price") {
      sortedMarkers = [...filteredMarkers].sort((a, b) => a.price - b.price);
    } else if (option === "Highest Price") {
      sortedMarkers = [...filteredMarkers].sort((a, b) => b.price - a.price);
    }
    setFilteredMarkers(sortedMarkers);
    setInViewMarkers(sortedMarkers.slice(0, inViewMarkers.length));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const saveFilters = (newFilters) => {
    setFilters(newFilters);
  };
  const { ref: loaderRef, inView } = useInView({
    threshold: 1,
    triggerOnce: false,
    onChange: (inView) => {
      if (inView) {
        loadMoreMarkers();
      }
    },
  });

  return (
    <div className="browse">
      <Header />
      <div>
        <div className="backgroundDiv">
          <div className="map-container-custom">
            <ClusterGoogleMap data={filteredMarkers} />
          </div>
        </div>
        <div className="overlayDiv">
          <div className="overlayDiv-header">
            <span className="search-title"></span>
            <div className="input-box">
              <TextField
                label="Homes for sale"
                placeholder="Enter Address, city"
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              />
            </div>
          </div>
          <hr />
          <div className="mx-4 mt-2 flex space-x-4 text-sm">
            <PropertyFilterDropDown onSelect={handleSelect} />
            <div className="border border-black border-2">
              <button onClick={openModal} className="filter-button h-full">
                Filters
              </button>
            </div>
          </div>
          <div className="inner-box px-4">
            {isLoading && (
              <div>
                <Skeleton /> <Skeleton /> <Skeleton />
              </div>
            )}
            {!isLoading &&
              inViewMarkers.map((marker) => (
                <div
                  key={marker.id}
                  className="box fade-in"
                  onClick={() => onPropertyClick(marker.slug)}
                >
                  <PropertyCard
                    pic={marker?.legal_description?.split("|")[0]}
                    title={marker.full_address}
                    bedroom={marker.bedrooms}
                    bathroom={marker.bathrooms}
                    price={
                      Number.isFinite(marker.estate_type)
                        ? `${marker.estate_type.toLocaleString()} NZD`
                        : marker.estate_type
                    }
                  />
                </div>
              ))}
            <div ref={loaderRef}>
              {filteredMarkers &&
                inViewMarkers.length < filteredMarkers.length && (
                  <CircularProgress />
                )}
            </div>
          </div>
        </div>
      </div>
      <FilterModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={saveFilters}
      />
      <Footer />
    </div>
  );
};

export default Browse;
