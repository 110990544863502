import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../auth/SignUp.css";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import { NavLink } from "react-router-dom";
import apiService from "../../services/apiService";
export default function Cofirmation() {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const resendEmail = async () => {
    try {
      await apiService.post("/users/confirmation", {
        user: {
          email,
        },
      });
      setMessage({
        type: "success",
        message: "Confirm email sent successfully",
      });
      setOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Sign failed. Please try again.",
      });
      setOpen(true);
      console.error("Sign up error:", error);
      throw error;
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Confirmation</div>
        <div className="step-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step active">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>
      <form>
        <div className="container mt-5 mb-5">
          <div className="row justify-content-center">
            <div className="col-md-4 bg-gray signup center confirmation bg-white text-center shadow p-5">
              <h1>
                <div className="thank p-50">Thanks!</div>
              </h1>
              <div className="p1 mt-5 m-b-20">
                A confirmation email has been sent to your email.
              </div>{" "}
              <div className="p1 mt-5 m-b-20">
                Please check your inbox and validate your account.{" "}
              </div>
              <div className="p1 mt-5 m-b-20 p-50 sub-text">
                You’re almost there! To complete your account verification and
                take advantage of the many premium memebership benefits, the
                following page requires your payment information to start the 30
                days risk-free trial - cancel anytime.
              </div>
              <div className="center">
                <NavLink
                  className="link m-b-20"
                  to={`/subscription?email=${email}`}
                >
                  <button className="button fixed-width-button">
                    Continue
                  </button>
                </NavLink>
              </div>
              <div className="m-b-20 p-50 p-50 p-50 link">
                <br />
                <a href="#" onClick={resendEmail}>
                  Resend Email
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AlertBox
        open={open}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
      <Footer />
    </div>
  );
}
