import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import "./SetPropertyCommunity.css";

function SetPropertyCommunity() {
  const { property, updateProperty } = useProperty();

  useEffect(() => {
    console.log("Current property data:", JSON.stringify(property, null, 2));
    console.log("Community data:", JSON.stringify(property?.community, null, 2));
  }, [property]);

  const handleChange = (field, event) => {
    const newValue = event.target.value;
    const [parentField, childField] = field.split('.');
    updateProperty(parentField, {
      ...property[parentField],
      [childField]: newValue
    });
  };

  const communityDetails = [
    { label: "Local Zone", field: "community.land_zone", value: property?.community?.land_zone || "" },
    { label: "Local Council", field: "community.local_council", value: property?.community?.local_council || "" },
  ];

  if (!property) {
    return <div>Loading property data...</div>;
  }

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          Tell us about the community
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={7} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-community-container">
            {communityDetails.map((detail) => (
              <div key={detail.field} className="property-card-specifics">
                <p>{detail.label}</p>
                <input
                  type="text"
                  value={typeof detail.value === 'object' ? JSON.stringify(detail.value) : detail.value}
                  className="property-card-specifics-input"
                  onChange={(event) => handleChange(detail.field, event)}
                />
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyCommunity;