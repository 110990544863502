import React, { Suspense } from "react";
import Image from "../common/Image";
const LazyImage = React.lazy(() => import("../common/LazyImage"));
const PropertyCard = ({ title, bedroom, bathroom, price, pic }) => {
  // Function to trim the title to 50 characters and add ellipsis if longer
  const trimmedTitle =
    title.length > 40 ? `${title.substring(0, 40)}...` : title;

  return (
    <div className="image-text-section">
      <div className="image-half">
        <Image src={pic} alt={title} />
      </div>
      <div className="text-half">
        {/* Add a title attribute to the div for the tooltip, showing the full title on hover */}
        <div className="text-row" title={title}>
          {trimmedTitle}
        </div>
        <div className="row-small">
          <div className="text-row-parent">
            {/* 
                <img
                  className="br-1-icon"
                  loading="lazy"
                  alt=""
                  src="/images/br-1.svg"
                />
              */}
            <div className="small-text">{bedroom} Bedroom</div>
          </div>
          <div className="text-row-parent">
            {/*
           <img
             className="bath-1-icon"
             loading="lazy"
             alt=""
             src="/images/bath-1.svg"
           />
            */}
            <div className="small-text"> {bathroom ? `${bathroom} Bathroom` : '0 Bathroom'}</div>
          </div>
        </div>
        <div className="text-row bottom-align">{price}</div>
      </div>
    </div>
  );
};

export default PropertyCard;
