const FETCH_WATCHLISTS_REQUEST = "FETCH_WATCHLISTS_REQUEST";
const FETCH_WATCHLISTS_SUCCESS = "FETCH_WATCHLISTS_SUCCESS";
const FETCH_WATCHLISTS_FAILURE = "FETCH_WATCHLISTS_FAILURE";
// Initial State
const initialState = {
  loading: false,
  items: [],
  error: null,
};

// Reducer
const watchlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHLISTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WATCHLISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case FETCH_WATCHLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default watchlistsReducer;
