import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import userReducer from "./reducers/userReducer";
import paymentReducer from "./reducers/paymentReducer";
import propertyReducer from "./reducers/propertyReducer";
import verifiedPropertiesReducer from "./reducers/verifiedPropertiesReducer";
import watchlistReducer from "./reducers/watchlistReducer";
import propertyDetailReducer from "./reducers/propertyDetailReducer";
import verificationReducer from "./reducers/verificationReducer";
const rootReducer = combineReducers({
  payment: paymentReducer,
  properties: propertyReducer,
  property: propertyDetailReducer,
  user: userReducer,
  verifiedProperties: verifiedPropertiesReducer,
  watchlist: watchlistReducer,
  verification: verificationReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
