import React, { useState } from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, Box, Grid, Table, Tabs, TextField, Button } from "@mui/material";

import apiService from "../../services/apiService";

import "./Login.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const [value, setValue] = useState("one");
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(""); // Add email state
  const [emailError, setEmailError] = useState(""); // Add emailError state
  const [openAlert, setAlertOpen] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const queryParams = new URLSearchParams(location.search);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const resetPassword = async () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    const data = {
      user: {
        email: email,
        redirect_url: "",
      },
    };

    try {
      const response = await apiService.post("users/password", data);
      setMessage({
        type: "success",
        message: "We have sent you an email for password reset.",
      });
      setAlertOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "An error occurred. Please try again.",
      });
      setAlertOpen(true);
    }
  };

  return (
    <>
      <div className="login-container">
        <Header />
        <div className="container">
          <div className="login">
            <div className="head">
              <p className="login_heading">Forgot Password</p>

            </div>
            <div className="email m-4">
              <p className="email_heading">Email</p>
              <input
                required
                type="email"
                name="email"
                className="email_subheading"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className="m-4 flex justify-center">
            <p onClick={resetPassword} className="login-security_edit">
              Reset
            </p>
            </div>
           
            {openAlert && <AlertBox message={message.message} type={message.type} />}
          </div>
        </div>
        <Footer />
        <AlertBox
          open={openAlert}
          type={message.type}
          onClose={handleClose}
          message={message.message}
        ></AlertBox>
      </div>
    </>
  );
}

export default ForgotPassword;
