// FilterModal.js
import React, { useState } from "react";

const FilterModal = ({ isOpen, onClose, onSave }) => {
  const [filters, setFilters] = useState({
    propertyType: "homes",
    priceMin: "No",
    priceMax: "No",
    bedrooms: "1+",
    bathrooms: "1+",
    listed: "Onmarket",
  });

  if (!isOpen) return null;

  const handleSave = () => {
    onSave(filters);
    onClose();
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value,
    }));
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-left text-black">
          <h3 className="text-sm leading-6 font-product-sans-medium ">
            Search Filter
          </h3>
          <hr className="my-3" />
          <div className="mt-2">
            <div className="mb-4">
              <label className="block text-sm  mb-2">Property Type</label>
              <div className="relative">
                <select
                  id="propertyType"
                  value={filters.propertyType}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="residential">Residential</option>
                  <option value="lifestyle">Lifestyle / Rural</option>
                  <option value="designated">Designated Land</option>{" "}
                  <option value="commercial">Commercial </option>{" "}
                  <option value="industrial">Industrial </option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm  mb-2">Price Range</label>
              <div className="flex flex-row">
                <div className="relative w-38 mr-4">
                  <select
                    id="priceMin"
                    value={filters.priceMin}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-32 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="No">No min</option>
                    <option value="50K">$50k</option>
                    <option value="100K">$100k</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <div className="relative w-38">
                  <select
                    id="priceMax"
                    value={filters.priceMax}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-32 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="No">No max</option>
                    <option value="250K">$250k</option>
                    <option value="300K">$300k</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm  mb-2">Bedrooms</label>
              <div className="relative">
                <select
                  id="bedrooms"
                  value={filters.bedrooms}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="1+">1+</option>
                  <option value="2+">2+</option>
                  <option value="3+">3+</option>
                  <option value="4+">4+</option>
                  <option value="5+">5+</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm  mb-2">Bathrooms</label>
              <div className="relative">
                <select
                  id="bathrooms"
                  value={filters.bathrooms}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="1+">1+</option>
                  <option value="2+">2+</option>
                  <option value="3+">3+</option>
                  <option value="4+">4+</option>
                  <option value="5+">5+</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            {/* <hr className="my-3" />
            <div className="mb-4">
              <label className="block text-sm mb-2">Listed</label>
              <div className="relative w-full">
                <select
                  id="listed"
                  value={filters.listed}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="Onmarket">On market</option>
                  <option value="Offmarket">Off market</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div> */}
          </div>

          <div className="flex justify-end  py-3">
            <button
              className="mr-2 px-4 py-2 bg-gray-300 text-base font-medium rounded-md w-24 shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-black text-white text-base font-medium rounded-md w-24 shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
