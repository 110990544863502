import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AlertBox from "../../components/common/AlertBox";
import "../auth/SignUp.css";
import { useSelector, useDispatch } from "react-redux";
import { setCountry } from "../../redux/actions/verificationActions";
import { NavLink, useNavigate } from "react-router-dom";
import HelpLink from "../../components/common/HelpLink";

export default function Verification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openAlert, setAlertOpen] = useState(false);
  const { country } = useSelector((state) => state.verification);
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const [localCountry, setLocalCountry] = useState(country || "");
  const onNextClick = ({ skip = false }) => {
    if (!skip) {
      if (localCountry === "") {
        setMessage({
          type: "error",
          message: "Please enter country for verification",
        });
        setAlertOpen(true);
        return;
      }
      dispatch(setCountry(currentUser.slug, localCountry));
      
    }
    navigate("/verification-upload-image");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
 
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Verification</div>
        <div className="step-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step active">
            <div className="step-number">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5 verification">
        <div className="confirmation">
          <button
            className="button"
            onClick={() => onNextClick({ skip: true })}
          >
            Skip for now
          </button>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 signup confirmation bg-gray text-center shadow p-5">
            <h3 className="text-center">Identity verification</h3>

            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={5} />
            </Box>
            <input
              className="form-control select mt-5"
              type="text"
              value={localCountry}
              onChange={(e) => setLocalCountry(e.target.value)}
              placeholder="Country of issue"
            />
            <p className="p1 mt-3 text-secondary">
              Only the following documents listed below will be accepted, all
              other documents will be rejected.
            </p>
            <div className="cards-container">
              <div className="cards-container-inner">
                <div className="box">
                  <img className="issuedid" src="/images/address.svg" alt="" />
                  <p>Government-Issued ID card</p>
                </div>
                <div className="box">
                  <img className="licesnce" src="/images/id.svg" alt="" />
                  <p>Driver’s License</p>
                </div>
                <div className="box">
                  <img className="pass" src="/images/passport.svg" alt="" />
                  <p>Passport</p>
                </div>
              </div>
            </div>
            <div className="m-b-50">
              <button onClick={onNextClick} className="button">
                Continue
              </button>
            </div>
            <div>
              <HelpLink />
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </div>
  );
}
