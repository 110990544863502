// propertyDetailReducer.js

import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  SET_LOADING,
} from "../actionTypes/propertyActionTypes";

const initialState = {
  property: {
    place: { coordinates: null, address: '' },
    pin: {},
    rooms: {},
    features: {},
    specifics: {},
    descriptions: {},
    community: {},
    photos: {},
    virtualTour: {},
  },
  loading: false,
  error: null,
};

const propertyDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case FETCH_PROPERTY_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PROPERTY_SUCCESS:
      return { ...state, loading: false, property: action.payload, error: null };
    case FETCH_PROPERTY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default propertyDetailReducer;