import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NavLink, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { green, red } from "@mui/material/colors";
import "../auth/SignUp.css";
import HelpLink from '../../components/common/HelpLink';

import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

export default function VerifictionUploadImage() {
  const navigate = useNavigate();
  const onNextClick = useCallback(() => {
    navigate("/verify-identify");
  }, [navigate]);
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Verification</div>
        <div className="step-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step active">
            <div className="step-number">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>
      <form>
        <div className="container mt-5 mb-5 verification">
          {" "}
          <div className="confirmation">
            <button onClick={onNextClick} className="button">
              Skip for now
            </button>
          </div>
          <div className="row justify-content-center confirmation">
            <div className="col-md-4 signup confirmation bg-gray text-center shadow p-5">
              <h3 className="text-center">Identity verification</h3>

              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={15} />
              </Box>

              <h5 className="text-center mt-4">Upload Image</h5>

              <div className="image1 p-3">
                <div className="div1">
                  <ul className="mt-2 mr-3">
                    <li>Government-issued</li>
                    <li>Original full-sized, unedited documents</li>
                    <li>
                      Place documents against a single coloured background
                    </li>
                    <li>Readable, well-lit, coloured images</li>
                    <li>
                      Place documents against a single coloured background
                    </li>
                    <li>Readable, well-lit, coloured images</li>
                  </ul>
                </div>
                <div className="div2">
                  <img src="/images/image2.png" />
                  <div className="check">
                    <CheckCircleIcon sx={{ color: green[400] }} />
                  </div>
                </div>
              </div>
              <div className="image2 p-3 mt-2">
                <div className="div1">
                  <ul className="mt-2 mr-3">
                    <li>No black and white images</li>
                    <li>No edited or expired documents</li>
                  </ul>
                </div>
                <div className="div2">
                  <img src="/images/image3.png" />
                  <div className="check">
                    <CancelIcon sx={{ color: red[400] }} />
                  </div>
                </div>
              </div>
              <div className="m-b-50">
                <NavLink className="link" to="/verify-identify">
                  <button className="button">Continue</button>
                </NavLink>
              </div>
              <div className="m-b-50">
                <NavLink className="back" to="/verification">
                  <b className="mt-3">Back</b>
                </NavLink>
              </div>
              <div>
              <HelpLink />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}
