import React, { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import "./Footer.css";
import FooterLogo from "../../assets/footer-logo-dark.png";
const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    const query = '*[_type == "footer"]';
    sanityClient.fetch(query).then((data) => {
      setFooterData(data[0]);
    });
  }, []);
  return (
    <footer>
      <div className="footer-container">
        <div className="flex-container-bottom">
          <div className="left-div">
            <div className="left-content">
            <a className="bolder" href={`mailto:${footerData?.supportEmail}`}>
                {footerData?.supportEmail}
              </a>

            </div>
          </div>

          <div className="right-div">
            <div className="right-content"></div>
          </div>
        </div>
        <div className="line-separator"></div>
        <div className="bottom-links">
          <div className="property-value">
            <div className="mask-group-frame">
              {footerData?.sections?.length > 0 && (
                <>
                  {footerData.sections.map((section) => (
                    <div
                      key={section._key}
                      className="support-help-centre-frame mr-0 md:mr-32"
                    >
                      <b className="col-heading">{section.heading}</b>
                      <div className="help-centre-parent">
                        {section?.links.map((link) => (
                          <div key={link._key} className={link._type}>
                            <a href={link.url}>{link.title}</a>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}{" "}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="faqs-area">
          {footerData?.bottomLinks?.length > 0 && (
            <div className="faqs">
              {footerData?.bottomLinks.map((item, index) => (
                <div key={index}>
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex-container-bottom">
          <div className="left-div">

            <span className="footer-copyright">
              @ Anyproperty 2024. All rights reserved.
            </span>

          </div>

          <div className="footer-right-div">
            <div className="logo-content">
              <img src={FooterLogo} alt="Anyproperty Logo" />
            </div>
          </div>
        </div>
        <div className="footer-brand">


        </div>



      </div>
    </footer>
  );
};

export default Footer;
