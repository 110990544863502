// propertyDetailActions.js

import apiService from "../../services/apiService";
import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  SET_LOADING,
} from "../actionTypes/propertyActionTypes";

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const fetchPropertyRequest = () => ({
  type: FETCH_PROPERTY_REQUEST,
});

export const fetchPropertySuccess = (property) => ({
  type: FETCH_PROPERTY_SUCCESS,
  payload: property,
});

export const fetchPropertyFailure = (error) => ({
  type: FETCH_PROPERTY_FAILURE,
  payload: error,
});

export const fetchProperty = (slug) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(fetchPropertyRequest());
    try {
      const data = await apiService.getWithToken(`/properties-detail/${slug}`);
      dispatch(fetchPropertySuccess(data));
    } catch (error) {
      dispatch(fetchPropertyFailure(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
  
};

export const updatePropertyField = (field, value) => ({
  type: 'UPDATE_PROPERTY_FIELD',
  payload: { field, value }
});