import React from 'react';
import './SetPropertyStepsCard.css';
import { Divider } from '@mui/material';

function SetPropertyStepsCard({ currentPage }) {
    let activeStepRange = [];
    if (currentPage >= 1 && currentPage <= 2) {
        activeStepRange = [0];
    } else if (currentPage >= 3 && currentPage <= 7) {
        activeStepRange = [0, 1];
    } else if (currentPage >= 8 && currentPage <= 9) {
        activeStepRange = [0, 1, 2];
    }

    return (
        <div className='set-property-card'>

            <div className="steps-process ">
                <ul>
                    <li className={activeStepRange.includes(0) ? 'active-steps' : ''}>
                        Step 1
                        <br />
                        Locate your place
                    </li>
                   
                </ul>
               
            </div>
            <Divider variant="middle" />
            <div className="steps-process">
                <ul>
                    <li className={activeStepRange.includes(1) ? 'active-steps' : ''}>
                        Step 2
                        <br />
                        Tell us about your place
                    </li>
                </ul>
            </div>
            <Divider variant="middle" />
            <div className="steps-process">
                <ul>
                    <li className={activeStepRange.includes(2) ? 'active-steps' : ''}>
                        Step 3
                        <br />
                        Add some photos
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default SetPropertyStepsCard;
