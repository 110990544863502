import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import AlertBox from "../../components/common/AlertBox";
import Footer from "../../components/common/Footer";
import apiService from "../../services/apiService";
import "./SignUp.css";
import HelpLink from "../../components/common/HelpLink";

export default function SignUp() {
  let navigate = useNavigate();

  // State hooks for form inputs
  const [email, setEmail] = useState("");
  const [marketing_emails, setReceiveMarketingEmails] = useState(false);

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirmed password
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState(""); // Corrected typo from 'seFirstName' to 'setFirstName'
  const [dob, setDOB] = useState("");
  const [error, setError] = useState(""); // State for potential error messages

  const handleCheckboxChange = (event) => {
    setReceiveMarketingEmails(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const login = async ({ first_name, last_name, dob, email, password }) => {
    const data = {
      user: {
        first_name,
        last_name,
        email,
        dob,
        password,
        marketing_emails: marketing_emails ? 1 : 0,
      },
    };
    setLoading(true);
    try {
      await apiService.post("/users", data);
      setLoading(false);
      navigate(`/confirmation?email=${email}`);
    } catch (error) {
      setLoading(false);
      let errorMessage = error.message;
      if (error.email) {
        errorMessage = `This Email ${error.email[0]}`;
      }
      setMessage({
        type: "error",
        message: errorMessage,
      });
      setOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    // Basic email validation
    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

    // Basic password validation (at least 8 characters)
    const validatePassword = (password) => password.length >= 8;

    let errorMessage = "";

    if (!validateEmail(email)) {
      isValid = false;
      errorMessage = "Please enter a valid email address.";
    } else if (!validatePassword(password)) {
      isValid = false;
      errorMessage = "Password must be at least 8 characters long.";
    } else if (password !== confirmPassword) {
      isValid = false;
      errorMessage = "Passwords do not match.";
    }
    // Add more validations as needed

    if (errorMessage) {
      setMessage({
        type: "error",
        message: errorMessage,
      });
      setOpen(true);
      return; // Stop the form submission
    }

    if (!isValid) return;

    try {
      await login({
        first_name,
        last_name,
        dob,
        email,
        password,
      });
    } catch (error) {
      console.error("Sign up failed", error);
      setMessage({
        type: "error",
        message: "Email has already been taken.",
      });
      setOpen(true);
    }
  };
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Sign Up</div>
        <div className="step-container">
          <div className="step active">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <div className="step-text">Verification</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="container mt-5 mb-5">
          <div className="row justify-content-center">
            <div className="col-md-4 signup bg-white text-center shadow p-5">
              <div className="span1 mb-3 center p-10">
                Please fill in the following information.
              </div>
              <div>
                <input
                  required
                  name="first_name"
                  className="form-control mt-4 mixed-input-top"
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                />
              </div>{" "}
              <div>
                <input
                  required
                  name="last_name"
                  className="form-control lastname mixed-input-bottom"
                  placeholder="Last name"
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                />
              </div>{" "}
              <div>
                <div className="span2">
                  Make sure it matches the name on your government ID.
                </div>
              </div>{" "}
              <div>
                <input
                  required
                  name="dob"
                  className="form-control mt-5"
                  type="date"
                  value={dob}
                  onChange={(e) => setDOB(e.target.value)}
                  placeholder="Birth"
                />
              </div>{" "}
              <div>
                <div className="span2 m-0">
                  To sign up, you need to be at least 18. Your birthdate won’t
                  be shared with anyone using AnyProperty.
                </div>
              </div>{" "}
              <div>
                <input
                  required
                  name="email"
                  className="form-control mt-5"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>{" "}
              <div>
                <div className="span2 m-0">
                  We'll email you offer confirmations and updates.
                </div>
              </div>{" "}
              <div>
                <input
                  required
                  name="password"
                  className="form-control mt-4 mixed-input-top"
                  placeholder="Password"
                  type="password" // Change type to "password" to hide input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>{" "}
              <div>
                <input
                  required
                  name="confirmPassword"
                  className="form-control lastname mixed-input-bottom"
                  placeholder="Confirm Password"
                  type="password" // Change type to "password" to hide input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>{" "}
              <div>
                <p className="span2 mt-4">
                  By selecting Agree and continue, I agree to AnyProperty’s{" "}
                  <a href="https://www.anyproperty.co.nz/pages/terms" target="_blank">Terms of Service, Payments Term of Service,</a> and
                  acknowledge the <a href="https://www.anyproperty.co.nz/pages/privacy-policy" target="_blank">Privacy Policy.</a>
                </p>
              </div>
              <div className="center">
                <button type="submit" className="button">
                  {" "}
                  <span className="continuebutton">
                    {isLoading ? "Wait..." : "Agree And Continue"}
                  </span>
                </button>
              </div>
              <hr />
              <div className="span2">
                AnyProperty will send you members-only updates, marketing
                emails, and push notifications. You can opt out of receiving
                these at any time in your account settings or directly from the
                marketing notification.
              </div>
              <div className="check-box">
                <div>
                  <input
                    type="checkbox"
                    checked={marketing_emails}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="text">
                  I don’t want to receive marketing messages from AnyProperty.
                </div>
              </div>
              <div className="center">
                <HelpLink />
              </div>
            </div>
          </div>
        </div>
      </form>{" "}
      <AlertBox
        open={open}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      ></AlertBox>
      <Footer />
    </div>
  );
}
