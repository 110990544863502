import React from "react";
import { Grid } from "@mui/material";
import SetPropertyStepsCard from "../../components/property/SetPropertyStepsCard";
import { useProperty } from "../../context/PropertyContext";
import "./SetPropertyDescriptions.css";

function SetPropertyDescriptions() {
  const { property, updateProperty } = useProperty();
  const [error, setError] = React.useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length > 800) {
      setError(true);
    } else {
      setError(false);
      updateProperty("descriptions", { generalDescription: value });
    }
  };

  const descriptionText = property.descriptions.generalDescription || "";
  const charactersLeft = 800 - descriptionText.length;

  return (
    <div className="set-properties-rooms-container">
      <div className="set-properties-rooms-heading-box">
        <p className="set-properties-rooms-heading">
          How would you describe your property?
        </p>
        <p className="set-properties-rooms-para">
          Share some basic details about your place
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SetPropertyStepsCard currentPage={6} />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="property-card-description-container">
            <div className="textarea-container">
              <textarea
                className="property-card-description"
                name="username"
                value={descriptionText}
                placeholder="Write something.."
                onChange={handleChange}
              />
              {error && (
                <p className="error-message">
                  Description shouldn't exceed 800 characters
                </p>
              )}
              <p className="text-in-corner">{charactersLeft} Characters left</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPropertyDescriptions;
