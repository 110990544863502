const initialState = {
  properties: [],
  properties_history: [],
  isLoading: true,
};

function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PROPERTIES":
      return { ...state, properties: action.payload, isLoading: false };
    case "SET_PROPERTIES_HISTORY":
      return { ...state, properties_history: action.payload, isLoading: false };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case 'UPDATE_PROPERTY_FIELD':
      return {
        ...state,
        property: {
          ...state.property,
          [action.payload.field]: action.payload.value
        }
      };
    default:
      return state;
  }
}

export default propertyReducer;
