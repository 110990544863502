import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMainProperties } from "../../utils/elasticSearch";
import Header from "../../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperties } from "../../redux/actions/propertyActions";
import Footer from "../../components/common/Footer";
import PropertyCard from "../../components/property/PropertyCard";
import ToggleButtons from "../../components/ui/ToggleButtons";
import "./HomeBuy.css";
const Home = () => {
  const dispatch = useDispatch();
  const [propertiesData, setProperties] = useState([]);
  useEffect(() => {
    const dd = async () => {
      const p = await getMainProperties();
      setProperties(p);
    };
    dd();
  }, [dispatch]);
  const navigate = useNavigate();

  const onSellTextClick = useCallback(
    (t) => {
      navigate(`/${t}`);
    },
    [navigate]
  );
  const onLearnMoreClick = useCallback(
    (t) => {
      navigate(`/how-it-works`);
    },
    [navigate]
  );
  const onGroupContainerClick = useCallback(
    (t) => {
      navigate("/browse");
    },
    [navigate]
  );

  const onPropertyClick = (slug) => {
    navigate(`/product-view?slug=${slug}`);
  };

  return (
    <>
      <Header />
      <div
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "images/home-bg.svg"
          })`,
          backgroundPosition: "center 15%",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        className="w-full"
      >
        <div className="container">
          <main>
            <ToggleButtons text={"buy"} onSellTextClick={onSellTextClick} />
            <div className="text-overlay">
              <div className="overlay-text">
                <span className="color1">Your move, </span>{" "}
                <span className="color2">your call.</span>{" "}
              </div>
            </div>
            <div className="responsive-image-container">
              <div className="image-wrapper">
                <img
                  src="/images/rectangle-31@2x.png" // Update the image source accordingly
                  alt="Responsive Image"
                  className="responsive-image"
                />
              </div>
            </div>
            <div className="overlay-text-blue additional-overlay">
              <div className="long-text">
                <span>
                  Christchurch’s{" "}
                  <span className="highlight-text ">largest range</span> of
                  property for <span className="highlight-text ">sale</span>
                </span>
              </div>
            </div>
            <div className="text-overlay">
              <div className="overlay-text">
                <span className="normal-text">Homes you might like</span>
              </div>
            </div>
            {/* First row */}
            {propertiesData && propertiesData.length > 0 && propertiesData && (
              <>
                <section className="row">
                  {propertiesData[0] && (
                    <div
                      className="box-left"
                      onClick={() => onPropertyClick(propertiesData[0].slug)}
                    >
                      <PropertyCard
                        pic={propertiesData[0].legal_description?.split("|")[0]}
                        title={propertiesData[0].full_address}
                        bedroom={propertiesData[0].bedrooms}
                        bathroom={propertiesData[0].bathrooms}
                        price={
                          Number.isFinite(propertiesData[0].estate_type)
                            ? `${propertiesData[0].estate_type.toLocaleString()} NZD`
                            : propertiesData[0].estate_type
                        }
                      />
                    </div>
                  )}
                  {propertiesData[1] && (
                    <div
                      className="box-right"
                      onClick={() => onPropertyClick(propertiesData[1].slug)}
                    >
                      <PropertyCard
                        pic={propertiesData[1].legal_description?.split("|")[0]}
                        title={propertiesData[1].full_address}
                        bedroom={propertiesData[1].bedrooms}
                        bathroom={propertiesData[1].bathrooms}
                        price={
                          Number.isFinite(propertiesData[1].estate_type)
                            ? `${propertiesData[1].estate_type.toLocaleString()} NZD`
                            : propertiesData[1].estate_type
                        }
                      />
                    </div>
                  )}
                </section>

                {/* Second row */}
                <section className="row">
                  {propertiesData[2] && (
                    <div
                      className="box-left"
                      onClick={() => onPropertyClick(propertiesData[2].slug)}
                    >
                      <PropertyCard
                        pic={propertiesData[2].legal_description?.split("|")[0]}
                        title={propertiesData[2].full_address}
                        bedroom={propertiesData[2].bedrooms}
                        bathroom={propertiesData[2].bathrooms} // Fixed a typo from 20 to 2
                        price={
                          Number.isFinite(propertiesData[2].estate_type)
                            ? `${propertiesData[2].estate_type.toLocaleString()} NZD`
                            : propertiesData[2].estate_type
                        }
                      />
                    </div>
                  )}
                  {propertiesData[3] && (
                    <div
                      className="box-right"
                      onClick={() => onPropertyClick(propertiesData[3].slug)}
                    >
                      <PropertyCard
                        pic={propertiesData[3].legal_description?.split("|")[0]}
                        title={propertiesData[3].full_address}
                        bedroom={propertiesData[3].bedrooms}
                        bathroom={propertiesData[3].bathrooms} // Fixed a typo from 30 to 3
                        price={
                          Number.isFinite(propertiesData[3].estate_type)
                            ? `${propertiesData[3].estate_type.toLocaleString()} NZD`
                            : propertiesData[3].estate_type
                        }
                      />
                    </div>
                  )}
                </section>
              </>
            )}

            <div className="text-overlay margin">
              <div className="overlay-text">
                <button
                  onClick={onGroupContainerClick}
                  className="blue-button button-custom"
                >
                  Browse Property
                </button>
              </div>
            </div>
            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 1</div>
                  <div className="search">Search</div>
                  <div className="search">& View</div>
                  <div className="steps-container-bottom">
                    Search for any property from Canterbury's largest property
                    database.
                    <p>View any place. From anywhere. At anytime.</p>
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="image-big"
                  loading="lazy"
                  alt=""
                  src="/images/step-1.png"
                />
              </div>
            </section>

            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 2</div>
                  <div className="search">Make an</div>
                  <div className="search">offer</div>
                  <div className="steps-container-bottom">
                    Found a property you like? Want to book a virtual tour? Or
                    simply want to know more?
                    <p>
                      Click ‘Request to view’ or ‘make an offer’, AnyProperty
                      will securely connect you to the current buyer if they’re
                      interested.
                    </p>
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="image-big"
                  loading="lazy"
                  alt=""
                  src="/images/Step-2.png"
                />
              </div>
            </section>

            <section className="steps-container">
              <div className="steps-container-box">
                <div className="steps-container-inner">
                  <div className="step">Step 3</div>
                  <div className="search">Move in</div>
                  <div className="steps-container-bottom">
                    Once you have come to an agreement using our seamless
                    purchasing process
                    <p>Congratulations, you’re a new homeowner.</p>
                  </div>
                </div>
              </div>
              <div className="steps-container-box-image">
                <img
                  className="image-big"
                  loading="lazy"
                  alt=""
                  src="/images/Step-3.png"
                />
              </div>
            </section>
            <div className="text-overlay margin">
              <div className="overlay-text">
                <button
                  className="blue-button button-custom"
                  // className="button1"
                  onClick={onLearnMoreClick}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="overlay-container black-area">
              <img src="/images/bf-2@2x.png" alt="Step 1 Image" />
              <div className="overlay-text-bottom">
                <div className="big-text-white">
                  Buy & sell privately anywhere
                </div>
                {/* <div className="text">Over 1 million</div> */}
                <div className="big-text-white counting">in Canterbury</div>
                {/* <div className="text2">and counting</div> */}
                {/* <div className="small-text-custom">
              Over 100 buyers throughout NZ makes an offer on AnyProperty It
              every minute.
            </div> */}
                <div className="text3">Nation-wide launch coming soon</div>
                <div className="mt-10">
                  <button
                    onClick={onGroupContainerClick}
                    // className="button2"
                    className="button-custom white-button font-product-sans-medium"
                  >
                    Browse Property
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
