import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Profile from "./Profile";
import { fetchUserProperties } from "../../redux/actions/userActions";
import apiService from "../../services/apiService";
import "./VerifiedProperties.css";
import PropertySections from "../../components/property/PropertySections";
import { Link, useLocation } from "react-router-dom";
import VerifiedPropertyCard from "../../components/property/VerifiedPropertyCard";
const VerifiedProperties = () => {
  const dispatch = useDispatch();
  const { myProperties, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserProperties());
  }, []);
  const location = useLocation();

  return (
    <div className="container">
      <Header />
      <Profile />
      <div className="verified-container">
        <div className="tab">
          <Link
            to="/verifiedproperties"
            className={`tab-button ${
              location.pathname === "/verifiedproperties" ? "active" : ""
            }`}
          >
            Verified Properties
          </Link>
          <Link
            to="/watchlist"
            className={`tab-button ${
              location.pathname === "/watchlist" ? "active" : ""
            }`}
          >
            Watchlist
          </Link>
          <Link
            to="/recentlyview"
            className={`tab-button ${
              location.pathname === "/recentlyview" ? "active" : ""
            }`}
          >
            Recently Viewed
          </Link>
        </div>
        <div className="line-separator"></div>
        <PropertySections isLoading={isLoading} propertiesData={myProperties} />
      </div>

      <Footer />
    </div>
  );
};

export default VerifiedProperties;
