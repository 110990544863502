import React from "react";
import "./ViewOfferCard.css";
import PopupModal from "../common/PopupModal";

const ViewOfferCard = ({ locationArray }) => {
  const [open, setOpen] = React.useState(false);

  const handlePopup = () => {
    setOpen(true);
  };

  return (
    <div className="offer-right-container">
      {locationArray.map((item) => (
        <div className="offer-card-container" key={item.id}>
          <div className="offer-card-layout">
            <div className="offer-card-container-left">
              <div className="offer-card-content-col">
                <p>Viewing Date</p>
                <h4>dasd{item.viewingDate}</h4>
              </div>
              {item.status && (
                <div className="offer-card-content-col">
                  <p>Status</p>
                  <h4>{item.status}</h4>
                </div>
              )}
              {item.price && (
                <div className="offer-card-content-col">
                  <p>Offer Price</p>
                  <h4>{item.price}</h4>
                </div>
              )}
            </div>

            <div className="offer-card-container-right">
              <div className="offer-card-right-btn">
                <button onClick={handlePopup}>Manage</button>
              </div>
            </div>
          </div>

          <PopupModal
            open={open}
            setOpen={setOpen}
            title={item.title}
            description={item.description}
            subDescription={item.subDescription}
          />
        </div>
      ))}
    </div>
  );
};

export default ViewOfferCard;
