import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PropertyProvider } from "../../context/PropertyContext";
import SetupPropertyFooter from "../../components/property/SetupPropertyFooter";
import SetupPropertyHeader from "../../components/property/SetupPropertyHeader";
import SetPropertyRooms from "./SetPropertyRooms";
import SetPropertyFeatures from "./SetPropertyFeatures";
import SetPropertySpecifics from "./SetPropertySpecifics";
import SetPropertyDescriptions from "./SetPropertyDescriptions";
import SetPropertyCommunity from "./SetPropertyCommunity";
import SetPropertyPhotos from "./SetPropertyPhotos";
import SetPropertyVirtualTour from "./SetPropertyVirtualTour";
import SetPropertyPlace from "./SetPropertyPlace";
import SetPropertyPin from "./SetPropertyPin";
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProperty } from '../../redux/actions/propertyDetailActions';


function SetProperty() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, isLoadingUser } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(currentUser || {});
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug');
  
  // Use optional chaining to safely access nested properties
  const propertyDetail = useSelector(state => state.propertyDetail);
  const property = propertyDetail?.property;
  const loading = propertyDetail?.loading;
  const error = propertyDetail?.error;

  const [isNewProperty, setIsNewProperty] = useState(!slug);

  useEffect(() => {
    if (currentUser) {
      setUserData(currentUser);
    } else {
      navigate("/login");
    }
  }, [currentUser]); // Listen to currentUser changes

  useEffect(() => {
    if (slug) {
      dispatch(fetchProperty(slug));
    } else {
      setIsNewProperty(true);
    }
  }, [slug, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <PropertyProvider initialSlug={slug}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <SetupPropertyHeader isNewProperty={isNewProperty} />
        <SetupPropertyFooter >
          <SetPropertyPlace />
          <SetPropertyPin />
          <SetPropertyRooms />
          <SetPropertySpecifics />
          <SetPropertyDescriptions />
          <SetPropertyCommunity />
          <SetPropertyPhotos />
          <SetPropertyVirtualTour />
        </SetupPropertyFooter>
      </div>
    </PropertyProvider>
  );
}

export default SetProperty;