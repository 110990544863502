const initialState = {
  verifiedProperties: [],
  isLoading: false,
  error: null,
};

const verifiedPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_VERIFIED_PROPERTIES_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_VERIFIED_PROPERTIES_SUCCESS":
      return {
        ...state,
        verifiedProperties: action.payload,
        isLoading: false,
      };
    case "FETCH_VERIFIED_PROPERTIES_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default verifiedPropertiesReducer;
