import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ToggleButtons.css";

const ToggleButtons = ({ onSellTextClick, text }) => {
  return (
    <div className="toggle-buttons">
      <button
        className={`button ${text === "buy" ? "blue-button" : "white-button"}`}
        onClick={() => onSellTextClick("buy")}
      >
        Buy
      </button>
      <button
        className={`button ${text === "sell" ? "blue-button" : "white-button"}`}
        onClick={() => onSellTextClick("sell")}
      >
        Sell
      </button>
    </div>
  );
};

export default ToggleButtons;
