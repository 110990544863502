import React, { useRef, useState, useEffect } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../../components/common/Header";
import AlertBox from "../../components/common/AlertBox";
import Footer from "../../components/common/Footer";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import apiService from "../../services/apiService";
import "./Subcription.css";
import HelpLink from "../../components/common/HelpLink";
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "pk_live_srOYpGflwsIyPtqXCeKQwa7r"
);
const Subcription = () => {
  let navigate = useNavigate();
  const [complete, setComplete] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [openAlert, setAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);

  const updateComplete = (field, isComplete) => {
    setComplete((prev) => ({ ...prev, [field]: isComplete }));
  };

  useEffect(() => {
    if (complete.number && complete.expiry && complete.cvc) {
      // Automatically focus to the next field isn't necessary because all fields are complete
    }
  }, [complete]);

  const handleCheckboxChange = (event) => {
    setReceiveMarketingEmails(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setErrorMessage("Stripe has not yet loaded.");
      return;
    }

    if (!email) {
      setMessage({
        type: "error",
        message: "Email is not found, please come from signup page",
      });
      setAlertOpen(true);
      return;
    }

    setLoading(true);
    setErrorMessage(null);
    try {
      const cardElement = elements.getElement(CardNumberElement);
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        console.log(error.message);
        setMessage({
          type: "error",
          message: error.message,
        });
        setAlertOpen(true);
        setLoading(false);
      } else {
        const response = await apiService.postWithToken(
          "/payments/add_user_subscription",
          {
            stripeToken: token.id,
            description: "AnyProperty Subscription",
            email,
          }
        );
        if (response.success) {
          setMessage({
            type: "success",
            message: "Subscription started successfully",
          });
          setAlertOpen(true);
          setTimeout(() => {
            navigate("/");
          }, 3000);
          return;
        }
      }
    } catch (e) {
      console.log(e);
      setMessage({
        type: "error",
        message: e,
      });
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  return (
    <div className="sign-up">
      <Header />
      <div className="container">
        <div className="steps-title">Subscribe</div>
        <div className="step-container">
          <div className="step active">
            <div className="step-number">1</div>
            <div className="step-text">Information</div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-text">Confirmation</div>
          </div>
        </div>
      </div>
      <form className="viewing-payment__body" onSubmit={handleSubmit}>
        <div className="container mt-5 mb-5">
          <div className="row justify-content-center">
            <div className="col-md-4 signup bg-white text-center shadow p-5">
              <div className="span1 mb-3 center p-10">
                <b> Plan: AnyProperty Premium</b>
                <div>30 days free trial then $7/month after that</div>
                <br />
                <small> Please fill in the following information.</small>
              </div>
              <div>
                {" "}
                <CardNumberElement
                  id="cardNumber"
                  placeholder="Card number"
                  className="form-control mt-4 mixed-input-top"
                  options={options}
                  onChange={(event) => updateComplete("number", event.complete)}
                  ref={cardNumberRef}
                />
              </div>{" "}
              <div>
                {" "}
                <CardExpiryElement
                  id="cardExpiry"
                  placeholder="Card Number"
                  className="form-control lastname mixed-input-bottom"
                  options={options}
                  onChange={(event) => updateComplete("expiry", event.complete)}
                  ref={cardExpiryRef}
                />
              </div>{" "}
              <div>
                <CardCvcElement
                  id="cardCvc"
                  className="form-control lastname mixed-input-bottom"
                  placeholder="CVV"
                  options={options}
                  onChange={(event) => updateComplete("cvc", event.complete)}
                  ref={cardCvcRef}
                />
              </div>{" "}
              <div>
                <p className="span2 mt-4">
                  By selecting Agree and continue, I agree to AnyProperty’s{" "}
                  <a href="https://www.anyproperty.co.nz/pages/terms" target="_blank">Terms of Service, Payments Term of Service,</a> and
                  acknowledge the <a href="https://www.anyproperty.co.nz/pages/privacy-policy" target="_blank">Privacy Policy.</a>
                </p>
              </div>
              <div className="center">
                <button type="submit" className="button">
                  {" "}
                  <span className="continuebutton">
                    {isLoading ? "Wait..." : "Agree And Continue"}
                  </span>
                </button>
              </div>
              <hr />
              <div className="span2">
                AnyProperty will send you members-only updates, marketing
                emails, and push notifications. You can opt out of receiving
                these at any time in your account settings or directly from the
                marketing notification.
              </div>
              <div className="center">
                <HelpLink />
              </div>
            </div>
          </div>
        </div>
      </form>{" "}
      <AlertBox
        open={openAlert}
        type={message.type}
        onClose={handleClose}
        message={message.message}
      />
      <Footer />
    </div>
  );
};
const PaymentFormWithStripe = () => (
  <Elements stripe={stripePromise}>
    <Subcription />
  </Elements>
);

export default PaymentFormWithStripe;
