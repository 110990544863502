import axios from "axios";
import { getCookie } from "../utils";
const baseURL = process.env.REACT_APP_API_URL;
//const baseURL = "/api";
const api = axios.create({
  baseURL,
});

const apiRequest = async (method, url, data = {}, headers = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    throw (
      error?.response?.data?.errors ||
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message
    );
  }
};

//Authorization: `Bearer ${getCookie("token")}`,
const apiService = {
  get: (url, params = {}, headers = {}) => {
    // If params object is not empty, include it in the options
    const options = Object.keys(params).length
      ? { params, ...headers }
      : { ...headers };
    return apiRequest("get", url, null, options);
  },
  getWithToken: (
    url,
    params = {},
    headers = {
      Authorization: `Bearer ${getCookie("token")}`,
    }
  ) => {
    // If params object is not empty, include it in the options
    const options = Object.keys(params).length
      ? { params, ...headers }
      : { ...headers };
    return apiRequest("get", url, null, options);
  },
  post: (url, data = {}, headers = {}) =>
    apiRequest("post", url, data, headers),
  postWithToken: (
    url,
    data = {},
    headers = {
      Authorization: `Bearer ${
        getCookie("token") ||
        "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyOTciLCJzY3AiOiJ1c2VyIiwiYXVkIjpudWxsLCJpYXQiOjE3MTU0MTc4NjIsImV4cCI6MTcxNTUwNDI2MiwianRpIjoiMDQ1ZWZlNDktNTZmMS00NWQ2LWI1ZDUtZDZlZDMzNWE2MjEwIn0.aSMaYbnWVRadGHXJDn2XuOvX2yQ8bICLqGGKCwcj1vQ"
      }`,
    }
  ) => apiRequest("post", url, data, headers),
  put: (url, data = {}, headers = {}) => apiRequest("put", url, data, headers),
  putWithToken: (
    url,
    data = {},
    headers = {
      Authorization: `Bearer ${getCookie("token")}`,
    }
  ) => apiRequest("put", url, data, headers),
  deleteWithToken: (
    url,
    data = {},
    headers = {
      Authorization: `Bearer ${
        getCookie("token") ||
        "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyOTciLCJzY3AiOiJ1c2VyIiwiYXVkIjpudWxsLCJpYXQiOjE3MTU0MTc4NjIsImV4cCI6MTcxNTUwNDI2MiwianRpIjoiMDQ1ZWZlNDktNTZmMS00NWQ2LWI1ZDUtZDZlZDMzNWE2MjEwIn0.aSMaYbnWVRadGHXJDn2XuOvX2yQ8bICLqGGKCwcj1vQ"
      }`,
    }
  ) => apiRequest("delete", url, data, headers),

  patchWithToken: (
    url,
    data = {},
    headers = {
      Authorization: `Bearer ${
        getCookie("token") 
      }`,
    }
  ) => apiRequest("patch", url, data, headers),
};

export default apiService;
